import { Component } from "@angular/core";
import { Router }    from "@angular/router";


/**
 * Generated class for the ConfigComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
	selector: "config",
	templateUrl: "config.html",
	styleUrls: ["./config.scss"],
	standalone: true,
	imports: []
})
export class ConfigComponent {

	constructor(private router: Router) {
	}

	async showSettings() {
		await this.router.navigate(["/settings"]);

	}

}
