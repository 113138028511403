<div class="searchbar" [class.searchbar--in-menu]="inMenu()">
	<form (submit)="openSearch($event)">

		<mat-form-field class="mat-form-field--searchbar">
			<input type="text" matInput
				[formControl]="inputCTRL"
				[placeholder]="('search' | translate:systemLanguage) + '...'"
				(focus)="onFocus()"
				(blur)="onBlur()"
				(change)="onChange()"
				(keyup)="onKeyup($event)">
			@if(inputCTRL.value) {
				<span class="phb-icon icon--cross2" matSuffix (click)="inputCTRL.setValue('')"></span>
			}
		</mat-form-field>
		@if(showLink) {
			<a [routerLink]="['/search']">{{'search_open' | translate:systemLanguage}}</a>
		}
	</form>
</div>
