import {
	ApplicationRef,
	EmbeddedViewRef,
	Injectable,
	ViewContainerRef
} from "@angular/core";

@Injectable()
export class DomService {

	private appendedChilds = [];

	constructor(private appRef: ApplicationRef) {
	}

	appendComponentToBody(component: any, vcr: ViewContainerRef) {
		// 1. Create a component reference from the component
		const componentRef = vcr.createComponent(component, {
			injector: vcr.injector
		});


		// 2. Attach component to the appRef so that it's inside the ng component tree
		this.appRef.attachView(componentRef.hostView);

		// 3. Get DOM element from component
		const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
				.rootNodes[0] as HTMLElement;

		// 4. Append DOM element to the body
		document.querySelector("body .app-page").appendChild(domElem);

		this.appendedChilds[component] = componentRef;


	}

	removeChild(component: any) {
		const componentRef = this.appendedChilds[component];
		// 5. Wait some time and remove it from the component tree and from the DOM

		this.appRef.detachView(componentRef.hostView);
		componentRef.destroy();
	}
}
