import { Component, effect } from "@angular/core";
import {DomSanitizer}        from "@angular/platform-browser";
import {Directory, File, Node, Section, UUID}  from "../../../_model";
import {DataProvider}                                    from "../../services/data/data";
import {MenuHelper}                                      from "../menuHelper";
import {Router}                                          from "@angular/router";
import {UserProvider}                                    from "../../services/user/user";
import {PHBGlobals}                                      from "../../../_config/config";
import {getCombinedIsoCode, Iso639_1, IsoCode, Language} from "../../../_model/Language";
import {isElectron}                                      from "../../../_utils/electron-utils";
import { closeSplitMenu }                                from "../../services/gui";
import { CommonModule }                                  from "@angular/common";
import { CaptionPipe }                                   from "../../pipes/caption.pipe";
import { ColorPipe }                                     from "../../pipes/color.pipe";
import { GetIconPipe }                                   from "../../pipes/get-icon.pipe";
import { NoFilesPipe }                                   from "../../pipes/no-files.pipe";
import { OnlyFilesPipe }                                 from "../../pipes/only-files.pipe";
import { DefaultTranslationPipe }                        from "../../pipes/default-translation.pipe";
import { IsoCodeToCountryKeyPipe }                       from "../../pipes/isoCode-to-country-key.pipe";


/**
 * Menu displaying the document tree on the right side.
 */
@Component({
	selector: "menu-list",
	templateUrl: "menu-list.html",
	styleUrls: ["menu-list.scss"],
	standalone: true,
	imports: [
		CommonModule,
		CaptionPipe,
		ColorPipe,
		GetIconPipe,
		NoFilesPipe,
		OnlyFilesPipe,
		DefaultTranslationPipe,
		IsoCodeToCountryKeyPipe
	]
})
export class MenuListComponent extends MenuHelper {

	private menuToggleClick: boolean = false;

	currentNode: Node;
	currentIsoCode: IsoCode;
	currentSection: Section;
	openPath: UUID[] = [];

	get useFallback(): boolean {
		return PHBGlobals.useTranslationFallback;
	}

	get currentLanguage(): Iso639_1 {
		return PHBGlobals.currentLanguage;
	}

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	get languages(): Language[] {
		return PHBGlobals.plainLanguages;
	}

	constructor(public data: DataProvider,
	            public sanitizer: DomSanitizer,
	            private router: Router,
	            userProvider: UserProvider
	) {
		super();
		effect(() => {
			const [node, isoCode] = this.data.currentNodeWithIsoCode();

			if (node !== null) {
				this.currentNode = node;
				this.currentIsoCode = isoCode;
				this.openPath.push(...node.parentIds, node.id);
				this.openPath = this.openPath.filter(function (item, pos, self) {
					return self.indexOf(item) == pos;
				});

				if (!node.parentIds[1]) {
					this.currentSection = node as Section;
				} else if ((node.parentIds[1] && this.currentSection.id != node.parentIds[1])) {
					this.currentSection = PHBGlobals.index.getNode(node.parentIds[1]) as Section;
				}
			}
		});

		effect(() => {
			const section = this.data.currentSection();
			if (section !== null) {
				this.currentSection = PHBGlobals.index.getNode(section) as Section;
			}
		});

		effect(() => {
			const change = PHBGlobals.indexChangedSignal();
			if (change) {
				this.currentSection = PHBGlobals.index.getNode(this.currentSection.id) as Section;
			}
		});

		effect(() => {
			const user = userProvider.currentUserSignal();
			if(user?.data?.settings) {
				this.menuToggleClick = user.data.settings.menuToggleClick || false;
			}
		});
	}

	async onItemClicked(node: Node) {
		if (node.nodeType === "Directory" && (node as Directory).heading) {
			return;
		}
		this.currentIsoCode = node.isoCode;

		if (this.currentNode && node.id !== this.currentNode.id && this.menuToggleClick) {
			this.openPath = [];
		} else if (!this.menuToggleClick && node.nodeType === "Directory"
			&& (this.currentNode && this.currentNode.id !== node.id && !this.currentNode.parentIds.includes(node.id))
		) {

			if (this.openPath.includes(node.id)) {
				const pos = this.openPath.findIndex(it => it === node.id);
				this.openPath.splice(pos, 1);

				const findChildIds = (dir: Directory) => {
					for (let child of dir.children) {
						const pos = this.openPath.findIndex(it => it === child.id);
						if (pos > -1) {
							this.openPath.splice(pos, 1);
							if (child.nodeType === "Directory") {
								findChildIds(child as Directory);
							}
						}
					}
				};
				findChildIds(node as Directory);

				return;
			} else {
				this.openPath.push(node.id);
			}
		}

		if(window.innerWidth < 768 && node.isFileType()) {
			closeSplitMenu();
		}

		await this.router.navigate(["/node", node.id]);
	}

	async onTranslatedItemClick(file: File, language: Language) {
		const isoCodeFn = ((file: File, language: Language) => {
			if (PHBGlobals.user.loggedIn && language.countries.includes(PHBGlobals.user.getCountry())) {
				const isoCode = getCombinedIsoCode(language.isocode, PHBGlobals.user.getCountry());
				if (file.translations.hasOwnProperty(isoCode)) {
					return {
						key: isoCode,
						value: file.translations[isoCode]
					};
				}
			}

			let isoCode = getCombinedIsoCode(language.isocode, language.countries[0]);
			if (file.translations.hasOwnProperty(isoCode)) {
				return {
					key: isoCode,
					value: file.translations[isoCode]
				};
			} else {
				for (let country of language.countries) {
					isoCode = getCombinedIsoCode(language.isocode, country);
					if (file.translations.hasOwnProperty(isoCode)) {
						return {
							key: isoCode,
							value: file.translations[isoCode]
						};
					}
				}
			}
		})(file, language);
		const isoCode = isoCodeFn.key;

		if (!file.translations.hasOwnProperty(isoCode)) {
			throw new Error("Invalid language!");
		}
		if (!file.isFileType()) {
			throw new Error("Is not a file!");
		}

		this.currentIsoCode = isoCode;

		if (this.currentNode
			&& (file.id !== this.currentNode.id && isoCode === this.currentIsoCode)
			&& this.menuToggleClick) {
			this.openPath = [];
		} else {
			this.openPath.push(file.id);
		}

		await this.router.navigate(["/node", isoCode, file.id]);
	}

	isOpen(directory: Directory) {
		return this.openPath.includes(directory.id) || directory.heading;
	}

	getTextColor(node: Node) {
		if (node.textColor) {
			return {color: "#" + node.textColor};
			//return this.sanitizer.bypassSecurityTrustStyle("color: #" + node.textColor + ";");
		} else {
			return "";
		}
	}

	canSeeTranslations(): boolean {
		if (isElectron()) {
			return false;
		}
		if (!PHBGlobals.useTranslationFallback) {
			return false;
		}
		return PHBGlobals.user.loggedIn && PHBGlobals.user.data.settings.showTranslations;
	}
}
