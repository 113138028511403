declare var process: any;
declare var window: any;

/**
 * Returns true, if running on the Electron platform.
 */
export function isElectron(): boolean {
	// Renderer process
	//if (typeof window !== "undefined" && typeof window.process === "object" && window.process.type === "renderer")
	if (!!window && !!window.process && !!window.process.type) {
		return true;
	}
	// Main process
	if (typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron) {
		return true;
	}
	// Detect the user agent when the `nodeIntegration` option is set to true
	if (typeof navigator === "object" && typeof navigator.userAgent === "string" && navigator.userAgent.indexOf("Electron") >= 0) {
		return true;
	}
	if (navigator.userAgent.toLowerCase().indexOf(" electron/") > -1) {
		return true;
	}
	return false;
}

export function callElectron(functionName: string, ...args: any[]) {
	if (isElectron()) {
		const {ipcRenderer} = window["require"]("electron");
		ipcRenderer.send(functionName, ...args);
	}
}

export async function callAsyncElectron(functionName: string, onProcess: (result) => void | null, ...args: any[]): Promise<any> {
	if (isElectron()) {
		const {ipcRenderer} = window["require"]("electron");

		return await new Promise((resolve, reject) => {
			ipcRenderer.send(functionName, ...args);
			const onProc = (event, arg) => {
				if (onProcess !== null) {
					onProcess(arg);
				}
			};
			if (onProcess !== null) {
				ipcRenderer.on("asynchronous-process", onProc);
			}

			const onError = (event, arg) => {
				ipcRenderer.removeListener("asynchronous-process", onProc);
				ipcRenderer.removeListener("asynchronous-error", onError);
				ipcRenderer.removeListener("asynchronous-reply", onFinish);
				reject(arg);
			};
			ipcRenderer.on("asynchronous-error", onError);

			const onFinish = (event, arg) => {
				ipcRenderer.removeListener("asynchronous-process", onProc);
				ipcRenderer.removeListener("asynchronous-error", onError);
				ipcRenderer.removeListener("asynchronous-reply", onFinish);
				resolve(arg);
			};
			ipcRenderer.on("asynchronous-reply", onFinish);
		});
	} else {
		console.log("callAsyncElectron", functionName, args);
	}
}
