import { Component, effect } from "@angular/core";
import { UserProvider }         from "../../services/user/user";
import { ZipProvider }          from "../../services/zip";
import { PHBGlobals }           from "../../../_config/config";
import { Router }               from "@angular/router";
import { Iso639_1 }             from "../../../_model/Language";
import { closeSplitMenu }       from "../../services/gui";
import { TranslatePipe }        from "../../pipes/translate";


/**
 * Button for creating a ZIP archive.
 */
@Component({
	selector: "zip",
	templateUrl: "zip.html",
	styleUrls: ["zip.scss"],
	standalone: true,
	imports: [
		TranslatePipe
	]
})
export class ZipComponent {

	isLoggedIn = false;

	zip: boolean = false;
	count: number = 0;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(private router: Router,
		public userProvider: UserProvider,
		public zipProvider: ZipProvider) {
		effect(() => {
			const user = this.userProvider.currentUserSignal();
			this.isLoggedIn = user.loggedIn;
			PHBGlobals.userLoggedIn = this.isLoggedIn;
		});

		effect(() => {
			this.count = this.zipProvider.fileCountSignal();
			PHBGlobals.zipAvailable = (this.count > 0 && this.isLoggedIn);
		});
	}

	async openModal() {
		await this.router.navigate(["/collect"]);
		if (window.innerWidth < 768) {
			closeSplitMenu();
		}
	}
}
