import { Component, Inject }             from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Iso639_1 }                      from "../../../_model/Language";
import { PHBGlobals } from "../../../_config/config";
import { MatButtonModule }               from "@angular/material/button";
import { TranslatePipe }                 from "../../pipes/translate";


@Component({
	selector: "app-phb-update-failure",
	templateUrl: "./phb-update-failure.component.html",
	styleUrls: ["./phb-update-failure.component.scss"],
	standalone: true,
	imports: [
        MatButtonModule,
		TranslatePipe
	]
})
export class PhbUpdateFailureComponent {

	msg: string = null;
	showSkip: boolean = false;
	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}


	constructor(protected dialogRef: MatDialogRef<PhbUpdateFailureComponent>,
		@Inject(MAT_DIALOG_DATA) public data) {
		console.log(data);
		this.msg = data.msg;
		this.showSkip = data.skipAllowed;
	}

	skip() {
		this.dialogRef.close('skip');
	}

	retry() {
		this.dialogRef.close('retry');
	}

}
