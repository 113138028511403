@if(!skipped) {
	<div class="updater">
		<h1>{{"SoftwareUpdater.Available"  | translate:systemLanguage}}</h1>

		<div class="m-2">
			<p>{{"SoftwareUpdater.Filesize"  | translate:systemLanguage}}: {{size | filesize}}</p>
		</div>

		@if(lastSkippedDate && remainingDays > 0) {
			<div class="m-2">
				<p>{{"SoftwareUpdater.Remaining" | translate:systemLanguage:{"{DAYS}": remainingDays + ""} }}</p>
			</div>
		}

		@if(!downloading) {
			<div class="m-2">
				<button mat-flat-button class="mat-small-button" (click)="download()" color="primary">{{"SoftwareUpdater.Start"  | translate:systemLanguage}}</button>
				@if(!lastSkippedDate || remainingDays >0) {
					<button mat-flat-button class="mat-small-button" (click)="skip()" color="light">{{"SoftwareUpdater.Skip"  | translate:systemLanguage}}</button>
				}
			</div>
		} @else {
			<p id="version-progress">{{"SoftwareUpdater.Downloading" | translate:systemLanguage}} <span>{{progress}}</span>%
			</p>
		}
	</div>

}
