import { NgModule }                from "@angular/core";
import { HasChildrenDirective }    from "./has-children.directive";
import { IsTocDirective }          from "./is-toc.directive";
import { NodeBackgroundDirective } from "./node-background.directive";
import { NodeTextColorDirective }  from "./node-text-color.directive";

const DIRECTIVES = [
	HasChildrenDirective,
	IsTocDirective,
	NodeBackgroundDirective,
	NodeTextColorDirective
];

@NgModule({
	imports: [],
	declarations: [...DIRECTIVES],
	exports: [...DIRECTIVES]
})
export class DirectivesModule {
}
