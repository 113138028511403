import { RouteReuseStrategy } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {

	/**
	 * Class copied from Ionic
	 **/
	shouldDetach(_route) {
		return false;
	}


	shouldAttach(_route) {
		return false;
	}


	store(_route, _detachedTree) {
		return;
	}

	retrieve(_route) {
		return null;
	}

	shouldReuseRoute(future, curr) {
		if (future.routeConfig !== curr.routeConfig) {
			return false;
		}

		const futureParams = future.params;
		const currentParams = curr.params;
		const keysA = Object.keys(futureParams);
		const keysB = Object.keys(currentParams);
		if (keysA.length !== keysB.length) {
			return false;
		}
		// Test for A's keys different from B.
		for (const key of keysA) {
			if (currentParams[key] !== futureParams[key]) {
				return false;
			}
		}
		return true;
	}
}
