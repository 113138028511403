import { HttpClient, HttpHeaders }                            from "@angular/common/http";
import { effect, Injectable, Signal, signal, WritableSignal } from "@angular/core";
import { assignUser, Credentials, setDefaultUserSettings, User, UserSettings } from "../../../_model/user";
import { callAsyncElectron, callElectron, isElectron }                         from "../../../_utils/electron-utils";
import { PHBGlobals }                                                          from "../../../_config/config";
import { environment }                                                         from "../../../environments/environment";
import * as _                                                                  from "lodash";
import * as KJUR                                                               from "jsrsasign";
import { measurePasswordStrength }                                             from "../../../_utils/general-utils";
import { AppstatusProvider }                                                   from "../appstatus";

/**
 * This provider manages the currently logged pin user.
 * It starts with an unknownUser(), but continues as quickly as possible with
 * either an anonymousUser() or a logged in user, or with an offlineUser() if
 * running in the Electron offline client.
 */
@Injectable({providedIn: "root"})
export class UserProvider {

	// Monitors the current user. When it changes, we can also be sure that
	// the corresponding access token is already available (managed by this class)
	private _currentUserSignal: WritableSignal<User> = signal<User>(undefined);
	readonly currentUserSignal: Signal<User> = this._currentUserSignal.asReadonly();

	token: string;

	private static ACCESS_KEY = "phb-token";

	accessTokenRefreshMinutes = 5;
	lastAccessTokenRefreshTime: Date = new Date(1970, 1, 1);


	constructor(public httpClient: HttpClient,
		private appStatus: AppstatusProvider) {

		effect(() => {
			const user = this.currentUserSignal();
			if(user) {
				console.log("set user:", user);
				PHBGlobals.user = user;
			}
		});
	}

	getStorageKey(identifier: string) {
		const user: User = this.currentUserSignal();
		if (user) {
			return `${user.username}-${identifier}`;
		} else {
			return `guest-${identifier}`;
		}
	}

	async init() {
		console.log("init userProvider");
		if (isElectron()) {
			await this.initElectron();
		} else {
			await this.initWww();
		}
	}

	async initElectron() {
		const online = this.appStatus.onlineSignal();
		this.token = localStorage.getItem(UserProvider.ACCESS_KEY);
		if (this.token) {
			console.log("---- TOKEN loaded ----");
			try {
				if (online && !this.validToken()) {
					console.log("Token is invalid");
					await this.loginElectronSSO();
				} else if (!online && !this.validToken()) {
					console.error("token is invalid... but u r offline...");
				} else if (this.validToken()) {
					console.log(this.getPayload(), new Date(this.getPayload().exp * 1000));
				}
			} catch (e) {
				if (online) {
					console.log("Token is invalid");
					await this.loginElectronSSO();
				}
				console.error(e);
			}
		} else if (online) {
			//is anonymousUser
			await this.loginElectronSSO();
		}

		if (!this.token) {
			console.error("A valid token or wifi connection is required!");
			throw new Error("A valid token or wifi connection is required!");
		}

		let settings: UserSettings = null;
		const _settings = await callAsyncElectron("getUserSettings", () => {
		});
		if (_settings) {
			if (isNaN(_settings.zoom)) {
				_settings.zoom = 1;
			}
			settings = Object.assign(new UserSettings(null), _settings);
		} else {
			settings = new UserSettings(PHBGlobals.currentLanguage);
		}


		let user: User;
		if (online) {
			user = await this.getUser();
		} else {
			const payload = this.getPayload();
			user = Object.assign(new User, {
				id: 0,
				active: true,
				data: {
					settings: settings
				},
				userGroup: payload.data && payload.data.userGroup ? payload.data.userGroup : null,
				email: "",
				roles: payload.roles,
				username: payload.username
			});
			setDefaultUserSettings(user);

			if (!settings.languages || settings.languages.length <= 0) {
				throw new Error("User has an invalid language list!");
			}
		}
		if (!_.isEqual(settings.languages, user.data.settings.languages)) {
			settings.languages = user.data.settings.languages;
		}

		await this.updateSettings(settings, user);
		this._currentUserSignal.set(user);
	}

	async initWww() {
		console.log("initWWW");
		this.token = localStorage.getItem(UserProvider.ACCESS_KEY);


		if (this.token) {
			console.log("---- TOKEN loaded ----");
			if (!this.validToken()) {
				console.log("Token is invalid");
				await this.logout();
			} else {
				console.log(`Token is valid`);
				await this.getUser();
			}
		} else {
			//is anonymousUser
			this._currentUserSignal.set(new User());
		}


	}

	getPayload(token: string = null): {
		address: any
		age: any
		data: any
		exp: number
		iss: any
		jti: string
		role: string
		[key: string]: any
	} {
		if (token === null) {
			token = this.token;
		}
		return KJUR.jws.JWS.parse(token).payloadObj;
	}

	validToken(token: string = null) {
		if (token === null) {
			token = this.token;
		}
		const payload = this.getPayload(token);
		const now = (new Date().getTime() / 1000);
		return (payload.exp > now);
	}

	async logout(forced: boolean = true) {
		if (!forced) {
			await this.httpClient.get<any>(`${environment.symfony}/logout`).toPromise();
		}
		localStorage.removeItem(UserProvider.ACCESS_KEY);
		if (PHBGlobals.user) {
			localStorage.removeItem(this.getStorageKey(`phb-section-${PHBGlobals.user.sessionHash}`));
			localStorage.removeItem(this.getStorageKey(`phb-zip-storage-${PHBGlobals.user.sessionHash}`));
		}
		this.token = null;

		let user = new User();
		this._currentUserSignal.set(user);
	}

	async getUser(): Promise<User> {
		if (this.token) {
			try {
				const userJson = await this.httpClient.get<User>(`${environment.symfony}/user/current`).toPromise();
				const user = assignUser(userJson);

				user.loggedIn = true;

				if (isElectron()) {
					let checker = (arr, target) => target.every(v => arr.includes(v));

					const settings = await callAsyncElectron("getUserSettings", () => {
					});
					if(!settings.languages) settings.languages = [];
					if(!user.data.settings.languages) user.data.settings.languages = settings.languages;
					if (!checker(settings.languages, user.data.settings.languages) || !checker(user.data.settings.languages, settings.languages)) {
						settings.languages = user.data.settings.languages;
					}
					if (settings) {
						user.data.settings = settings;
					}
				}

				if (!this.currentUserSignal() || this.currentUserSignal().username !== user.username) {
					setDefaultUserSettings(user);
					this._currentUserSignal.set(user);
				}
				if (isElectron()) { //TODO www?
					const payload = this.getPayload();
					if (payload.data && payload.data.userGroup) {
						console.info("payload found", payload.data.userGroup);
						console.info("current userGroup", user.userGroup);
						if (user.userGroup !== payload.data.userGroup) {
							await this.loginElectronSSO();
							return await this.getUser();
						}
					}
				}
				return user;
			} catch (e) {
				console.error(e);
				if (e.status > 400) {
					await this.logout();
					return new User();
				}
			}
		}
		throw new Error("Invalid User!");
	}

	async loginElectronSSO() {
		if (this.appStatus.onlineSignal()) {
			const token = await callAsyncElectron("sso-login", () => {
			});
			await this.updateTokens(token);
		} else {
			console.info("no online connection... skip login");
		}
	}

	async login(credentials: Credentials): Promise<User> {
		let data = await (this.httpClient.post<{ token: string }>(environment.symfony + "/user/login_check", credentials)).toPromise();
		await this.updateTokens(data.token);
		return this.getUser();
	}

	async updateTokens(access) {
		if (access !== null) {
			console.info("update token");
			localStorage.setItem(UserProvider.ACCESS_KEY, access);
			this.token = access;
			this.lastAccessTokenRefreshTime = new Date();
		}
	}

	async refreshExpiringToken() {
		if (!this.token) {
			throw new Error("Invalid token!");
		}
		const preventAt = (new Date().getTime() / 1000) - (60 * 10);
		const now = (new Date().getTime() / 1000);
		const payload = this.getPayload();
		console.info("Refreshing token?");
		console.info("Prevent at:", new Date(preventAt * 1000));
		console.info("Exp:", new Date(payload.exp * 1000));

		if (payload.exp < now) {
			if (!isElectron()) {
				await this.logout();
			} else {
				await this.loginElectronSSO();
			}
		} else {
			if (preventAt < now) {
				await this.refreshToken();
			}
		}
	}

	async refreshToken() {
		let h = {};
		if (PHBGlobals.phbVersion && PHBGlobals.previewModeUserGroups) {
			h = {
				"PHB-Preview-Groups": PHBGlobals.previewModeUserGroups
			};
		}

		let httpOptions = {
			headers: new HttpHeaders(h)
		};

		const res = await this.httpClient.get<any>(environment.symfony + "/user/refresh", httpOptions).toPromise();
		await this.updateTokens(res.token);
	}


	//MISC User Stuff


	async update(user: User, _password) {
		const data: User = _.clone(user);
		if (_password && measurePasswordStrength(_password) > 70) {
			data.password = _password;
		}
		await this.httpClient.put<any>(`${environment.symfony}/user/current`, data).toPromise();
		this._currentUserSignal.set(user);
	}

	async updateSettings(settings: UserSettings, user: User = null) {
		let currentUser = user || this.currentUserSignal();
		if (currentUser) {
			currentUser.data.settings = settings;
			if (PHBGlobals.phbClient === "www") {
				await this.update(currentUser, null);
			} else {
				this._currentUserSignal.set(_.clone(currentUser));
				if (isElectron()) {
					callElectron("saveUserSettings", settings);
				}
			}
		} else {
			try {
				throw new Error("CurrentUser undefined!");
			} catch (e) {
				console.error(e);
			}
		}
	}

	async requestPassword(email) {
		return await this.httpClient.post<any>(environment.symfony + "/password/forgot", {
			email: email
		}).toPromise();
	}
}
