import {HttpClient}                                   from "@angular/common/http";
import { effect, Injectable, signal, WritableSignal } from "@angular/core";
import {Index, File, Hash}                            from "../../../_model";
import {DataProvider}                                from "../data/data";
import {environment}                                 from "../../../environments/environment";
import {FileDownload, UpdateService}                 from "./UpdateServiceInterface";
import {callAsyncElectron, callElectron, isElectron} from "../../../_utils/electron-utils";
import {ElectronUpdater}                             from "./electronUpdater";
import {translate}                                   from "../../../_model/vocab";
import {TagGroup}                                    from "../../../_model/tags";
import {LanguageService}                             from "../language";
import {Language}                                    from "../../../_model/Language";
import {PHBGlobals}                                  from "../../../_config/config";
import {UserProvider}                                from "../user/user";
import {last}                                        from "../../../_utils/general-utils";

export const UpdaterSteps = [
	"init",
	"download",
	"cleanup",
	"symlinks",
	"index",
	"finished"
];

export const DownloadMessages = {

	START: "Updater.Log.Start",
	STARTING: "updater_starting_download",
	CHECK: "Updater.Log.GeneratingFileList",
	CHECK_FILES: "updater_check_files",
	CHECKED_FILES: "updater_checked_files",
	CALCULATE: "updater_calculate",
	UPDATE_AVAILABLE: "updater_update_available",
	NO_UPDATE_AVAILABLE: "updater_no_update_available",
	NO_FILE_UPDATES: "updater_no_updatable_files_found",

	COLLECTING_OLD_FILES: "updater_collecting_old_files",

	DOWNLOAD_FINISHED: "updater_node_download_finished",

	FILE_DOWNLOAD_FAILED: "updater_download_failed_count",
	FILE_DOWNLOAD_RETRY_SUCCESS: "updater_retry_successful",
	FILE_DOWNLOAD_CANCELED: "updater_download_cancelled",


	SYMLINKS: "Updater.BuildingFileTree",
	REMOVING_OLD_FILES: "Updater.RemovingOldFiles",
	OLD_FILES_REMOVED: "updater_old_files_deleted",

	INDEX_WRITING: "updater_writing_index",
	INDEX_TEMP: "updater_writing_index_temp",
	INDEX_WRITTEN: "updater_index_written",
	COMPLETE: "updater_complete",
	DONE: "Updater.Log.UpdateDone",
	TRANSLATIONS: "updater_translations",
	TAGGROUPS: "updater_tagGroups"

};

export interface Update {
	currentVersion: number;
	newVersion: number;
	index: Index;
	winIndex: Index;
	downloads: FileDownload[];
	updDate: Date;
	forced:boolean
}

@Injectable({providedIn: "root"})
export class UpdateProvider {

	updateAvailable: WritableSignal<Update> = signal<Update>(null);

	messagesSignal: WritableSignal<string[]> = signal<string[]>([]);

	elementProgress: WritableSignal<{
		fileSize: number,
		progress: number
	}> = signal<{
		fileSize: number,
		progress: number
	}>(null);

	finishedElementsSignal: WritableSignal<number> = signal<number>(null);
	updateStepSignal: WritableSignal<number> = signal<number>(-1);
	updateStepProgressSignal: WritableSignal<number> = signal<number>(-1);

	updateFailure: WritableSignal<any> = signal<any>(null);
	updateFinished: WritableSignal<Update> = signal<Update>(null);

	private service: UpdateService;

	running: boolean = false;
	version: number;
	hashes: Hash[] = [];


	constructor(public http: HttpClient,
	            private data: DataProvider,
	            private userService: UserProvider,
	            private languageProvider: LanguageService) {

		if (isElectron()) {
			this.service = new ElectronUpdater();
		} else {
			//TODO cordova etc
		}

		effect(async () => {
			const step = this.updateStepSignal();
			const update = this.updateAvailable();
			if (update) {
				const stepString = translate(`updater_step_${UpdaterSteps[step]}`);
				try {
					if (step > 0) {
						this.pushMessage("updater_step_start", {"%step%": stepString});
					}
					switch (step) {
						case 0:
							//new update available
							console.log("new update available");
							break;
						case 1:
							await this.updateStepDownloadFiles(update);
							break;
						case 2:
							if (update.forced || update.downloads.length > 0) {
								await this.updateStepRemoveOldFiles(update);
							} else {
								this.pushMessage("Skipping file cleanup...");
							}
							break;
						case 3:
							await this.updateStepSymlinks(update);
							break;
						case 4:
							await this.updateStepIndex(update);
							break;
						case 5:
							//finished do nothing
							await this.updateStepFinished(update);
							break;
						default:
							//do nothing
							break;
					}
					if (step > 0 && step < 5) {
						this.pushMessage("updater_step_done", {"%step%": stepString});
						this.invokeUpdateStep(step + 1);
					}
				} catch (e) {
					this.pushMessage("updater_step_exception", {"%step%": stepString});
					this.updateFailure.set(e);
				}
			}
		}, {
			allowSignalWrites: true
		});
	}

	invokeUpdateStep(step) {
		const realStep = Math.round(step);

		const cur = this.updateStepSignal();
		if (cur !== realStep) {
			this.updateStepSignal.set(realStep);
		}
		this.updateStepProgressSignal.set(step);
	}

	async hasUpdate(force: boolean = false) {
		const curUser = this.userService.currentUserSignal();
		if (!curUser || curUser.username === "_guest") {
			if (isElectron()) {
				callElectron("restart");
			}
			throw new Error("Not allowed for guest!");
		}
		await this.userService.refreshToken();
		const lastUpdate = this.updateAvailable();
		const updating = this.updateStepSignal() !== -1;
		console.log("checking for update",
			{
				"run update": (force || !lastUpdate) && !updating && !this.running,
				"force": force,
				"has last Update": !!lastUpdate,
				"not updating": !updating,
				"not running": !this.running,
				"lastUpdate": lastUpdate
			}
		);
		if ((force || !lastUpdate) && !updating && !this.running) {
			this.running = true;
			const update: Update = {
				currentVersion: await this.data.getIndexVersion(),
				newVersion: await this.data.getServerIndexVersion(),
				index: null,
				winIndex: null,
				downloads: null,
				updDate: new Date(),
				forced:force
			};

			const promises: Promise<boolean>[] = [
				new Promise<boolean>(async (resolve, reject) => {
					try {
						update.index = await this.data.getCurrentIndex();
						resolve(true);
					} catch (e) {
						resolve(false); //TODO typescript update required for Promise.allSettled
					}
				}),
				new Promise<boolean>(async (resolve, reject) => {
					try {
						update.winIndex = await this.data.getCurrentIndex(false);
						resolve(true);
					} catch (e) {
						resolve(false); //TODO typescript update required for Promise.allSettled
					}
				}),
				new Promise<boolean>(async (resolve, reject) => {
					this.hashes = [];
					update.downloads = await this.collectDownloads();
					resolve(true);
				})
			];
			//TODO typescript update required for Promise.allSettled
			await Promise.all(promises);

			update.downloads.push(...await this.collectResources(update.index));

			if (!update.winIndex || !update.index) {
				this.running = false;
				throw new Error("Error!");
			}

			console.log({
				force: force,
				downloads: update.downloads.length,
				newVersion: update.currentVersion < update.newVersion,
				update,
				execute: force || update.downloads.length > 0 || update.currentVersion < update.newVersion
			});
			if (force || update.downloads.length > 0 || update.currentVersion < update.newVersion) {
				this.running = false;
				this.updateAvailable.set(update);
				if (!force) {
					this.invokeUpdateStep(0);
				}
				this.messagesSignal.set([]);
				if (!force) {
					this.pushMessage("updater_update_available");
				} else {
					this.pushMessage("updater_update_forced");
				}
				return true;
			} else {
				this.running = false;
				return false;
			}
		}
		this.running = false;
	}

	isUpdating() {
		return this.updateStepSignal() !== -1;
	}

	private async collectDownloads(): Promise<FileDownload[]> {
		const downloads: FileDownload[] = [];
		const hashes = await this.data.getFileHashes();
		this.hashes = hashes;
		const list = await this.service.collectDownloads(hashes, (progress) => {
			if (!environment.production) {
				console.log(progress);
			}
		});

		for (let hash of list) {
			let url = `${environment.kotlin}/file-preview/${hash.split(".preview.")[0]}/${hash.split(".preview.")[1]}.jpg`;
			let size = 0;
			if (hash.indexOf(".preview.") === -1) {
				size = parseInt(hash.split("-")[1]);
				url = `${environment.kotlin}/file/${hash}`;
			}
			const download: FileDownload = {
				hash: hash,
				url: url,
				size: size
			};
			downloads.push(download);
		}


		return downloads;
	}

	async collectResources(index: Index) {
		const downloads: FileDownload[] = [];
		const resources = await this.service.collectResources(index, () => {
		});

		for (let hash of resources) {
			const download: FileDownload = {
				hash: hash,
				url: `${environment.kotlin}/file/${hash}`,
				size: parseInt(hash.split("-")[1])
			};
			downloads.push(download);
		}

		return downloads;
	}


	async updateStepDownloadFiles(update: Update) {
		if (update.downloads.length > 0) {
			try {
				await this.download(update.downloads);
			} catch (e) {
				console.error(e);
				throw new Error("download");
			}
		} else {
			this.pushMessage(DownloadMessages.NO_FILE_UPDATES);
		}
	}

	async updateStepRemoveOldFiles(update: Update) {
		try {
			const deletedHashes = await this.service.cleanup(this.hashes);
			if (isElectron()) {
				const log = [];

				const hashList = {};
				for (const file of PHBGlobals.index.asArray.filter(it => it.nodeType === "File") as File[]) {
					for (let isoCode in file.translations) {
						const translation = file.translations[isoCode];
						if (translation.hash !== null) {
							if (!hashList[translation.hash]) {
								hashList[translation.hash] = [];
							}
							hashList[translation.hash].push({
								nodeId: file.id,
								isoCode: isoCode,
								translation: translation,
								breadcrumb: file.getBreadcrumb().map(node => node.caption).join("/")

							});
						}
					}
				}

				for (let hash of deletedHashes.map(it => last(it.split("\\")))) {
					if (hashList[hash]) {
						for (const hashListEntry of hashList[hash]) {
							log.push({
								hash: hashListEntry.translation.hash,
								status: 200,
								caption: hashListEntry.translation.caption,
								name: hashListEntry.translation.name,
								mimeType: hashListEntry.translation.mimeType,
								size: hashListEntry.translation.size,
								isoCode: hashListEntry.isoCode,
								nodeId: hashListEntry.nodeId,
								breadcrumb: hashListEntry.breadcrumb
							});
						}
					} else {
						log.push({
							hash: hash,
							status: 404,
							caption: "",
							name: "",
							mimeType: "",
							size: "",
							isoCode: "",
							nodeId: "",
							breadcrumb: "",
						});
					}
				}
				await callAsyncElectron("logDeletedFiles", () => {
				}, log);
			}

			this.pushMessage(DownloadMessages.OLD_FILES_REMOVED, {"%count%": deletedHashes.length});
		} catch (e) {
			console.error(e);
			throw new Error("deleteOldFiles");
		}

	}

	async updateStepSymlinks(update: Update) {
		try {
			await this.createSymLinks(update);
		} catch (e) {
			throw new Error("symlinks");
		}
	}

	async updateStepIndex(update: Update) {
		try {
			if (!update || !update.winIndex) {
				throw new Error("No Index!");
			}
			await this.service.index(update.winIndex);
			PHBGlobals.index.version = update.winIndex.version;
		} catch (e) {
			throw new Error("index");
		}
	}

	async updateStepFinished(update: Update) {
		this.pushMessage(DownloadMessages.COMPLETE);
		this.updateAvailable.set(null);
		this.invokeUpdateStep(-1);
		this.finishedElementsSignal.set(0);
		this.elementProgress.set(null);
		this.updateFinished.set(update);
	}

	async update() {
		let update = this.updateAvailable();
		if (!update) {
			return;
		}
		this.pushMessage(DownloadMessages.START);

		const liveVersion = await this.data.getServerIndexVersion();
		if (liveVersion !== update.newVersion) {
			this.pushMessage("Update outdated. Fetching new data, please wait...");
			this.updateStepSignal.set(-1);
			await this.hasUpdate(true);
			update = this.updateAvailable();
		}


		this.pushMessage(DownloadMessages.INDEX_TEMP);
		await this.service.index(update.index, "index.complete");

		if (update.forced || update.downloads.length > 0) {
			this.pushMessage(DownloadMessages.TRANSLATIONS);
			const languages: Language[] = await this.data.getRemoteLanguages();
			await this.service.languageFiles(languages);
			await this.languageProvider.init(true);

			this.pushMessage(DownloadMessages.TAGGROUPS);
			const tags: TagGroup[] = await this.data.getRemoteTagGroups();
			await this.service.tagGroups(tags);
		}else{
			this.pushMessage("Skipping tag groups & translations...");
		}

		this.invokeUpdateStep(1);
	}

	async download(downloads: FileDownload[]) {
		const curStep = this.updateStepSignal();
		let overallErrors = 0;
		if (downloads.length > 0) {
			for (let download of downloads) {
				let retries = 0;

				await this.service.download(download, (p) => {
					if (download.size) {
						this.elementProgress.set(
							{
								fileSize: download.size,
								progress: Math.round(p)
							});
					}
				}, (retry) => {
					retries = retry;
					this.pushMessage(DownloadMessages.FILE_DOWNLOAD_FAILED, {"%count%": retry});
				}, () => {
					const finished = this.finishedElementsSignal() + 1;
					const progress = finished * 100 / downloads.length;
					this.updateStepProgressSignal.set((Math.round((curStep + (progress / 100)) * 100)) / 100);
					if (retries > 0) {
						this.pushMessage(DownloadMessages.FILE_DOWNLOAD_FAILED, {"%count%": retries});
					}
					this.finishedElementsSignal.set(finished);
				}, (errors) => {
					console.error("Download canceled with errors: ", errors);
					this.pushMessage(DownloadMessages.FILE_DOWNLOAD_CANCELED, {"%count%": retries});
					const finished = this.finishedElementsSignal() + 1;
					this.finishedElementsSignal.set(finished);
					overallErrors++;
				});

				if (overallErrors > 10) {
					throw new Error("Too many failed downloads! Try again later...");
				}
			}
		}
	}

	async createSymLinks(update: Update) {
		const errorList = await this.service.symlinks(update.index, () => {
		});
		if (errorList && errorList.length > 0) {
			for (let item of errorList) {
				this.pushMessage("updater_exception_inuse", {"%path%": item});
			}
			throw new Error("symlinks");
		}
	}

	/**
	 *
	 * @param message
	 * @param replacements string[][]
	 */
	pushMessage(message: string, replacements: { [key: string]: string | number } = null) {
		const messages: string[] = this.messagesSignal();
		let text = translate(message);
		if (replacements) {
			for (let key in replacements) {
				if (replacements.hasOwnProperty(key)) {
					const value = replacements[key];
					text = text.replace(key, value + "");
				}
			}
		}

		messages.push(text);
		console.log("Updater:", text);
		this.messagesSignal.set(messages);
	}

}
