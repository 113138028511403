import { Pipe, PipeTransform }     from "@angular/core";
import { File, Node } from "../../_model";
import { DataProvider }            from "../services/data/data";
import { DomSanitizer }            from "@angular/platform-browser";


@Pipe({
	name: "getIcon",
	standalone: true
})
export class GetIconPipe implements PipeTransform {

	constructor(private data: DataProvider,
		public sanitizer: DomSanitizer) {

	}

	transform(node: Node, sanitizeMe: boolean = true, preferred: "treeIcon" | "icon" = "icon"): any {
		if (node.nodeType === "VirtualFile") {
			return "assets/placeholder/file.svg";
		}
		const node_icon = node[preferred] || node.icon;
		if (node_icon && node_icon.indexOf("@flags/") !== -1) {
			return `assets/flags/${node_icon.replace("@flags/", "").toUpperCase()}.png`;
		}
		if (!node_icon || node_icon == "ordner.png" || node_icon == "no.png" || node_icon == "nix.png" || node_icon == "nix1.png" || node_icon == "no-icon.png") {
			if (!node.niceIconPath) {
				const folder = "assets/placeholder/";
				if (node.nodeType == "File" || node.nodeType == "WebLink") {
					const file = node as File;
					switch (file.mimeType) {
						case "image/jpeg":
						case "image/png":
						case "image/gif":
							file.niceIconPath = this.data.getFilePath(file.hash);
							break;

						case "video/mp4":
						case "video/webm":
						case "video/ogg":
						case "video/mpeg":
							const parent = file.getParent();
							if(parent.video && file.previewHash) {
								file.niceIconPath = this.data.getFilePreview(file.previewHash,-1);
							}else {
								file.niceIconPath = folder + "video.svg";
							}
							break;

						case "application/pdf":
							if (file.pages) {
								file.niceIconPath = this.data.getFilePreview(file.hash);
							} else {
								file.niceIconPath = folder + "pdf.svg";
							}
							break;

						default:
							console.log(file.mimeType);
							file.niceIconPath = folder + "file.svg";
							break;
					}

				} else if (node.nodeType == "Directory") {
					node.niceIconPath = folder + "folder.svg";
				}
			}
		} else {
			node.niceIconPath = this.data.getIcon(node_icon);
		}

		let ret = node.niceIconPath;

		if (ret === null) {
			return "assets/placeholder/file.svg";
		}

		if (sanitizeMe) {
			return this.sanitizer.bypassSecurityTrustUrl(ret);
		} else {
			return ret;
		}
	}

}
