import { NgModule }      from "@angular/core";
import { PasswordModal } from "./password";
import { CommonModule }  from "@angular/common";
import { FormsModule }   from "@angular/forms";
import { RouterModule }  from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { TranslatePipe } from "../../pipes/translate";

@NgModule({
	declarations: [
		PasswordModal
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild([
			{
				path: "",
				component: PasswordModal
			}
		]),
		MatButtonModule,
		TranslatePipe
	]
})
export class PasswordModalModule {
}
