import { UUID }                          from "./index";
import { Iso3166_1, Iso639_1, Language } from "./Language";
import { PHBGlobals }                    from "../_config/config";
import * as md5                   from "md5";
import { countryStringToIso3166 } from "../_utils/country-helper";
import { isElectron }             from "../_utils/electron-utils";

interface IUser {
	id: number;
	username: string;
	email: string;

	password?: string;

	roles: string[];
	active: boolean;

	uniquekey?: string;
	userGroup?: number;

	data: UserData;
	adminSections?: number[];

	loggedIn?: boolean;
}

export class User implements IUser {
	id: number;
	username: string = "_guest";
	email: string;

	password?: string;

	roles: string[] = [];
	active: boolean;

	uniquekey?: string;
	userGroup?: number;

	data: UserData = new UserData();
	adminSections?: number[];

	loggedIn: boolean = false;

	private country: Iso3166_1 | "__";

	get sessionHash(): string {
		return md5(this.username);
	}

	get languages(): Iso639_1[] {
		const plain = PHBGlobals.plainLanguages.map(it => it.isocode);
		if(isElectron()) {
			if (this.data.settings.languages && this.data.settings.languages.length > 0) {
				return this.data.settings.languages.filter(l => plain.includes(l));
			}
		}else if (this.loggedIn){
			const langs:Iso639_1[] = [];
			if (this.data.settings.languages && this.data.settings.languages.length > 0) {
				langs.push(...this.data.settings.languages.filter(l => plain.includes(l)));
			}
			langs.push(...plain.filter(iso => langs.findIndex(it => it === iso) === -1));
			return langs;
		}
		return plain;
	}

	isAdmin(): boolean {
		return this.roles.includes("ROLE_SUPER_ADMIN") || this.roles.includes("ROLE_ADMIN") || this.roles.includes("ROLE_MAINTAINER");
	}

	setCountry(c: Iso3166_1 | "__") {
		this.country = c;
	}

	getCountry(): Iso3166_1 | "__" {
		return this.country;
	}
}

export function assignUser(userJson: IUser): User {
	if (userJson.data.settings) {
		userJson.data.settings = Object.assign(new UserSettings(null), userJson.data.settings);
	} else {
		userJson.data.settings = new UserSettings("de");
	}
	const user: User = Object.assign(new User, userJson);
	user.setCountry(countryStringToIso3166(user.data.country));
	return user;
}

class UserData {
	firstname: string;
	lastname: string;
	company: string;
	company2: string;
	city: string;
	country: string;
	department: string;
	permissions: {
		newsletter: boolean
		[key: string]: any
	};
	settings: UserSettings = new UserSettings(PHBGlobals.currentLanguage);
}

export type Credentials = { username: string, password: string }

export interface UserAPIResponse {
	status: number,
	jwt?: {
		access: string
		refresh: string
	}
}

export class UserSettings {
	showMenu: boolean = true;

	view: string = "cards";

	printPdf: boolean = true;
	collect: boolean = true;
	showFolder: boolean = true;
	external: boolean = true;
	saveFile: boolean = true;
	language: Iso639_1 = PHBGlobals.currentLanguage;

	silentUpdater: boolean = false;

	visibleSections: UUID[] = [];

	changelogAlwaysVisible: boolean = false;
	menuToggleClick: boolean = false;
	zoom: number = 1;

	languages: Iso639_1[] = [];

	showTranslations: boolean = false;

	constructor(lang: Iso639_1) {
		this.language = lang;
		this.languages = PHBGlobals.plainLanguages.map(it => it.isocode);
	}
}


export function setDefaultUserSettings(user: User) {
	if (!user.data.settings) {
		user.data.settings = new UserSettings("de");
	} else {
		if (user.data.settings.showMenu === undefined) {
			user.data.settings.showMenu = true;
		}
		if (user.data.settings.view === undefined) {
			user.data.settings.view = "cards";
		}
		if (user.data.settings.printPdf === undefined) {
			user.data.settings.printPdf = true;
		}
		if (user.data.settings.collect === undefined) {
			user.data.settings.collect = true;
		}
		if (user.data.settings.showFolder === undefined) {
			user.data.settings.showFolder = true;
		}
		if (user.data.settings.external === undefined) {
			user.data.settings.external = true;
		}
		if (user.data.settings.saveFile === undefined) {
			user.data.settings.saveFile = true;
		}
		if (user.data.settings.language === undefined) {
			user.data.settings.language = "de";
		}
		if (user.data.settings.silentUpdater === undefined) {
			user.data.settings.silentUpdater = false;
		}
		if (user.data.settings.visibleSections === undefined) {
			user.data.settings.visibleSections = [];
		}
		if (user.data.settings.changelogAlwaysVisible === undefined) {
			user.data.settings.changelogAlwaysVisible = false;
		}
		if (user.data.settings.menuToggleClick === undefined) {
			user.data.settings.menuToggleClick = false;
		}
		if (user.data.settings.zoom === undefined) {
			user.data.settings.zoom = 1;
		}
		if (user.data.settings.showTranslations === undefined) {
			user.data.settings.showTranslations = false;
		}
	}
}
