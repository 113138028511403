<div class="app-page  justify-content-start">
	<div class="modal-header">
		<input class="modal-prevent-focus" disabled>
		<h1 class="modal-title">{{ 'forgotten_pw' | translate:systemLanguage }}?</h1>
		<div class="modal-close" (click)="closeModal()">
			<span class="icon--cross2"></span>
		</div>
	</div>

	<div class="page-content p-a">
		<div class="content-inner">
			<div class="row">
				<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
					<div class="text-content">

						<div class="card card--light p-a">
							<!--TODO translation switch <h2 class="text-center m-b">{{'forgotten_pw_appstart' | translate:systemLanguage}}</h2>-->
							<h2 class="text-center m-b">Forgot your password?</h2>

							<form (ngSubmit)="resetPassword()" class="p-a-0">

								<div class="input-wrapper p-a-0">
									<label>
										{{ 'email' | translate:systemLanguage }}<br>
										<input type="email" [(ngModel)]="_email" name="_email" id="_email" class="m-t-quarter">
									</label>
								</div>

								<!--TODO translation switch <p class="text-info m-a-0 text-center" [innerHTML]="'forgotten_pw_intro' | translate"></p>-->
								<p class="text-info m-a-0 text-center">Please enter your registered email address and we will send you immediately an email to set a new one.</p>


								<button color="primary" mat-flat-button type="submit" class="w-100 m-t">Reset password</button>

								@if (status) {
									<p class="text-center">{{ status }}</p>
								}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
