import { Component }    from "@angular/core";
import { UserProvider } from "../../services/user/user";
import { Location }     from "@angular/common";
import { Iso639_1 }     from "../../../_model/Language";
import { PHBGlobals }   from "../../../_config/config";


@Component({
	selector: "password-modal",
	templateUrl: "password.html",
	styleUrls: ["password.scss"]
})
export class PasswordModal {

	_username = "";
	_email = "";
	status = "";

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(public userProvider: UserProvider,
		private location: Location) {
	}

	async resetPassword() {
		if (this._email) {
			try {
				await this.userProvider.requestPassword(this._email);
				this.status = "E-Mail send."; //translate("password_send") + "!";
			} catch (e) {
				this.status = "An error occurred!";//translate("error_occurred");
			}
		}
	}

	async closeModal() {
		this.location.back();
	}

}
