import { Injectable }       from "@angular/core";
import { HttpClient }       from "@angular/common/http";
import { environment }      from "../../environments/environment";
import packageInfo          from "../../../package.json";
import { versionToNumeric } from "../../_utils/general-utils";

@Injectable({
	providedIn: "root"
})
export class SoftwareService {

	constructor(public http: HttpClient) {
	}

	async checkVersion(): Promise<boolean> {
		const newVersion = await this.http.get(environment.kotlin + "/software/win/version", {responseType: "text"}).toPromise();
		return versionToNumeric(newVersion) > versionToNumeric(packageInfo.version);
	}

	async getDownloadSize(): Promise<number> {
		return parseInt(await this.http.get(environment.kotlin + "/software/win/size", {responseType: "text"}).toPromise());
	}

	async getCheckSum(): Promise<string> {
		return await this.http.get(environment.kotlin + "/software/win/checksum", {responseType: "text"}).toPromise();
	}

	getInstaller(): string {
		return environment.kotlin + "/software/win/installer";
	}
}
