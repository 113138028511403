import { Component, OnInit } from "@angular/core";
import { User }              from "../../../_model/user";
import { UserProvider }              from "../../services/user/user";
import { Router }                    from "@angular/router";
import { Location }                  from "@angular/common";
import { Iso639_1 }                  from "../../../_model/Language";
import { PHBGlobals }                from "../../../_config/config";


@Component({
	selector: "login-popover",
	templateUrl: "login.html",
	styleUrls: ["login.scss"]
})
export class LoginPopover implements OnInit {


	currentUser: User;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(public userProvider: UserProvider,
		private router: Router,
		private location: Location) {
	}


	async ngOnInit() {
		this.currentUser = this.userProvider.currentUserSignal();
	}

	async logout() {
		await this.userProvider.logout();
		this.router.navigate(["/"]);
		location.reload();
	}

	async showProfile() {
		await this.router.navigate(["/profile"]);
	}


	async showSettings() {
		await this.router.navigate(["/settings"]);
	}

	async onLogin() {
		localStorage.setItem('phb-logon','true');
		await this.router.navigate(["/"]);
		location.reload();
	}

	async close() {
		if(localStorage.getItem('phb-redirect-after-login')) {
			localStorage.removeItem('phb-logon');
			localStorage.removeItem('phb-redirect-after-login');
			await this.router.navigate(['/']);
		}else {
			this.location.back();
		}
	}


}
