<div class="card card--light ad p-a">
	<div class="text-center">
		Sign in as Melitta employee.
	</div>
	<form>
		<button mat-flat-button color="primary" (click)="ssoLogin()" type="button" class="button-centered login-button">
			<span class="phb-icon icon--melitta-professional_logo"></span>
			{{'sso_login' | translate:systemLanguage}}
		</button>
	</form>

</div>
<div class="card card--light external p-a">

	<h2 class="text-center m-b">Login for external user (outside Melitta company).</h2>

	<form (ngSubmit)="login()" [class]="loginclass() + ' p-a-0'">
		@if(isAppstart()) {
			<div>
				<h2 class="text-center m-b">{{'login_headline' | translate:systemLanguage}}<br></h2>
			</div>
		}

		<div class="input-wrapper p-a-0">
			<label>
				User or email<br>
				<input type="text" name="username" [(ngModel)]="credentials.username" class="m-t-quarter">
			</label>
		</div>

		<div class="input-wrapper p-a-0">
			<label>
				Password<br>
				<input type="password" name="password" [(ngModel)]="credentials.password" class="m-b-0 m-t-quarter">
			</label>
			@if(!isAppstart()) {
				<p class="m-t-half text-right">
					<a (click)="resetPassword()">Forgot your password?</a>
				</p>
			}
		</div>

		@if(isAppstart()) {
			<div class="text-center">
				<button mat-flat-button type="submit" color="primary" class="w-100 m-t-half">{{'login' | translate:systemLanguage}}</button>
				<p>
					{{'forgotten_pw_appstart' | translate:systemLanguage}}<br>
					<a class="forgotten-pw" (click)="resetPassword()">{{'forgotten_pw_appstart_reorder' | translate:systemLanguage}}</a>
				</p>
			</div>
		} @else {
			<button mat-flat-button type="submit" color="primary" class="m-t-half w-100">{{'login' | translate:systemLanguage}}</button>
		}

		@if(msg) {
			<p class="text-center">{{msg}}</p>
		}
	</form>

</div>
