import { Component, effect, OnInit } from "@angular/core";
import { PHBGlobals } from "../../../_config/config";
import { Router, RouterModule } from "@angular/router";
import { AppstatusProvider } from "../../services/appstatus";
import { LanguageService } from "../../services/language";
import { SoftwareService } from "../../services/software.service";
import { Node } from "../../../_model";
import { callElectron, isElectron } from "../../../_utils/electron-utils";
import { PhbUpdateComponent } from "../../dialogs/phb-update/phb-update.component";
import { MatDialog } from "@angular/material/dialog";

import { PhbComponent } from "./phb/phb.component";
import { SoftwareUpdaterComponent } from "./software-updater/software-updater.component";

@Component({
	selector: "app-main",
	templateUrl: "./main.component.html",
	styleUrls: ["./main.component.scss"],
	standalone: true,
	imports: [
		RouterModule,
		PhbComponent,
		SoftwareUpdaterComponent
	]
})
export class MainComponent implements OnInit {


	currentNode: Node;

	_everythingFine: boolean = false;
	get everythingFine(): boolean {
		return this._everythingFine;
	}

	set everythingFine(b: boolean) {
		this._everythingFine = b;
	}

	online: boolean = false;

	dataUpdateNeeded: boolean = false;

	get softwareUpdateNeeded(): boolean {
		return PHBGlobals.softwareUpdateAvailable;
	}

	get dataLoaded(): boolean {
		return PHBGlobals.dataSuccessfulLoaded;
	}


	constructor(private appProvider: AppstatusProvider,
	            public languageProvider: LanguageService,
	            private dialog: MatDialog,
	            private router: Router,
	            private softwareService: SoftwareService) {

		effect(async () => {
			const online = this.appProvider.onlineSignal();
			if (online !== null && online !== this.online) {
				this.online = online;
				await this.ngOnInit();
			}
		});
	}

	async ngOnInit() {
		console.log("dataSuccessfulLoaded:", PHBGlobals.dataSuccessfulLoaded);
		if (!this.dataLoaded) {
			this.dataUpdateNeeded = true;
			try {
				const online = this.appProvider.onlineSignal();
				if (online) {
					try {
						await this.languageProvider.init();
						await this.router.navigate(["/updater"]);
					} catch (e) {
						console.error(e);
						throw new Error("No data and not updatable!");
					}
				} else {
					await this.router.navigate(["/home"]);
					throw new Error("Offline & No data");
				}
			} catch (e) {
				console.error(e);
				if (isElectron()) {
					console.error(e);
					this.dialog.open(PhbUpdateComponent, {
						panelClass: "cdk-overlay__alert",
						backdropClass: "cdk-overlay__alert-backdrop",
						disableClose: true
					}).afterClosed().subscribe(async (result) => {
						if (result === 'restart') {
							callElectron("restart");
						} else {
							callElectron("quit");
						}
					});
				} else {
					// TODO error 500
					this.online = false;
				}
			}
		}
		if (isElectron()) {
			if (this.online) {
				let newVersion = false;
				try {
					newVersion = await this.softwareService.checkVersion();
				} catch (e) {
				}

				if (newVersion) {
					console.log("new version available!");
				}
				PHBGlobals.softwareUpdateAvailable = newVersion;
			}
		}

		if (!this.dataUpdateNeeded && !this.softwareUpdateNeeded) {
			this.everythingFine = true;
		} else {
			this.everythingFine = false;
		}
	}

}
