import { Component } from "@angular/core";
import { Iso639_1 }          from "../../../_model/Language";
import { PHBGlobals }        from "../../../_config/config";
import { MatDialogRef }      from "@angular/material/dialog";

import { MatButtonModule } from "@angular/material/button";
import { TranslatePipe }   from "../../pipes/translate";

@Component({
	selector: "app-on-boarding-modal",
	templateUrl: "./on-boarding-modal.component.html",
	styleUrls: ["./on-boarding-modal.component.scss"],
	standalone: true,
	imports: [
	    MatButtonModule,
		TranslatePipe
	]
})
export class OnBoardingModalComponent {

	pdfUrl:string = 'https://planerhandbuch.de/download/Newsletter_de_en_es_nl_fr.pdf';

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(protected dialogRef: MatDialogRef<OnBoardingModalComponent>) {
	}

	close() {
		localStorage.setItem("phb-onboarding-search","yes");
		this.dialogRef.close();
	}

}
