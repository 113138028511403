import { Component, OnInit }     from "@angular/core";
import { Language }              from "../../../_model/Language";
import { PHBGlobals }            from "../../../_config/config";
import { LanguageService }       from "../../services/language";


@Component({
	selector: "language-header",
	templateUrl: "./language-header.component.html",
	styleUrls: ["./language-header.component.scss"],
	standalone: true,
	imports: []
})
export class LanguageHeaderComponent implements OnInit {

	popupOpen: boolean = false;

	get disabled(): boolean {
		return !PHBGlobals.useTranslationFallback;
	}

	get languages(): Language[] {
		return PHBGlobals.plainLanguages.filter(it => {
			return PHBGlobals.user.languages.includes(it.isocode);
		});
	};

	get language(): Language {
		return PHBGlobals.getLanguage();
	};

	constructor(private languageService: LanguageService) {
	}

	ngOnInit() {
		if(PHBGlobals.languages && !PHBGlobals.languages[PHBGlobals.currentLanguage]){
			PHBGlobals.currentLanguage = "en";
		}
	}

	togglePopOver() {
		this.popupOpen = !this.popupOpen;
	}

	async changeLanguage(language: Language) {
		PHBGlobals.languageChanged.emit(language.isocode);
		this.languageService.loadTranslations();
		this.popupOpen = false;
	}

	closePopup(e) {
		e.stopPropagation();
		e.preventDefault();
		this.popupOpen = false;
	}

	protected readonly PHBGlobals = PHBGlobals;
}
