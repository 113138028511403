import { Component, effect, OnInit } from "@angular/core";
import { UUID }                      from "../../../_model";
import { CollectionEntry, ZipProvider }     from "../../services/zip";
import { AppstatusProvider }                from "../../services/appstatus";
import { Location }                         from "@angular/common";
import { translate }                        from "../../../_model/vocab";
import { validateEmail }                    from "../../../_utils/general-utils";
import { Router }                           from "@angular/router";
import { UserProvider }                     from "../../services/user/user";
import { environment }                      from "../../../environments/environment";
import { Iso639_1 }                         from "../../../_model/Language";
import { PHBGlobals }                       from "../../../_config/config";
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: "zip-download-modal",
	templateUrl: "zip-download.html",
	styleUrls: ["zip-download.scss"]
})
export class ZipDownloadModal implements OnInit {
	collectedFiles: CollectionEntry[] = [];

	total_filesize: number = 0;

	visiblePageSelection: UUID;

	api: string = environment.kotlin;

	maxAttachmentSize: number = 20_971_520;

	mailText: string = translate("DocumentPool.EmailText") + "\n\n" + translate("DocumentPool.Greetings") + "\n";
	mailRecipient: string = "";

	lastFileCount: number = 0;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(public zipProvider: ZipProvider,
		private status: AppstatusProvider,
		private router: Router,
		private _location: Location,
		private user: UserProvider,
		private snackBar: MatSnackBar) {

		effect(()=>{
			const count = this.zipProvider.fileCountSignal();
			if (count !== this.lastFileCount) {
				this.lastFileCount = count;
				this.init();
			}
		});
	}

	async ngOnInit() {
		const user = this.user.currentUserSignal();
		this.mailText += user.data.firstname + " " + user.data.lastname;
	}

	async init() {
		this.collectedFiles = [];
		this.total_filesize = 0;
		this.visiblePageSelection = null;

		const collection = await this.zipProvider.getAll();
		for (let obj of collection) {
			if (obj.translation.mimeType === "application/pdf") {
				obj["pages"] = this.getPagesAsArray(obj.translation.pages);
			}
			this.collectedFiles.push(obj);
		}

		for (let obj of collection) {
			this.total_filesize += obj.translation.size;
		}
	}

	open(id: UUID) {
		this.router.navigate(["/node/", id]);
	}

	async createZip() {
		if (!this.status.onlineSignal()) {
			this.snackBar.open("You are offline! Please go online to create a zip!","", {
				duration: 3000,
				panelClass: "custom-snackbar--success",
				verticalPosition: "top",
				horizontalPosition: "center"
			});
			return;
		}
		const zip_id = await this.zipProvider.create(this.collectedFiles);
		document.location.href = environment.symfony + "/file-collect/zip/" + zip_id;
	}

	async removeFile(entry: CollectionEntry) {
		this.zipProvider.delete(entry.fileId, entry.isoCode);
		this.snackBar.open(translate("Toastr.Message.Success.FileRemoved"),"", {
			duration: 3000,
			panelClass: "custom-snackbar--success",
			verticalPosition: "top",
			horizontalPosition: "center"
		});
	}

	async close() {
		this._location.back();
	}

	async cancel() {
		await this.zipProvider.truncate();
		this._location.back();
	}

	getPagesAsArray(pages: number): number[] {
		let range: number[] = [];
		for (let i = 0; i < pages; i++) {
			range.push(i);
		}
		return range;
	}

	isPageSelected(file: CollectionEntry, page: number) {
		return file.pages.indexOf(page) !== -1;
	}

	togglePage(file: CollectionEntry, page: number) {
		const pos = file.pages.indexOf(page);
		if (pos !== -1) {
			file.pages.splice(pos, 1);
		} else {
			file.pages.push(page);
		}
		file.pages.sort();
	}

	async send() {
		if (!this.status.onlineSignal()) {
			this.snackBar.open("You are offline! Please go online to send a mail!","", {
				duration: 3000,
				panelClass: "custom-snackbar--success",
				verticalPosition: "top",
				horizontalPosition: "center"
			});
			return;
		}
		if (!this.validateEmail(this.mailRecipient)) {
			return;
		}
		if (this.mailText.indexOf("%links%") === -1) {
			this.mailText += "\n%links%";
		}

		let message = "";

		try {
			await this.zipProvider.send(this.mailRecipient, translate("DocumentPool.EmailSubject"), this.mailText, this.collectedFiles);
			this.zipProvider.truncate();
			message = translate("Toastr.Message.Success.DataSent");
		} catch (e) {
			console.error(e);
			message = translate("Toastr.Message.Error.DataNotSent");
			if (e.status === 413) {
				message = translate("Toastr.Message.Error.TemporaryLimitExceeded");
			}
		}

		this.snackBar.open(message,"", {
			duration: 3000,
			panelClass: "custom-snackbar--success",
			verticalPosition: "top",
			horizontalPosition: "center"
		});
	}

	validateEmail(mail: string) {
		return validateEmail(mail);
	}

	selectAll(collectedFile: CollectionEntry) {
		collectedFile.pages = this.getPagesAsArray(collectedFile.translation.pages);
	}

	selectNone(collectedFile: CollectionEntry) {
		collectedFile.pages = [];
	}
}
