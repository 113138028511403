import { FileDownload, UpdateService } from "./UpdateServiceInterface";
import { Hash, Index }                 from "../../../_model";
import { callAsyncElectron }           from "../../../_utils/electron-utils";
import { TagGroup }                    from "../../../_model/tags";
import { Language }                    from "../../../_model/Language";


export class ElectronUpdater implements UpdateService {
	constructor() {
	}

	async collectDownloads(hashes: Hash[],
		onProcess: (result) => void | null): Promise<Hash[]> {
		//this.pushMessage(DownloadMessages.CHECK_FILES);
		if (hashes && hashes.length > 0) {
			return await callAsyncElectron("getFileDownloadlist", (p) => {
				if (onProcess !== null) {
					onProcess(p);
				}
			}, hashes, null);
		}
	}

	async collectResources(index: Index,
		onProcess: (result) => void | null): Promise<Hash[]> {
		if (index.resources) {
			return await callAsyncElectron("checkResources", onProcess, index.resources);
		}
	}

	async languageFiles(languages: Language[]) {
		for (let lang of languages) {
			await callAsyncElectron("writeFile", (progress) => {
			}, "lang", `${lang.isocode}.json`, lang);
		}
		await callAsyncElectron("writeFile", (progress) => {
		}, "lang", `languages.json`, languages);
	}

	async tagGroups(tagGroups: TagGroup[]): Promise<void> {
		return await callAsyncElectron("writeFile", (progress) => {
		}, null, `tag-groups.json`, tagGroups);
	}

	async download(download: FileDownload,
		onProgress: (...args) => void,
		onRetry: (...args) => void,
		onFinish: (...args) => void,
		onError: (...args) => void) {


		try {
			let retries = 0;
			let errors = [];
			let done = false;

			while (retries < 10 && !done) {
				try {
					await callAsyncElectron("downloadFile", onProgress, download.url, download.hash);
					onFinish();
					break;
				} catch (e) {
					retries++;
					onRetry(retries);
					errors.push(e);
				}
			}
			if (retries === 10) {
				onError(errors);
			}

		} catch (e) {
			onError(e);
		}
	}

	async cleanup(fileHashes: Hash[]) {
		return await callAsyncElectron("deleteOldFiles", () => {
		}, fileHashes);
	}

	async symlinks(index: Index, onProgress: (...args) => void): Promise<string[]> {
		return await callAsyncElectron("createSymlinks", onProgress, index);
	}

	async index(index: Index, fileName: string = "index"): Promise<void> {
		await callAsyncElectron("writeFile", (progress) => {
		}, null, fileName, index);
	}


}
