import { Injectable, signal, WritableSignal } from "@angular/core";
import { environment }     from "../../environments/environment";
import { isElectron }      from "../../_utils/electron-utils";
import { HttpClient }      from "@angular/common/http";


@Injectable({providedIn: "root"})
export class AppstatusProvider {

	onlineSignal: WritableSignal<boolean> = signal<boolean>(false);

	private _startUpdater: boolean = false;


	set startUpdater(b: boolean) {
		this._startUpdater = b;
	}

	get startUpdater(): boolean {
		return this._startUpdater;
	}


	constructor(private http:HttpClient) {
	}

	async isOnline(forced: boolean = false): Promise<boolean> {
		if (this.onlineSignal === null || forced) {
			if (navigator.onLine) {
				try {
					await this.http.get(environment.symfony + "/ping", {responseType: "text"}).toPromise();
					console.info("Auth-API online");
				} catch (e) {
					console.info("Auth-API offline");
					console.error(e);
					this.onlineSignal.set(false);
					return false;
				}

				if (!isElectron()) {
					try {
						await this.http.get(environment.kotlin + "ping", {responseType: "text"}).toPromise();
						console.info("Data-API online");
					} catch (e) {
						console.info("Data-API offline");
						console.error(e);
						this.onlineSignal.set(false);
						return false;
					}
				}
			}else{
				this.onlineSignal.set(false);
				return false;
			}
		} else {
			return this.onlineSignal();
		}

		this.onlineSignal.set(true);
		return true;
	}

}
