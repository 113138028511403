import { Directive, effect, input, InputSignal, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Directory }                                                                     from "../../_model";
import { isToc }                                                   from "src/_model/index.fun";

@Directive({
	selector: "[isToc]"
})
export class IsTocDirective implements OnInit {

	constructor( private templateRef: TemplateRef<any>,
				 private viewContainer: ViewContainerRef) {

		effect(() => {
			const d = this.isToc();
			this.updateView();
		});

	}

	ngOnInit() {
		this.updateView();
	}

	isToc: InputSignal<Directory> = input<Directory>();

	private updateView() {
		this.viewContainer.clear();
		if (isToc(this.isToc())) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			return;
		}

		this.viewContainer.clear();
	}
}
