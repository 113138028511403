import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { UpdateProvider } from "./services/update/update";
import { DomService } from "../_utils/DomService";
import { AppstatusProvider } from "./services/appstatus";
import { DataProvider } from "./services/data/data";
import { LanguageService } from "./services/language";
import { UserProvider } from "./services/user/user";
import { ZipProvider } from "./services/zip";
import { SearchSuggestionsComponent } from "./components/search-suggestions/search-suggestions";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { LoginPopoverModule } from "./pages/login/login.module";
import { PasswordModalModule } from "./pages/password/password.module";
import { ProfileModalModule } from "./pages/profile/profile.module";
import { ZipDownloadModalModule } from "./pages/zip-download/zip-download.module";
import { SettingsModalModule } from "./pages/settings/settings.module";
import { HeaderInterceptor } from "./interceptors/header.interceptor";
import { PHBGlobals } from "../_config/config";
import { callAsyncElectron, isElectron } from "../_utils/electron-utils";
import { AppLoadedGuard } from "./guards/app-loaded.guard";
import { UpdaterGuard } from "./pages/updater/updater.guard";
import { translate }                from "../_model/vocab.fun";
import { ElectronLocalSettings }    from "./services/data/winDataService";
import { DirectivesModule }         from "./directives/directives.module";
import { BrowserAnimationsModule }  from "@angular/platform-browser/animations";
import { MatButtonModule }          from "@angular/material/button";
import {MatDialogModule}            from "@angular/material/dialog";
import {MatSnackBarModule}          from "@angular/material/snack-bar";
import { CustomRouteReuseStrategy } from "./custom-route-reuse-strategy";
import { HeaderComponent } from "./theme/header/header.component";
import { MainComponent } from "./theme/main/main.component";
import { TranslatePipe } from "./pipes/translate";

export function init_app(injector: Injector) {
	const updateStatusMessage = (message: string) => {
		console.log("Status Message:", message);
		document.querySelector("#status-information p").innerHTML = message;
	};

	return () =>
		new Promise<any>(async (resolve: Function, reject) => {
			console.log("init app...");
			const appProvider = injector.get(AppstatusProvider);

			if (PHBGlobals.phbClient !== "www") {
				try {
					if (navigator.onLine) {
						await appProvider.isOnline(true);
					}
				} catch {
					console.info("Ping failed...");
				}
			} else {
				appProvider.onlineSignal.set(true);
			}

			console.log("isElectron:", isElectron());

			document.getElementById("status-information").style.display = "block";

			let startupAttributes: any = null;

			if (isElectron()) {
				const localSettings = await callAsyncElectron("readLocalSettings", () => {
				});
				if (localSettings.localBasePath) {
					ElectronLocalSettings.localBasePath = localSettings.localBasePath;
				}
				if (localSettings.localDbPath) {
					ElectronLocalSettings.localDbPath = localSettings.localDbPath;
				}
			} else {
				//not electron
			}

			updateStatusMessage(translate("Login") + "...");
			const userProvider = injector.get(UserProvider);
			await userProvider.init();

			updateStatusMessage("Loading data...");
			const dataProvider = injector.get(DataProvider);
			await dataProvider.init();

			updateStatusMessage("Loading translations...");

			try {
				const languageService = injector.get(LanguageService);
				await languageService.init();
			} catch (e) {
				console.error(e);
			}
			PHBGlobals.resetCurrentLanguage();

			if (isElectron()) {
				//set Attributes from Program parameters
				startupAttributes = await callAsyncElectron("getAppStartAttributes", () => {
				});
				console.log("startupAttributes:", startupAttributes);
				if (startupAttributes.lang) {
					if (!localStorage.getItem(`phb-language-${PHBGlobals.user.sessionHash}`)) {
						console.log("set lang from parameter...", startupAttributes.lang);
						localStorage.setItem(`phb-language-${PHBGlobals.user.sessionHash}`, startupAttributes.lang);
					}
				}

				if (startupAttributes.updater) {
					appProvider.startUpdater = true;
				}
			}


			try {

				await dataProvider.service.init();

				if (isElectron() && appProvider.startUpdater) {
					const updater = injector.get(UpdateProvider);
					updateStatusMessage(translate("updater_start"));
					if (!await updater.hasUpdate()) {
						appProvider.startUpdater = false;
					}
				}

				if (isElectron() && startupAttributes && startupAttributes.node) {
					await this.path.setCurrentNode(this.startupAttributes.node);
				}


				if (isElectron() && !PHBGlobals.index.i18n) {
					appProvider.startUpdater = true;
					console.log(PHBGlobals.index.version);
					PHBGlobals.dataSuccessfulLoaded = false;
				} else {
					if (isElectron() && PHBGlobals.index.version < 12753) {
						appProvider.startUpdater = true;
						console.log(PHBGlobals.index.version);
						PHBGlobals.dataSuccessfulLoaded = false;
					} else {
						updateStatusMessage(translate("success"));
						PHBGlobals.dataSuccessfulLoaded = true;
					}
				}
			} catch (e) {
				console.error(e);
			}


			const zip = injector.get(ZipProvider);
			await zip.init();

			dataProvider.searchData.set(JSON.parse(localStorage.getItem('phb-search-' + PHBGlobals.user.sessionHash)));

			console.log("User languages", PHBGlobals.user.languages, PHBGlobals.user.data.settings);

			console.log("app init done");
			document.getElementById("status-information").style.display = "none";
			resolve();
		});
}

const GUARDS = [
	AppLoadedGuard,
	UpdaterGuard
];

@NgModule({
    declarations: [
        AppComponent,
    ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LoginPopoverModule,
		FormsModule,
		ZipDownloadModalModule,
		SettingsModalModule,
		ProfileModalModule,
		PasswordModalModule,
		DirectivesModule,
		BrowserAnimationsModule,
		MatButtonModule,
		MatDialogModule,
		MatSnackBarModule,
		HeaderComponent,
		SearchSuggestionsComponent,
		MainComponent,
		TranslatePipe
	],
    providers: [
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        UserProvider,
        ZipProvider,
        DataProvider,
        LanguageService,
        AppstatusProvider,
        DomService,
        UpdateProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [Injector],
            multi: true
        },
        ...GUARDS
    ],
    bootstrap: [AppComponent],
    exports: [
        SearchSuggestionsComponent
    ]
})
export class AppModule {
}
