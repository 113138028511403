import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { Injectable }                                                                                   from "@angular/core";
import {
	isElectron
}                                                                                                       from "../../../_utils/electron-utils";
import { AppstatusProvider }                                                                            from "../../services/appstatus";


@Injectable()
export class UpdaterGuard  {
	constructor(private router: Router,
		private status: AppstatusProvider
	) {
	}

	async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		if (!this.can()) {
			await this.router.navigate(["/home"]);
			return;
		}
		return true;
	}

	async canActivate(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Promise<boolean> {

		return this.can();
	}

	can() {
		if (isElectron() && this.status.onlineSignal()) {
			return true;
		}
		return false;

	}
}
