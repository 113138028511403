import { NgModule }         from "@angular/core";
import { ZipDownloadModal } from "./zip-download";
import { RouterModule }     from "@angular/router";
import { FormsModule }      from "@angular/forms";
import { CommonModule }     from "@angular/common";
import { MatButtonModule }  from "@angular/material/button";
import { TranslatePipe }    from "../../pipes/translate";
import { FilesizePipe }     from "../../pipes/filesize";

@NgModule({
	declarations: [
		ZipDownloadModal
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild([
			{
				path: "",
				component: ZipDownloadModal
			}
		]),
		MatButtonModule,
		TranslatePipe,
		FilesizePipe
	]
})
export class ZipDownloadModalModule {
}
