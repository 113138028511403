import { Pipe, PipeTransform }    from "@angular/core";
import { translate }              from "../../_model/vocab.fun";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { IsoCode }                from "../../_model/Language";

@Pipe({
	name: "translate",
	standalone: true
})
export class TranslatePipe implements PipeTransform {

	constructor(public sanitized: DomSanitizer) {

	}

	transform(value: string, lang:IsoCode, placeholder: { [replacement: string]: string } = null): string | SafeHtml {
		let t = translate(value, placeholder);

		/**
		 * check if translated value contains a html string and return SafeHtml
		 * if it is SafeHtml it must be set to a property binding like <p [innerHtml]="'profile_terms' | translate"></p>
		 */
		if (t.match(/<[a-z][\s\S]*>/i)) {
			return this.sanitized.bypassSecurityTrustHtml(t);
		} else {
			return t;
		}


	}
}
