import * as _                                  from "lodash";
import {v4 as uuid}                            from "uuid";
import {PHBGlobals}                            from "../_config/config";
import {getCombinedIsoCode, IsoCode, Language} from "./Language";
import {convert_accented_characters}           from "../_utils/charTable";

export abstract class Index {
	version: number;
	date: Date;
	author?: string;
	resources: { [path: string]: Hash };

	root: Directory;

	i18n: boolean;

	protected _nodes: { [key: string]: Node };

	abstract getResources(): {
		name: string
		hash: Hash
		path: string
		used: boolean
	}[];

	abstract addResource(path: string, hash: Hash)

	abstract deleteResource(hash: Hash)

	abstract get nodes(): { [key: string]: Node };

	abstract get asArray(): Node[];

	abstract destroy(): void

	abstract _tempNodes: { [indexVersion: number]: { [key: string]: Node } };

	getNode(id: UUID): Node {
		return this.nodes[id];
	}

	getDirectory(id: UUID): Directory {
		const dir = this.nodes[id] as Directory;
		if (dir.nodeType !== "Directory") {
			throw new Error("NodeType is not a Directory!");
		}
		return dir;
	}

	getSections(): Section[] {
		return this.root.children.filter(it => it.nodeType === "Directory") as Section[];
	}
}

class _Index extends Index {
	version: number = -1;
	date: Date = null;
	author?: string = null;
	resources: { [path: string]: Hash } = {};

	i18n: boolean = false;

	root: Directory = new Directory();

	getResources(): {
		name: string
		hash: Hash,
		path: string
		used: boolean
	}[] {
		const resources = [];
		const indexResources = this.resources;
		for (let res in indexResources) {
			if (indexResources.hasOwnProperty(res)) {
				const hash = indexResources[res];
				const name = res.split("/").pop();
				const icon = {
					name: name,
					hash: hash,
					path: res,
					used: null
				};
				new Promise((resolve) => {
					resolve(this.asArray.filter(it => (it.icon && it.icon === name) || (it.treeIcon && it.treeIcon === name)).length > 0);
				}).then((u) => {
						icon.used = u;
					}
				);
				resources.push(icon);
			}
		}
		return resources.sort((a, b) => {
			const textA = a.name;
			const textB = b.name;
			return (textA).localeCompare(textB);
		});

	}

	addResource(path: string, hash: Hash) {
		this.resources[path] = hash;
	}

	deleteResource(hash: Hash) {
		for (let path in this.resources) {
			if (this.resources[path] === hash) {
				delete this.resources[path];
			}
		}

	}

	get asArray(): Node[] {
		const nodes = [];
		for (let n in this.nodes) {
			if (this.nodes.hasOwnProperty(n)) {
				nodes.push(this.nodes[n]);
			}
		}
		return nodes;
	}

	_tempNodes: { [indexVersion: number]: { [key: string]: Node } } = {};

	get nodes(): { [key: string]: Node } {
		if (this._tempNodes[this.version]) {
			return this._tempNodes[this.version];
		}
		const nodes: { [key: string]: Node } = {};
		const rec = (node) => {
			nodes[node.id] = node;
			if (node.nodeType === "Directory") {
				let dir = node as Directory;
				if (dir.children) {
					for (let child of dir.children) {
						rec(child);
					}
				}
			}
		};
		rec(this.root);
		this._tempNodes[this.version] = nodes;
		return nodes;
	};

	destroy() {
		this.version = -1;
		this.date = null;
		this.author = null;
		this.resources = {};
		this.root = new Directory();
		this._nodes = null;
	}
}

export class NodeTranslation {
	caption: string;
	description?: string;
	override?: boolean;

	get name(): string {
		return convert_accented_characters(this.caption)
			.replace(":", "")
			.replace("\\", "-")
			.replace("/", "-")
			.replace("*", "")
			.replace("<", "")
			.replace(">", "")
			.replace("?", "")
			.replace("|", "")
			.replace("&", "")
			.replace("\"", "")
			.replace("\'", "");
	}

	get systemFullName(): string {
		return this.name;
	}

	set name(n: string) {
	}
}

export class Node {
	id: UUID = uuid();
	nodeType: NodeType = null;
	heading?: boolean;
	files?: { [key: string]: any };

	_translations: { [isoCode: string]: NodeTranslation } = {};
	set translations(t: { [isoCode: string]: NodeTranslation }) {
		if (t) {
			this._translations = t;
		}
	}

	get translations(): { [isoCode: string]: NodeTranslation } {
		return this._translations;
	};

	/**
	 * get a NodeTranslation by language or fallback
	 * @param language
	 * @param withHardFallback
	 */
	getTranslationOrFallback(language: Language, withHardFallback: boolean = true): [IsoCode, NodeTranslation] {
		const byLanguage = (_language: Language): [IsoCode, NodeTranslation] => {
			if (PHBGlobals.user.loggedIn) {
				if (PHBGlobals.user.data.country && language.countries.includes(PHBGlobals.user.getCountry())) {
					const combined = getCombinedIsoCode(_language.isocode, PHBGlobals.user.getCountry());
					if (this.translations.hasOwnProperty(combined)) {
						return [combined, this.translations[combined]];
					}
				}
			}
			for (let country of _language.countries) {
				const combined = getCombinedIsoCode(_language.isocode, country);
				if (this.translations.hasOwnProperty(combined)) {
					return [combined, this.translations[combined]];
				}
			}
			return [undefined, undefined];
		};

		//if fallback is disabled by environment...
		if (!PHBGlobals.useTranslationFallback) {
			const lang = PHBGlobals.getLanguage(this.getSection().defaultLanguage);
			const [isoCode, translation] = byLanguage(lang);
			if (translation) {
				return [isoCode, translation];
			}
			//TODO throw new Error(`No translation found for ${isoCode}!`);
			const iso = Object.keys(this.translations)[0];
			//console.log(`Return remaining translations if none is found: ${this.id}`, iso);
			return [iso, this.translations[iso]];
			//return [this.getSection().defaultLanguage, {caption: `No translation found for ${isoCode}`}];
		}


		//get value by current language
		let [isoCode, translation] = byLanguage(language);
		if (isoCode && translation) {
			return [isoCode, translation];
		}

		//"Diese Übersetzung immer verwenden"
		for (let isoCode in this.translations) {
			if (this.translations.hasOwnProperty(isoCode) && this.translations[isoCode].override) {
				return [isoCode, this.translations[isoCode]];
			}
		}

		//get value by user fallback-languages
		for (let _isoCode of PHBGlobals.user.languages) {
			[isoCode, translation] = byLanguage(PHBGlobals.getLanguage(_isoCode));
			if (isoCode && translation) {
				return [isoCode, translation];
			}
		}

		//get value by all fallback-languages
		if (withHardFallback) {
			for (let isoCode in PHBGlobals.languages) {
				if (PHBGlobals.languages.hasOwnProperty(isoCode)) {
					const language = PHBGlobals.languages[isoCode];
					for (let country of language.countries) {
						const combined = getCombinedIsoCode(language.isocode, country);
						if (this.translations.hasOwnProperty(combined)) {
							return [combined, this.translations[combined]];
						}
					}

				}
			}
		}

		if(Object.keys(this.translations).length>0) {
			console.error(`No valid translation found for ${language.isocode} on Node ${this.id}!`);
			console.log(language, this);
			const lastChance = Object.keys(this.translations)[0];
			return [lastChance,this.translations[lastChance]];
		}


		//TODO remove debug info
		console.log(language, this);
		throw new Error(`No valid translation found for ${language.isocode} on Node ${this.id}!`);
	}

	/**
	 * get a NodeTranslation by isoCode
	 * @param isoCode
	 * @param withHardFallback
	 */
	getTranslation(isoCode: IsoCode = null, withHardFallback: boolean = true): [IsoCode, NodeTranslation] {
		if (isoCode) {
			return this.getTranslationOrFallback(
				PHBGlobals.getLanguage(isoCode),
				withHardFallback
			);
		} else {
			return this.getTranslationOrFallback(
				PHBGlobals.language,
				withHardFallback
			);
		}
	}

	getTranslationProperty(property: string, language: Language = null) {
		const [isoCode, translation] = this.getTranslationOrFallback(language || PHBGlobals.language);
		if (property in translation) {
			return translation[property];
		}
		throw new Error(`No value on "${property}" found for node ${this.id}!`);
	}

	get caption(): string {
		return this.getTranslationProperty("caption");
	}

	get description(): string {
		try {
			return this.getTranslationProperty("description");
		} catch (e) {
			return null;
		}
	}

	get isoCode(): IsoCode {
		const [isoCode, translation] = this.getTranslationOrFallback(PHBGlobals.getLanguage());
		return isoCode;
	}

	icon: string = null;
	treeIcon: string = null;

	// Following: client-side properties (TODO: really? sort!)
	niceIconPath?: string = null;

	parentIds?: UUID[] = [];
	parentId: UUID = null;

	color?: ColorHash = null;
	childsColor?: ColorHash = null;
	textColor?: ColorHash = null;

	tags?: number[] = null;
	specialType: SpecialType = null;
	specialParent: UUID = null;
	groups?: number[] = null;

	modificationTime?: number = null;

	accessRights?: number[] = null;
	securityLevel?: number = null;

	hidden?: HiddenValue[] = null;
	zip?: ZipFunction = null;

	disabled?: boolean = false;

	constructor(parent: Directory = null) {
		if (parent) {
			this.parentId = parent.id;
			this.parentIds = [...parent.parentIds, parent.id];
			this.accessRights = [...parent.accessRights];
			this.securityLevel = parent.securityLevel;
		} else {
			this.securityLevel = 6;
		}
	}

	traverse(parent: Directory | CollectionTarget, modifier: (...args) => void) {
		modifier(this, parent);
	}

	withFilter(filter: (...args) => boolean): Node {
		if (filter(this)) {
			return this;
		}
		return null;
	}

	isSection(): boolean {
		return false;
	}

	getSection(): Section {
		if (!this.parentIds[1]) {
			throw new Error("No section available!");
		}
		return PHBGlobals.index.getNode(this.parentIds[1]) as Section;
	}

	isFileType(): boolean {
		return ["File", "VirtualFile", "WebLink"].includes(this.nodeType);
	}

	getBreadcrumb(): Node[] {
		const breadcrumb: Node[] = [];


		for (let i = 1; i < this.parentIds.length; i++) {
			breadcrumb.push(PHBGlobals.index.nodes[this.parentIds[i]]);
		}

		breadcrumb.push(this);

		return breadcrumb;
	}

	getParent(): Directory {
		const p = this.parentIds;
		if (this.parentId === null && p[p.length - 1] === null) {
			return null;
		}
		if (!this.parentId) {
			return PHBGlobals.index.nodes[p[p.length - 1]] as Directory;
		}
		return PHBGlobals.index.nodes[this.parentId] as Directory;
	}

}

export type HiddenValue = "www" | "ios" | "win"
export type SpecialType = "book" | "cad" | "page" | "cad-entry" | "collection"

export class Directory extends Node {

	nodeType: NodeType = "Directory";

	children: Node[] = [];
	hideChildren:boolean = false;

	icon: string = "ordner.png";

	heading: boolean = false;
	private _video: boolean = false;

	get video(): boolean {
		const parent = this.getParent();
		return this._video || (parent ? parent.video : null);
	}

	set video(b: boolean) {
		this._video = b;
	}


	get name(): string {
		const caption = this.getTranslationProperty("caption");
		return convert_accented_characters(caption)
			.replace(":", "")
			.replace("\\", "-")
			.replace("/", "-")
			.replace("*", "")
			.replace("<", "")
			.replace(">", "")
			.replace("?", "")
			.replace("|", "")
			.replace("&", "")
			.replace("\"", "")
			.replace("\'", "");

	}

	set name(m: string) {
	}

	traverse(parent: Directory | CollectionTarget, modifier: (...args) => void) {
		modifier(this, parent);

		for (let child of this.children) {
			child.traverse(this, modifier);
		}
	}

	withFilter(filter: (...args) => boolean): Directory {
		if (filter(this)) {
			const dup: Directory = _.cloneDeep(this);
			const dir = dup as Directory;
			const children = [];
			for (let child of dir.children) {
				const res = child.withFilter(filter);
				if (res) {
					children.push(res);
				}
			}
			dir.children = children;
			return dup;
		}
		return null;
	}

	isSection(): boolean {
		return this.parentIds.length <= 1;
	}
}

export class Heading extends Directory {
	heading = true;
	icon: string = null;
}

export class VideoDirectory extends Directory {
	constructor(parent: Directory = null) {
		super(parent);
		this.video = true;
	}
}

export class DirectoryLink extends Node {
	static create(copy: Directory, target: Directory): DirectoryLink {
		throw new Error("Not implemented yet!");
	}
}

export class CollectionTarget extends Node {
	nodeType: NodeType = "CollectionTarget";

	collectionTarget: UUID;
	filters: number[];
	children?: Node[];

	traverse(parent: Directory | CollectionTarget, modifier: (...args) => void) {
		modifier(this, parent);

		for (let child of this.children) {
			child.traverse(this, modifier);
		}
	}
}

export class Section
	extends Directory {
	defaultLanguage: IsoCode;

	getSection(): Section {
		return this;
	}
}

export class FileTranslation extends NodeTranslation {
	private _name: string = "";
	get name(): string {
		return this._name;
	}

	set name(n: string) {
		this._name = n;
	}

	size: number = 0;
	pages: number = 0;
	hash: Hash;
	mimeType: string;

	get systemFullName(): string {
		return convert_accented_characters(this.name)
			.replace(":", "")
			.replace("\\", "-")
			.replace("/", "-")
			.replace("*", "")
			.replace("<", "")
			.replace(">", "")
			.replace("?", "")
			.replace("|", "")
			.replace("&", "")
			.replace("\"", "")
			.replace("\'", "");

	}

	modificationTime?: number = null;

	isVideo(): boolean {
		switch (this.mimeType) {
			case "video/mpeg":
			case "video/mp4":
			case "video/webm":
			case "video/ogg":
				return true;
			default:
				return false;
		}
	}

	isWebContent(): boolean {
		switch (this.mimeType) {
			case "application/pdf":
			case "video/mpeg":
			case "text/html":
			case "text/plain":
			case "image/jpeg":
			case "image/png":

			case "audio/mpeg":
			case "audio/ogg":
			case "audio/*":

			case "video/mp4":
			case "video/webm":
			case "video/ogg":

			case "application/json":

				//case "application/octet-stream":
				return true;
			default:
				return false;
		}
	}

	isPDF(): boolean {
		return this.mimeType === "application/pdf";
	}
}

export class File extends Node {
	nodeType: NodeType = "File";


	_translations: { [isoCode: string]: FileTranslation } = {};
	set translations(t: { [isoCode: string]: FileTranslation }) {
		if (t) {
			this._translations = t;
		}
	}

	get translations(): { [isoCode: string]: FileTranslation } {
		return this._translations;
	};

	external?: boolean = false;
	viewer: Viewer = "Intern";


	collectable?: boolean = true;
	previewHash?: Hash = null;

	set hash(h: Hash) {
	}

	get hash(): Hash {
		return this.getTranslationProperty("hash");
	}

	set mimeType(m: string) {
	}

	get mimeType(): string {
		return this.getTranslationProperty("mimeType");
	}

	set name(m: string) {
	}

	get name(): string {
		return this.getTranslationProperty("name");
	}

	get language(): string {
		const [isoCode, translation] = this.getTranslationOrFallback(PHBGlobals.language);
		return isoCode;
	}

	set size(m: number) {
	}

	get size(): number {
		return this.getTranslationProperty("size");
	}

	set pages(p: number) {
	}

	get pages(): number {
		if (this.isPDF()) {
			try {
				return this.getTranslationProperty("pages");
			} catch (e) {
				return 0;
			}
		} else {
			return null;
		}
	}


	modificationTime:number;

	isVideo(): boolean {
		const [isoCode, translation] = this.getTranslation();
		return translation.isVideo();
	}

	isWebContent(): boolean {
		if (this.external) {
			return false;
		}

		const [isoCode, translation] = this.getTranslation();
		return translation.isWebContent();
	}

	isPDF(): boolean {
		const [isoCode, translation] = this.getTranslation();
		return translation.isPDF();
	}

	isInternalViewer(): boolean {
		return this.viewer === "Intern" || !this.viewer;
	}

	getTranslationOrFallback(language: Language, withHardFallback: boolean = true): [IsoCode, FileTranslation] {
		const [isoCode, translations] = super.getTranslationOrFallback(language, withHardFallback);
		return [isoCode, translations as FileTranslation];
	}

	/**
	 * get a FileTranslation by isoCode
	 * @param isoCode
	 * @param withHardFallback
	 */
	getTranslation(isoCode: IsoCode = null, withHardFallback: boolean = true): [IsoCode, FileTranslation] {
		let [_isoCode, translations] = super.getTranslation(isoCode, withHardFallback);
		return [_isoCode, translations as FileTranslation];
	}

}

export class FileLink extends Node {
	nodeType: NodeType = "FileLink";
	fileId: UUID;

	set translations(t: { [isoCode: string]: NodeTranslation }) {
		throw new Error("Not allowed!");
	}

	get translations(): { [isoCode: string]: NodeTranslation } {
		if (PHBGlobals.index.nodes[this.fileId]) {
			return PHBGlobals.index.nodes[this.fileId].translations;
		}
	}

	static create(copy: File, target: Directory): FileLink {
		const link = new FileLink();
		Object.assign(link, {
			id: uuid(),
			parentId: target.id,
			parentIds: [...target.parentIds, target.id],
			fileId: copy.id
		});
		return link;
	}
}

export class WebLinkTranslation extends NodeTranslation {
	url: string;
}

export class WebLink extends Node {
	set url(u: string) {
		//DEPRECATED!
	}

	get url(): string {
		return this.getTranslationProperty("url");
	}

	nodeType: NodeType = "WebLink";
}

export class VirtualFile extends File {
	nodeType: NodeType = "VirtualFile";

	isWebContent(): boolean {
		return true;
	}
}

export type UUID = string
export type Hash = string

export type NodeType = "Directory" |
	"File" |
	"WebLink" |
	"FileLink" |
	"DirectoryLink" |
	"CollectionTarget" |
	"VirtualFile"

export type ClientType = "www" | "win"


/**
 * Color in the format "#rrggbb", e.g. "#ffff00" for yellow.
 */
export type ColorHash = string

export type Viewer = "Intern" | "Extern";

export type ZipFunction = "Allow" | "Parent" | "Exclude"

PHBGlobals.index = new _Index();

export function assignIndex(indexJson): void {
	indexJson.root = assignDirectory(indexJson.root);
	Object.assign(PHBGlobals.index, indexJson);
}

export function assignNode(nodeJson, depth: number = 0): Node {


	if (nodeJson.nodeType !== "FileLink") {


		const trans = {};
		for (let isocode in nodeJson.translations) {
			switch (nodeJson.nodeType) {
				case "VirtualFile":
				case "File":
					trans[isocode] = Object.assign(new FileTranslation(), nodeJson.translations[isocode]);
					break;
				case "WebLink":
					trans[isocode] = Object.assign(new WebLinkTranslation(), nodeJson.translations[isocode]);
					break;
				default:
					trans[isocode] = Object.assign(new NodeTranslation(), nodeJson.translations[isocode]);
					break;
			}
		}
		nodeJson.translations = trans;
	}
	delete nodeJson.caption;
	delete nodeJson.description;


	if (nodeJson.tags && (nodeJson.tags.indexOf(21) !== -1 || nodeJson.tags.indexOf(22) !== -1) && nodeJson.modificationTime) {
		const d = new Date();
		d.setDate(d.getDate() - 30);
		if (nodeJson.modificationTime < d.getTime()) {
			const pos21 = nodeJson.tags.indexOf(21);
			if (pos21 !== -1) {
				nodeJson.tags.splice(pos21, 1);
			}
			const pos22 = nodeJson.tags.indexOf(22);
			if (pos22 !== -1) {
				nodeJson.tags.splice(pos22, 1);
			}
		}
	}

	let obj: Node = null;
	switch (nodeJson.nodeType) {
		case "Directory":
			if (depth === 1) {
				obj = assignSection(nodeJson);
			} else {
				obj = assignDirectory(nodeJson);
			}
			break;
		case "File":
			obj = Object.assign(new File(), nodeJson);
			break;
		case "FileLink":
			delete nodeJson.translations;
			obj = Object.assign(new FileLink(), nodeJson);
			break;
		case "WebLink":
			obj = Object.assign(new WebLink(), nodeJson);
			break;
		case "CollectionTarget":
			obj = Object.assign(new CollectionTarget(), nodeJson);
			break;
		case "VirtualFile":
			obj = Object.assign(new VirtualFile(), nodeJson);
			break;
		default:
			throw new Error("Not implemented!");
	}

	if (!obj) {
		throw new Error("Invalid Object!");
	}

	if (!nodeJson.icon) {
		obj.icon = null;
	}

	if (!nodeJson.treeIcon) {
		obj.treeIcon = null;
	}

	return obj;

}

function assignDirectory(dirJson, depth: number = 0): Directory {
	delete dirJson.name;
	if (dirJson.children) {
		const children = [];
		for (let child of dirJson.children) {
			children.push(assignNode(child, depth + 1));
		}
		dirJson.children = children;
	}
	const dir = Object.assign(new Directory(), dirJson);
	if (!dir.translations) {
		console.error(dir);
	}
	if (dir.isSection()) {
		return Object.assign(new Section(), dirJson);
	}
	return dir;
}

function assignSection(dirJson): Directory {
	delete dirJson.name;
	if (dirJson.children) {
		const children = [];
		for (let child of dirJson.children) {
			children.push(assignNode(child, 2));
		}
		dirJson.children = children;
	}
	const dir = Object.assign(new Directory(), dirJson);
	if (!dir.translations) {
		console.error(dir);
	}
	if (dir.isSection()) {
		return Object.assign(new Section(), dirJson);
	}
	return dir;
}
