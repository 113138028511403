import {Pipe, PipeTransform}          from "@angular/core";
import {File, FileTranslation}        from "../../_model";
import {getCombinedIsoCode, Language} from "../../_model/Language";
import {PHBGlobals}                   from "../../_config/config";


@Pipe({
	name: "defaultTranslation",
	standalone: true
})
export class DefaultTranslationPipe implements PipeTransform {

	transform(file: File, language: Language): {
		key: string
		value: FileTranslation
	} {

		if (PHBGlobals.user.loggedIn && language.countries.includes(PHBGlobals.user.getCountry())) {
			const isoCode = getCombinedIsoCode(language.isocode, PHBGlobals.user.getCountry());
			if (file.translations.hasOwnProperty(isoCode)) {
				return {
					key: isoCode,
					value: file.translations[isoCode]
				};
			}
		}

		let isoCode = getCombinedIsoCode(language.isocode, language.countries[0]);
		if (file.translations.hasOwnProperty(isoCode)) {
			return {
				key: isoCode,
				value: file.translations[isoCode]
			};
		} else {
			for (let country of language.countries) {
				isoCode = getCombinedIsoCode(language.isocode, country);
				if (file.translations.hasOwnProperty(isoCode)) {
					return {
						key: isoCode,
						value: file.translations[isoCode]
					};
				}
			}
		}
	}

}
