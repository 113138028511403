import { Component } from "@angular/core";
import { MatDialogRef }      from "@angular/material/dialog";
import { Iso639_1 } from "../../../_model/Language";
import { PHBGlobals } from "../../../_config/config";
import { MatButtonModule } from "@angular/material/button";
import { TranslatePipe } from "../../pipes/translate";

@Component({
	selector: "app-update-downloaded",
	templateUrl: "./update-downloaded.component.html",
	styleUrls: ["./update-downloaded.component.scss"],
	standalone: true,
	imports: [
		MatButtonModule,
		TranslatePipe
	]
})
export class UpdateDownloadedComponent {

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}


	constructor(protected dialogRef: MatDialogRef<UpdateDownloadedComponent>) {
	}

	acceptAndClose() {
		this.dialogRef.close('restart');
	}

	close() {
		this.dialogRef.close();
	}

}
