import { Pipe, PipeTransform } from "@angular/core";
import { getTranslationOrDefault } from "../../_utils/general-utils";
import { PHBGlobals } from "../../_config/config";
import { Node } from "../../_model";
import { IsoCode } from "../../_model/Language";

@Pipe({
	name: "caption",
	standalone: true
})
export class CaptionPipe implements PipeTransform {

	transform(value: any | Node, isoCode: IsoCode = null): any {
		if(value === null) return "";
		if (typeof value === "object" && value.hasOwnProperty("nodeType")) {
			const node = value as Node;
			if (isoCode && node.translations.hasOwnProperty(isoCode)) {
				return node.translations[isoCode].caption;
			}
			return node.getTranslationProperty("caption", PHBGlobals.getLanguage(PHBGlobals.systemLanguage));
		}
		return getTranslationOrDefault(value, PHBGlobals.systemLanguage);
	}

}
