import { NgModule }         from "@angular/core";
import { LoginPopover }     from "./login";
import { CommonModule }     from "@angular/common";
import { RouterModule }     from "@angular/router";
import { FormsModule }      from "@angular/forms";
import { MatButtonModule }  from "@angular/material/button";
import { LoginComponent }   from "../../components/login/login.component";
import { TranslatePipe }    from "../../pipes/translate";

@NgModule({
	declarations: [
		LoginPopover
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild([
			{
				path: "",
				component: LoginPopover
			}
		]),
		MatButtonModule,
		LoginComponent,
		TranslatePipe
	]
})
export class LoginPopoverModule {
}
