import { Component, effect, input, InputSignal, output } from "@angular/core";
import { FormsModule } from "@angular/forms";


@Component({
	selector: "country-select",
	templateUrl: "./country-select.component.html",
	styleUrls: ["./country-select.component.scss"],
	standalone: true,
	imports: [
		FormsModule
	]
})
export class CountrySelectComponent {

	_country: string;
	country: InputSignal<string> = input<string>(null, {alias: "ngModel"});

	ngModelChange = output<string>();
	countryChange = output<string>();

	disabled: InputSignal<boolean> = input<boolean>(false);
	placeholder: InputSignal<string> = input<string>("-- Bitte wählen --");

	constructor() {
		effect(() => {
			this._country = this.country();
		});
	}

	change() {
		this.countryChange.emit(this.country());
		this.ngModelChange.emit(this.country());
	}

}
