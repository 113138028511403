<div class="alert__wrapper">
	<div class="alert__header">
		{{ "updater_error_occurred" | translate:systemLanguage }}
	</div>
	<div class="alert__message">
		{{ msg }}
	</div>
	<div class="alert__buttons">
		<button mat-flat-button (click)="retry()">{{ 'retry' | translate:systemLanguage }}</button>
		@if(showSkip) {
			<button mat-flat-button (click)="skip()">{{ 'skip' | translate:systemLanguage }}</button>
		}
	</div>
</div>
