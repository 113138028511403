import { Component, effect, Signal } from "@angular/core";
import { User }                                 from "../../../_model/user";
import { Router }                    from "@angular/router";
import { UpdateProvider }            from "../../services/update/update";
import { Iso639_1 }                  from "../../../_model/Language";
import { PHBGlobals }                from "../../../_config/config";
import { UserProvider }              from "../../services/user/user";
import { AppstatusProvider }         from "../../services/appstatus";
import { TranslatePipe }             from "../../pipes/translate";
/**
 * This component shows a button when an update is available on the PHB server.
 */
@Component({
	selector: "updater",
	templateUrl: "updater.html",
	styleUrls: ["updater.scss"],
	standalone: true,
	imports: [
		TranslatePipe
	]
})
export class UpdaterComponent {

	update = false;

	currentUser: Signal<User>;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	get show(): boolean {
		return !this.currentUser()?.data.settings.silentUpdater && this.appStatus.onlineSignal();
	}

	constructor(public userService: UserProvider,
		private updateService: UpdateProvider,
		private appStatus: AppstatusProvider,
		private router: Router) {

		effect(() => {
			const update = this.updateService.updateAvailable();
			this.update = update !== null && update.downloads.length > 0;
		});

		this.currentUser = this.userService.currentUserSignal;
	}

	async openUpdater() {
		await this.router.navigate(["/updater"]);
	}
}
