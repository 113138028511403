<div class="modal-header">
	<h1 class="modal-title">
		@switch (systemLanguage) {
			@case ("de") {
				Information zum Planerhandbuch
			}
			@case ("nl") {
				Informatie over het "Planerhandbuch"
			}
			@case ("fr") {
				Information sur « Planerhandbuch »
			}
			@case ("es") {
				Información sobre el “Planerhandbuch”
			}
			@default {
				Information about the "Planerhandbuch"
			}
		}
	</h1>
</div>

<div class="page-content p-a">
	<div class="content-inner">

		@switch (systemLanguage) {
			@case ("de") {
				<h1>Sehr geehrte Kunden, Partner, Vertriebsmitarbeiter und Techniker,</h1>
				<p>wir freuen uns, Ihnen mitteilen zu können, dass unser Webportal Planerhandbuch.de eine neue,
					erweitere Suchfunktion erhält. Diese Verbesserung wird es Ihnen ermöglichen, eine sehr individuelle
					Suche nach benötigten Dokumenten durchzuführen.</p>

				<p><a target="_blank" [href]="pdfUrl">Mehr erfahren!</a></p>
			}
			@case ("nl") {
				<h1>Beste klanten, partners, verkoopmedewerkers en technici,</h1>
				<p>Met veel plezier willen wij u laten weten dat onze webportal Planerhandbuch.de een nieuwe, uitgebreide zoekfunctie krijgt. Deze verbetering zal u in staat stellen heel individueel te zoeken naar benodigde documenten.</p>

				<p><a target="_blank" [href]="pdfUrl">Leer meer!</a></p>
			}
			@case ("fr") {
				<h1>Chers clients, partenaires, commerciaux et techniciens,</h1>
				<p>Nous sommes heureux de vous annoncer que notre portail Web Planerhandbuch.de dispose d’une nouvelle fonction de recherche avancée. Grâce à cette amélioration, vous allez pouvoir rechercher les documents dont vous avez besoin de manière très personnalisée.</p>

				<p><a target="_blank" [href]="pdfUrl">Apprendre encore plus!</a></p>
			}
			@case ("es") {
				<h1>Estimados clientes, socios, personal de ventas y técnicos:</h1>
				<p>Nos complace poder informarles que nuestro portal web Planerhandbuch.de adoptó una nueva función de búsqueda avanzada. Esta mejora le permitirá realizar una búsqueda completamente personalizada de los documentos requeridos.</p>

				<p><a target="_blank" [href]="pdfUrl">¡Aprende más!</a></p>
			}
			@default {
				<h1>Dear Customers, Partners, Sales Staff, and Technicians</h1>
				<p>We are delighted to inform you that our online portal at Planerhandbuch.de features a new advanced search function. This improvement will help you to run customized searches to find the documents you need.</p>

				<p><a target="_blank" [href]="pdfUrl">Read more!</a></p>
			}
		}
	</div>
</div>

<div class="page-footer p-a">
	<button color="primary" mat-flat-button class="mat-small-button" (click)="close()">{{'close' | translate:systemLanguage}}</button>
</div>
