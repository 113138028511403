import { Injectable }                   from "@angular/core";
import { HttpClient }                   from "@angular/common/http";
import { isElectron }                   from "../../_utils/electron-utils";
import { MatDialog }                    from "@angular/material/dialog";
import { VersionChangedComponent }      from "../dialogs/version-changed/version-changed.component";

/**
 * https://medium.com/@aakashbumiya/auto-reload-for-clients-after-deploy-with-angular-7-production-build-bdc45be9b2bd
 */

@Injectable({
	providedIn: "root"
})
export class VersionCheckService {

	private currentHash = "{{POST_BUILD_ENTERS_HASH_HERE}}";
	private currentVersion = "{{POST_BUILD_ENTERS_VERSION_HERE}}";

	private repeatVersionCheck: boolean = true;

	constructor(private http: HttpClient,
		private dialog: MatDialog) {
	}

	/**
	 * Checks in every set frequency the version of frontend application
	 * @param url
	 * @param {number} frequency - in milliseconds, defaults to 30 minutes
	 */
	public initVersionCheck(url, frequency = 1000 * 60 * 30) {
		setInterval(() => {
			this.checkVersion(url);
		}, frequency);
		this.checkVersion(url);
	}

	/**
	 * Will do the call and check if the hash has changed or not
	 * @param url
	 */
	private checkVersion(url) {
		if (isElectron()) {
			return;
		}
		if (!this.repeatVersionCheck) {
			return;
		}
		// timestamp these requests to invalidate caches
		this.http.get(location.origin + "/version.json?t=" + new Date().getTime())
			.subscribe(
				async (response: any) => {
					const hash = response.hash;
					const hashChanged = this.hasHashChanged(this.currentHash, hash);
					const versionChanged = this.hasVersionChanged(this.currentVersion, response.version);
					// If new version, do something
					if (versionChanged || hashChanged) {
						this.repeatVersionCheck = false;
						this.dialog.open(VersionChangedComponent, {
							panelClass: "cdk-overlay__alert",
							backdropClass: "cdk-overlay__alert-backdrop"
						});
					}
					// store the new hash so we wouldn't trigger versionChange again
					// only necessary in case you did not force refresh
					this.currentHash = hash;
				},
				(err) => {
					console.error(err, "Could not get version");
				}
			);
	}

	/**
	 * Checks if hash has changed.
	 * This file has the JS hash, if it is a different one than in the version.json
	 * we are dealing with version change
	 * @param currentHash
	 * @param newHash
	 * @returns {boolean}
	 */
	private hasHashChanged(currentHash, newHash) {
		if (!currentHash || currentHash === "{{POST_BUILD_ENTERS_HASH_HERE}}") {
			return false;
		}
		return currentHash !== newHash;
	}

	private hasVersionChanged(_current, _new) {
		if (!_current || _current === "{{POST_BUILD_ENTERS_VERSION_HERE}}") {
			return false;
		}
		return _current !== _new;
	}
}
