/**
 * Sprachenpaket mit Übersetzungen.
 * Zugriff z.B.: languages["de"]["S2"] = "Pfahltyp"
 */
import { PHBGlobals }            from "../_config/config";
import { global_vocab_settings } from "./vocab";

/**
 * Übersetze das gegebene Wort in die aktuelle Sprache
 * oder in die Fallback-Sprache.
 */
export function translate(wordId: string,placeholder:{[replacement:string]:string} = null): string {
	let translation:string = null;
	const langId = PHBGlobals.systemLanguage;
	if (!wordId)
		return "";
	let s = global_vocab_settings;

	let lang = s.vocab.languages[langId];
	if (lang) {
		let value = lang[wordId];
		if (value) {
			translation = value;
		} else {
			console.log("cant find translation for " + wordId);
		}
	} else {
		console.log("cant find language " + langId);
	}
	if(translation === null) {
		//Nicht gefunden? Versuche nochmal in Fallbacksprache
		lang = s.vocab.languages[s.fallbackLanguageId];
		if (lang) {
			let value = lang[wordId];
			if (value) {
				translation = value;
			} else {
				console.log("cant find translation for " + wordId + " in fallback language " + s.fallbackLanguageId);
			}
		}
	}
	if(translation !== null) {
		if(placeholder!=null){
			for(let p in placeholder){
				translation = translation.replace(p,placeholder[p]);
			}
		}
		return translation;
	}
	//Immer noch nicht gefunden? Verwende Word-ID als Fallback
	return wordId;
}
