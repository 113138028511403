import { Injectable, Injector }                                             from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { from, Observable }                                                 from "rxjs";
import { UserProvider }                                                     from "../services/user/user";
import { ElectronLocalSettings }                                            from "../services/data/winDataService";
import { isElectron }                                                       from "../../_utils/electron-utils";
import { PHBGlobals }                                                       from "../../_config/config";
import { mergeMap }                                                         from "rxjs/operators";
import { environment }                                                      from "../../environments/environment";
import * as KJUR                                                            from "jsrsasign";
import { AppstatusProvider }                                                from "../services/appstatus";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

	constructor(private injector: Injector) {
	}

	getToken(isRefreshRoute: boolean): Observable<any> {
		const userProvider = this.injector.get(UserProvider);
		return from(
			new Promise<any>(async (resolve, reject) => {
				(new Promise<any>(async (_resolve, _reject) => {
					_resolve({
						accessToken: userProvider.token
					});
				})).then(async res => {
					let auth = res as any;
					let token: string = auth.accessToken;

					if (token) {
						let payload = KJUR.jws.JWS.parse(token).payloadObj;

						const preventAt = (new Date().getTime() / 1000) - (60 * 10);
						const now = (new Date().getTime() / 1000);
						if (payload.exp < now) {
							if (!isElectron()) {
								await userProvider.logout(true);
								reject(null);
							} else {
								await userProvider.loginElectronSSO();
								resolve(userProvider.token);
							}
						}

						if (!isRefreshRoute && payload.exp < preventAt) {
							console.log("Refreshing token!", new Date(payload.exp), new Date(preventAt));
							await userProvider.refreshToken();
							resolve(userProvider.token);
						} else {
							resolve(token);
						}
					}
					resolve(null);
				});
			})
		);
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.indexOf(environment.localBasePath) === -1
			&& !request.url.includes(ElectronLocalSettings.LocalClientPath)
			&& !request.url.includes(ElectronLocalSettings.localDbPath)
			&& !request.url.includes("AppData/Local/")
			&& !request.url.includes("user/login_check")
			&& !request.url.includes("api/4.4/software/")
			&& !request.url.includes("ping")
			&& !request.url.includes("/languages")
			//&& request.url.indexOf("user/refresh") === -1
		) {
			if (isElectron() && !request.url.includes(`${environment.kotlin}/index`)) {
				const appStatus = this.injector.get(AppstatusProvider);
				if (!appStatus.onlineSignal()) {
					console.log("Client is offline pass without token url:", request.url);
					return next.handle(request);
				} else {
					console.log(request.url);
				}
			}

			return this.getToken(request.url.indexOf("user/refresh") !== -1).pipe(
				mergeMap((token: string) => {
						//console.log("external request", request.url);
						//console.log(request.headers);
						let headers = request.headers.set("PHB-Client", PHBGlobals.phbClient + "");

						if (token) {
							headers = headers.set("Authorization", "Bearer " + token);
						}

						if (request.url.indexOf("/user/current") === -1) {
							headers = headers.set("PHB-Language", PHBGlobals.user.languages.join(","));
						}

						if (PHBGlobals.phbVersion !== null) {
							headers = headers.set("PHB-Index-Version", PHBGlobals.phbVersion + "");
						}

						const req = request.clone({
							headers: headers
						});

						return next.handle(req);
					}
				)
			);
		} else {
			return next.handle(request);
		}
	}
}
