import { Component, effect, Injector, OnInit, ViewChild, WritableSignal } from "@angular/core";
import {PHBGlobals}                                                       from "../../../../_config/config";
import { AngularSplitModule, SplitComponent } from "angular-split";
import {AppstatusProvider}                    from "../../../services/appstatus";
import {DataProvider}                 from "../../../services/data/data";
import {UserProvider}                 from "../../../services/user/user";
import {File, Node}                   from "../../../../_model";
import { setSplitMenu }         from "../../../services/gui";
import { Router, RouterModule } from "@angular/router";
import { SearchbarComponent } from "../../../components/searchbar/searchbar";
import { SectionListComponent } from "../../../components/section-list/section-list";
import { MenuListComponent }                  from "../../../components/menu-list/menu-list";


@Component({
	selector: "app-phb",
	templateUrl: "./phb.component.html",
	styleUrls: ["./phb.component.scss"],
	standalone: true,
	imports: [
    AngularSplitModule,
    RouterModule,
    SearchbarComponent,
    SectionListComponent,
    MenuListComponent
]
})
export class PhbComponent implements OnInit {

	splitArea = {
		initialized: false,
		contentView: {
			initialSize: 75
		},
		menuView: {
			size: 0,
			initialSize: 25
		}
	};
	@ViewChild(SplitComponent) splitEl: SplitComponent;

	online: WritableSignal<boolean>;

	currentNode: Node;

	protected readonly window = window;

	constructor(public data: DataProvider,
	            public userProvider: UserProvider,
	            private appProvider: AppstatusProvider,
				private router: Router,
				private injector: Injector) {

		effect(() => {
			const node = this.data.currentNode();
			if (this.splitEl) {
				this.currentNode = node;
				this.splitEl.disabled = this.currentNode?.nodeType === "File" && (this.currentNode as File).mimeType === "application/pdf";
			}
		});

		this.online = this.appProvider.onlineSignal;

	}

	ngAfterViewInit() {
		effect(() => {
			const user = this.userProvider.currentUserSignal();
			console.log("USER", user);
			if (user?.data?.settings) {
				if (!user.data.settings.showMenu) {
					this.setMenuSize(0);
				}
				else {
					let storedMenuSize = Number(localStorage.getItem('menu-size'));
					if(storedMenuSize === 0) {
						localStorage.setItem('menu-size', "25");
					}
					this.initSplitMenu();
				}

				if (user.data.settings.showMenu !== PHBGlobals.showMenu) {
					PHBGlobals.showMenu = user.data.settings.showMenu;
				}
			}
		}, {
			injector: this.injector
		});
	}

	ngOnInit() {

		const view = function (client) {
			switch (client) {
				case "win":
					return "desktop";
			}
			return window.innerWidth < 1024 ? "mobile" : "desktop";
		}(PHBGlobals.phbClient);
		if (view === "mobile") {
			PHBGlobals.showMenu = false;
			this.setMenuSize(0);
		} else {
			this.initSplitMenu();
		}
	}

	initSplitMenu() {
		let storedMenuSize = Number(localStorage.getItem('menu-size'));
		storedMenuSize = storedMenuSize || storedMenuSize === 0 ? storedMenuSize : this.splitArea.menuView.initialSize;
		this.splitArea.menuView.initialSize = storedMenuSize;
		this.splitArea.initialized = true;
		if (!this.router.url.includes('/search') && !this.router.url.includes('/search-result')) {
			this.setMenuSize(storedMenuSize);
		}
	}

	async toggleSplitMenu(e) {
		if (window.innerWidth < 768) {
			if (e.sizes[1] === 100) {
				(document.querySelector('.searchbar--in-menu #searchtermInput input') as any).blur();
				const suggestions = document.querySelector('.search-suggestions--in-menu > .suggestions');
				if (suggestions) {
					suggestions.classList.add('hide');
				}
			}
			this.setMenuSize(e.sizes[1] === 100 ? 0 : 100);
		}
		else {
			const storedMenuSize = Number(localStorage.getItem('menu-size'));
			if (e.sizes[1] > 2) {
				this.setMenuSize(0);
			} else {
				this.setMenuSize(storedMenuSize || this.splitArea.menuView.initialSize);
			}
		}
	}

	async menuSizeChanged(e) {
		this.setMenuSize(e.sizes[1]);
		localStorage.setItem('menu-size', e.sizes[1]);
	}


	setMenuSize(size: number) {
		if (this.splitEl) {
			setSplitMenu(this.splitEl);
			this.splitEl.setVisibleAreaSizes([100 - size, size]);
		}
	}
}
