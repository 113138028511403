import { Component, effect, ElementRef, input, InputSignal, ViewChild, ViewContainerRef } from "@angular/core";
import { DataProvider }                                                                   from "../../services/data/data";
import { DomService } from "../../../_utils/DomService";
import { SearchSuggestionsComponent }        from "../search-suggestions/search-suggestions";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { Iso639_1 }                          from "../../../_model/Language";
import { PHBGlobals } from "../../../_config/config";
import { closeSplitMenu }                          from "../../services/gui";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MatInputModule }                          from "@angular/material/input";
import { TranslatePipe }                                             from "../../pipes/translate";


@Component({
	selector: "searchbar",
	templateUrl: "searchbar.html",
	styleUrls: ["searchbar.scss"],
	standalone: true,
	imports: [
    MatInputModule,
    ReactiveFormsModule,
    RouterLink,
		TranslatePipe
]
})
export class SearchbarComponent {
	inMenu: InputSignal<boolean> = input<boolean>();

	inputCTRL = new UntypedFormControl();

	query: string = "";
	showLink: boolean = true;

	@ViewChild("searchtermInput") input: ElementRef;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(private dataProvider: DataProvider,
	            private domService: DomService,
	            private router: Router,
		private vcr: ViewContainerRef) {

		this.router.events.subscribe(async (it) => {
			if (it instanceof NavigationEnd) {
				this.showLink = !it.url.includes('/search');
			}
		});

		effect(() => {
			const term = this.dataProvider.searchData();
			if (term) {
				this.inputCTRL.setValue(term.query);
			} else {
				this.inputCTRL.setValue("");
			}
		});
	}

	onChange() {
		//this.dataProvider.updateSearchData(this.query);
	}

	async onKeyup(e) {
		//this.dataProvider.updateSearchData(this.query);
		if (e.key == 'Enter') {
			this.openSearch(e);
		}
	}

	onFocus() {
		this.showSuggestions();
		if (window.innerWidth < 768) {
			document.querySelector('.as-split-area + .as-split-area').classList.add('as-split-area--overflow-hide');
		}
	}

	onBlur() {
		setTimeout(_ => {
			const suggestions = document.querySelector("search-suggestions .suggestions");
			if (suggestions) {
				suggestions.classList.add("hide");
			}
			if (window.innerWidth < 768) {
				document.querySelector('.as-split-area + .as-split-area').classList.remove('as-split-area--overflow-hide');
			}
		}, 300);
	}

	showSuggestions() {
		return;
		const me = document.querySelector("searchbar form").getBoundingClientRect();
		let suggestions = document.querySelector("search-suggestions") as any;
		if (!suggestions) {
			this.domService.appendComponentToBody(SearchSuggestionsComponent, this.vcr);
			suggestions = document.querySelector("search-suggestions") as any;
		}
		suggestions.style.left = me.left + "px";
		suggestions.style.top = (me.top) + "px";
		suggestions.style.width = (me.width) + "px";

		if (this.inMenu()) {
			suggestions.classList.add("search-suggestions--in-menu");
		}

		const list = document.querySelector("search-suggestions .suggestions");
		if (list) {
			list.classList.remove("hide");
			(list as any).style.marginTop = me.height + "px";
		}

	}


	async openSearch(e: Event) {
		e.preventDefault();
		await this.dataProvider.updateSearchData(this.inputCTRL.value);
		(e.target as any).blur();
		const suggestions = document.querySelector("search-suggestions .suggestions");
		if (suggestions) {
			suggestions.classList.add("hide");
		}
		if (window.innerWidth < 768) {
			closeSplitMenu();
		}

		await this.router.navigate(["/search", {q: this.inputCTRL.value}]);
	}
}
