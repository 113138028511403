import { Component, effect } from "@angular/core";
import { DataProvider } from "../../services/data/data";
import { DomService } from "../../../_utils/DomService";
import { Router } from "@angular/router";
import { inArray } from "../../../_utils/general-utils";
import { IsoCode } from "../../../_model/Language";
import { PHBGlobals } from "../../../_config/config";
import { closeSplitMenu } from "../../services/gui";
import { CommonModule } from "@angular/common";

/**
 * Generated class for the SearchSuggestionsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
	selector: "search-suggestions",
	templateUrl: "search-suggestions.html",
	styleUrls: ["search-suggestions.scss"],
	standalone: true,
	imports: [
		CommonModule

	]
})
export class SearchSuggestionsComponent {

	searchterm: string = "";

	suggestions: string[] = [];
	showSuggestions: boolean = false;

	suggested: boolean = false;

	hits: number = 0;

	//showSuggestions: boolean = false;
	lang: IsoCode;

	constructor(private data: DataProvider,
	            private domService: DomService,
	            private router: Router) {


		effect(() => {
			const searchData = this.data.searchData();
			if (searchData) {
				this.searchterm = searchData.query;
				if (!this.suggested) {
					this.suggest();
				} else {
					this.suggested = false;
				}
			} else {
				this.suggestions = [];
				this.showSuggestions = false;
			}
		});

		this.init();
	}

	async init() {
		this.lang = PHBGlobals.currentLanguage; //TODO wofür?
	}

	async search() {
		const result = await this.data.search(this.searchterm.trim(), this.data.currentSection(), 5, true);
		this.hits = result.hits.total;
		return result;
	}

	private async suggest() {
		return;
		const result = await this.search();
		console.log(result);
		this.suggestions = [];
		if (result) {
			this.showSuggestions = true;
			for (let hit of result.hits.hits) {
				if (hit._source["caption_" + this.lang]) {
					if (!inArray(this.suggestions, hit._source["caption_" + this.lang])) {
						this.suggestions.push(hit._source["caption_" + this.lang]);
					}
				} else {
					if (!inArray(this.suggestions, hit._source["caption_" + this.lang])) {
						this.suggestions.push(hit._source["caption"]);
					}
				}
			}
		}
	}

	useSuggestion(value) {
		const input = document.getElementById("searchtermInput") as any;
		input.value = value;
		this.suggested = true;
		this.data.updateSearchData(value);
		this.showSuggestions = false;
		this.openSearch(value);
	}

	openSearch(term = null) {
		if (!!term) {
			term = this.searchterm;
		}
		this.domService.removeChild(SearchSuggestionsComponent);
		this.router.navigate(["/search", {q: term}]);
		if (window.innerWidth < 768) {
			closeSplitMenu();
		}

	}

}
