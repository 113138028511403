// TODO: Create GUI controller for better control of alerts, ...
// Clean up: MenuListComponent.openOrSave_Electron
// Change this as soon as it is clear how to deal with ESC


import { Injectable, Signal, signal, WritableSignal } from "@angular/core";

export let openAlert;

export function setOpenAlert(alert) {
	if (openAlert) {
		try {
			openAlert.dismiss();
		} catch (e) {
		}
		clearOpenAlert();
	}
	openAlert = alert;
}

export function clearOpenAlert() {
	openAlert = null;
}


export let splitMenu;

export function setSplitMenu(_splitMenu) {
	splitMenu = _splitMenu;
}

export function reopenSplitMenu(menuSize: number = 100) {
	if(splitMenu) {
		setSplitMenu(splitMenu);
		splitMenu.setVisibleAreaSizes([100 - menuSize, menuSize]);
	}
}

export function closeSplitMenu() {
	if(splitMenu) {
		splitMenu.setVisibleAreaSizes([100 - 0, 0]);
	}
}


@Injectable({providedIn: "root"})
export class GuiService {

	private _scrollPosRememberSignal: WritableSignal<{
		[key: string]: number
	}> = signal({});

	readonly scrollPosRememberSignal: Signal<{
		[key: string]: number
	}> = this._scrollPosRememberSignal.asReadonly();


	constructor() {
		this.addScrollPosRemember("x", 500);
	}

	addScrollPosRemember(p, val) {
		let positions = this.scrollPosRememberSignal();
		positions[p] = val;
		this._scrollPosRememberSignal.set(positions);
	}
}
