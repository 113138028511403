import { Component, Signal } from "@angular/core";
import { User }                      from "../../../_model/user";
import { UserProvider }         from "../../services/user/user";
import { Router }            from "@angular/router";
import { Iso639_1 } from "../../../_model/Language";
import { PHBGlobals }        from "../../../_config/config";
import { closeSplitMenu }       from "../../services/gui";
import { TranslatePipe }        from "../../pipes/translate";


/**
 * Login/logout button.
 */
@Component({
	selector: "user",
	templateUrl: "user.html",
	styleUrls: ["user.scss"],
	standalone: true,
	imports: [
		TranslatePipe
	]
})
export class UserComponent {

	currentUser: Signal<User>;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(public userProvider: UserProvider,
		private router: Router) {
		this.currentUser = this.userProvider.currentUserSignal;
	}

	async showLogin() {
		await this.router.navigate(["/login"]);
		if (window.innerWidth < 768) {
			closeSplitMenu();
		}
	}

}
