<div class="container-fluid">
	<div class="row">
		<div class="col-4 col-sm-2 col-xl-4">
			<img src="assets/melitta-professional_logo.png" alt="Melitta Planerhandbuch" (click)="goHome()"
				class="pointer"/>
			<div class="title d-none d-xl-block">{{'Planerhandbuch' | translate:systemLanguage}}
				@if(preview()){- Preview ({{version}})}
			</div>
		</div>

		@if(everythingFine()) {
			<div class="col-4 col-xl-3 d-none d-sm-block">
				@if(canSeeSearch) {
					<searchbar></searchbar>
				}
			</div>
			<div class="col-3 col-sm-6 col-xl-5 offset-5 offset-sm-0 menu-align-right d-flex">
				@if(isElectron()) {
					<updater></updater>
					<config></config>
				} @else {
					<user></user>
					<zip></zip>
				}

				<div class="w-50 section-list-outer">
					<language-header></language-header>
				</div>
			</div>
		}
	</div>
</div>
