<div class="app-page  justify-content-start">
	<div class="modal-header">
		<input class="modal-prevent-focus" disabled>
		@if(currentUser) {
			@if (currentUser.loggedIn) {
				<h1 class="modal-title">{{ 'user' | translate:systemLanguage }}</h1>
			} @else {
				<h1 class="modal-title">{{ 'login' | translate:systemLanguage }}</h1>
			}
		}
		<div class="modal-close" (click)="close()">
			<span class="icon--cross2"></span>
		</div>
	</div>

	<div class="page-content p-a">
		<div class="content-inner">
			<div class="row">
				<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
					<div class="text-content">
						@if (currentUser) {
							<div class="login-wrapper">
								@if(!currentUser.loggedIn) {
									<div>
										<login [loginclass]="'popover'" (onLogin)="onLogin()"></login>
									</div>
								} @else {
									<div class="logout-wrapper">

										<h2>{{ currentUser.data.firstname }} {{ currentUser.data.lastname }}</h2>

										@if(!currentUser.uniquekey || currentUser.id===1) {
											<a (click)="showProfile()">{{ 'edit_profile' | translate:systemLanguage }}</a>
										}

										<a (click)="showSettings()">{{ 'settings' | translate:systemLanguage }}</a>

										<button mat-flat-button color="primary" (click)="logout()">{{ 'logout' | translate:systemLanguage }}</button>
									</div>
								}
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
