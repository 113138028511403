export const environment = {
	production: true,

	kotlin: "https://api.planerhandbuch.de/api/4.4",
	symfony: "https://api.planerhandbuch.de",
	localBasePath:"file:///C:/MPCS/software/PHB4",
	sso:"https://sso.planerhandbuch.de",
	client:"https://planerhandbuch.de",
	languageFallbackDisabled: false,
	versionCheck:{
		url:"https://www.planerhandbuch.de/version.json",
		interval: 1000 * 60 * 30
	}
};
