import { NgModule }      from "@angular/core";
import { SettingsModal } from "./settings";
import { CommonModule }  from "@angular/common";
import { FormsModule }   from "@angular/forms";
import { RouterModule }  from "@angular/router";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { TranslatePipe } from "../../pipes/translate";

@NgModule({
	declarations: [
		SettingsModal
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild([
			{
				path: "",
				component: SettingsModal
			}
		]),
		MatSlideToggleModule,
		MatSelectModule,
		MatButtonModule,
		TranslatePipe
	]
})
export class SettingsModalModule {
}
