<div class="app-page justify-content-start">
	<div class="modal-header">

		<h1 class="modal-title">{{ 'edit_settings_header' | translate:systemLanguage }}</h1>
		<div class="modal-close" (click)="close()">
			<span class="icon--cross2"></span>
		</div>
	</div>

	<div class="page-content p-a">

		<div class="content-inner">
			<div class="row">
				<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
					<div class="text-content">

						<div class="form-wrapper">
							<h2>{{ 'edit_settings' | translate:systemLanguage }}</h2>
							<form (ngSubmit)="saveChanges()">
								<div class="form-item">
									<div class="form-item-inner">
										<label [title]="'settings_showMenu' | translate:systemLanguage">{{ 'settings_showMenu' | translate:systemLanguage }}</label>
										<mat-slide-toggle color="primary" name="showSettings" [(ngModel)]="settings.showMenu"></mat-slide-toggle>
									</div>
								</div>

								<div class="form-item">
									<div class="form-item-inner">
										<label [title]="'settings_view' | translate:systemLanguage">{{ 'settings_view' | translate:systemLanguage }}</label>
										<mat-select [(ngModel)]="settings.view" name="view">
											<mat-option value="cards">{{ 'view_cards' | translate:systemLanguage }}</mat-option>
											<mat-option value="list">{{ 'view_list' | translate:systemLanguage }}</mat-option>
										</mat-select>
									</div>
								</div>

								@if(isWindowsClient) {
									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_zoom' | translate:systemLanguage">{{ 'settings_zoom' | translate:systemLanguage }}</label>
											<mat-select [(ngModel)]="currentZoom" name="zoom" (selectionChange)="changedZoom()">
												<mat-option [value]="0.75">75%</mat-option>
												<mat-option [value]="1">100%</mat-option>
												<mat-option [value]="1.25">125%</mat-option>
												<mat-option [value]="1.5">150%</mat-option>
												<mat-option [value]="1.75">175%</mat-option>
												<mat-option [value]="2">200%</mat-option>
												<mat-option [value]="2.5">250%</mat-option>
											</mat-select>
										</div>
									</div>
								}


								<div class="form-item">
									<div class="form-item-inner">
										<label [title]="'changelog_always_visible' | translate:systemLanguage">{{ 'changelog_always_visible' | translate:systemLanguage }}</label>
										<mat-slide-toggle color="primary" name="changelogToggled" [(ngModel)]="settings.changelogAlwaysVisible"></mat-slide-toggle>
									</div>
								</div>

								<div class="form-item">
									<div class="form-item-inner">
										<label [title]="'settings_menuToogleClick' | translate:systemLanguage">{{ 'settings_menuToogleClick' | translate:systemLanguage }}</label>
										<mat-slide-toggle color="primary" name="menuToggleClick" [(ngModel)]="settings.menuToggleClick"></mat-slide-toggle>
									</div>
								</div>

								@if(languages) {
									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'Menu.Section.SelectLanguage' | translate:systemLanguage">{{ 'Menu.Section.SelectLanguage' | translate:systemLanguage }}</label>
											<mat-select [(ngModel)]="settings.language" name="language" (selectionChange)="showWarning()">
												@for(language of languages; track language.isocode) {
													<mat-option [value]="language.isocode">{{ language.title }}</mat-option>
												}
											</mat-select>
										</div>
									</div>
								}


								<div class="form-item">
									<div class="form-item-inner">
										<label [title]="'settings_collect' | translate:systemLanguage">{{ 'settings_collect' | translate:systemLanguage }}</label>
										<mat-slide-toggle color="primary" name="collect" [(ngModel)]="settings.collect"></mat-slide-toggle>
									</div>
								</div>

								@if(isWindowsClient) {
									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_silentUpdater' | translate:systemLanguage">{{ 'settings_silentUpdater' | translate:systemLanguage }}</label>
											<mat-slide-toggle color="primary" name="silentUpdater" [(ngModel)]="settings.silentUpdater"></mat-slide-toggle>
										</div>
									</div>
									@if(settings.silentUpdater) {
										<div class="p-a">{{ 'settings_silentUpdaterWarning' | translate:systemLanguage }}</div>
									}


									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_printPdf' | translate:systemLanguage">{{ 'settings_printPdf' | translate:systemLanguage }}</label>
											<mat-slide-toggle color="primary" name="printPdf" [(ngModel)]="settings.printPdf"></mat-slide-toggle>
										</div>
									</div>

									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_showFolder' | translate:systemLanguage">{{ 'settings_showFolder' | translate:systemLanguage }}</label>
											<mat-slide-toggle color="primary" name="showFolder" [(ngModel)]="settings.showFolder"></mat-slide-toggle>
										</div>
									</div>

									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_external' | translate:systemLanguage">{{ 'settings_external' | translate:systemLanguage }}</label>
											<mat-slide-toggle color="primary" name="external" [(ngModel)]="settings.external"></mat-slide-toggle>
										</div>
									</div>

									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_saveFile' | translate:systemLanguage">{{ 'settings_saveFile' | translate:systemLanguage }}</label>
											<mat-slide-toggle color="primary" name="saveFile" [(ngModel)]="settings.saveFile"></mat-slide-toggle>
										</div>
									</div>
								}


								@if(online) {
									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_newsletter' | translate:systemLanguage">{{ 'settings_newsletter' | translate:systemLanguage }}</label>
											<mat-slide-toggle color="primary" name="newsletter" [(ngModel)]="newsletterRecipient"></mat-slide-toggle>
										</div>
									</div>
								}

								@if(canSeeTreeTranslationSettings()) {
									<div class="form-item">
										<div class="form-item-inner">
											<label [title]="'settings_show_translations' | translate:systemLanguage">{{ 'settings_show_translations' | translate:systemLanguage }}</label>
											<mat-slide-toggle color="primary" name="showTranslations" [(ngModel)]="settings.showTranslations"></mat-slide-toggle>
										</div>
									</div>
								}

								<div>
									@if(warning) {
										<p class="danger">{{ warning }}</p>
									}
								</div>

								@if (!update) {
									<button color="primary" mat-flat-button type="submit">{{ 'save' | translate:systemLanguage }}</button>
								} @else {
									<button color="light" mat-flat-button (click)="startUpdate()">{{ 'update' | translate:systemLanguage }}</button>
								}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
