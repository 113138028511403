import { Component, input, InputSignal, OnInit, output } from "@angular/core";
import { UserProvider }                                                        from "src/app/services/user/user";
import { Credentials, User }                              from "../../../_model/user";
import { Router }                                         from "@angular/router";
import { Iso639_1 }                                       from "../../../_model/Language";
import { PHBGlobals }                                     from "../../../_config/config";
import { environment }                                    from "../../../environments/environment";
import { MatButtonModule }                                from "@angular/material/button";
import { FormsModule }                                    from "@angular/forms";
import { TranslatePipe }                                  from "../../pipes/translate";


@Component({
	selector: "login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	standalone: true,
	imports: [
	    MatButtonModule,
	    FormsModule,
		TranslatePipe
	]
})
export class LoginComponent implements OnInit {
	onLogin = output<boolean>();

	loginclass: InputSignal<string> = input<string>();
	isAppstart: InputSignal<boolean> = input<boolean>(false);

	currentUser: User;
	credentials: Credentials = {username: "", password: ""};
	msg = null;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(public userProvider: UserProvider,
		private router: Router) {
	}

	async ngOnInit() {
		this.currentUser = this.userProvider.currentUserSignal();
	}

	async login() {
		try {
			this.currentUser = await this.userProvider.login(this.credentials);
			this.msg = null;
			this.onLogin.emit(true);
		} catch {
			this.msg = "User name or password incorrect.";//translate("error_occurred") + "!";
		}
	}

	async resetPassword() {
		this.router.navigate(["/reset-password"]);
	}

	ssoLogin() {
		const redirect = localStorage.getItem('phb-redirect-after-login');
		location.href = `${environment.sso}?redirect=${redirect || ''}`;
		return;
	}
}
