<div class="alert__wrapper">
	<div class="alert__header">
		Etwas ist schief gegangen!
	</div>
	<div class="alert__message">
		Das PHB muss zwingend aktualisiert werden! Bitte stellen Sie sicher, dass eine Verbindung zum Internet besteht!
	</div>
	<div class="alert__buttons">
		<button mat-flat-button (click)="acceptAndClose()">Planerhandbuch neu starten</button>
		<button mat-flat-button (click)="close()">Abbrechen</button>
	</div>
</div>
