import { NgModule }         from "@angular/core";
import { ProfileModal }     from "./profile";
import { CommonModule }     from "@angular/common";
import { FormsModule }      from "@angular/forms";
import { RouterModule }     from "@angular/router";
import { MatButtonModule }  from "@angular/material/button";
import { CountrySelectComponent } from "../../components/country-select/country-select.component";
import { TranslatePipe } from "../../pipes/translate";


@NgModule({
	declarations: [
		ProfileModal
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild([
			{
				path: "",
				component: ProfileModal
			}
		]),
		MatButtonModule,
		CountrySelectComponent,
		TranslatePipe
	]
})
export class ProfileModalModule {
}
