import { Component, effect, OnInit } from "@angular/core";

import { PHBGlobals } from "../_config/config";
import { isUserUnknown } from "../_model/user.fun";

import { DataProvider } from "./services/data/data";
import { clearOpenAlert, closeSplitMenu, openAlert } from "./services/gui";
import { UserProvider } from "./services/user/user";
import { callElectron, isElectron } from "../_utils/electron-utils";
import { AppstatusProvider } from "./services/appstatus";
import { global_vocab_settings, translate } from "../_model/vocab";
import { NavigationEnd, Router } from "@angular/router";
import packageInfo from "../../package.json";
import { Node } from "../_model";
import { Update, UpdateProvider } from "./services/update/update";
import { SoftwareService } from "./services/software.service";
import { environment } from "../environments/environment";
import { VersionCheckService } from "./services/version-check.service";
import { Title } from "@angular/platform-browser";
import { Iso639_1 } from "../_model/Language";
import { OnBoardingModalComponent } from "./modals/on-boarding-modal/on-boarding-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { UpdateDownloadedComponent } from "./dialogs/update-downloaded/update-downloaded.component";
import { PhbUpdateFailureComponent } from "./dialogs/phb-update-failure/phb-update-failure.component";
import { ElectronService } from "ngx-electronyzer";


@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

	year = new Date().getFullYear();

	loaded = false;

	preview = PHBGlobals.isPreviewMode;

	get everythingFine(): boolean {
		return PHBGlobals.dataSuccessfulLoaded && !PHBGlobals.softwareUpdateAvailable;
	}

	online: boolean = false;

	cookieConsentVisible = false;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	currentNode: Node;

	silentUpdater: boolean = false;

	constructor(public data: DataProvider,
	            public userProvider: UserProvider,
	            private appProvider: AppstatusProvider,
	            private router: Router,
	            private update: UpdateProvider,
	            private softwareService: SoftwareService,
	            private versionCheckService: VersionCheckService,
	            private titleService: Title,
	            private dialog: MatDialog,
	            private electronService: ElectronService
	) {

		// remove after 01.03.24
		const today = new Date();
		const firstMarch24 = new Date("3-1-24 00:00:00");
		const infoDismissed = localStorage.getItem("phb-onboarding-search");

		if (today < firstMarch24 && !infoDismissed) {
			this.dialog.open(OnBoardingModalComponent, {
				panelClass: "cdk-overlay__on-boarding",
				backdropClass: "cdk-overlay__on-boarding-backdrop",
				disableClose: true
			});
		}

		if (isElectron()) {
			this.electronService.ipcRenderer.on("open-node", async (e, node) => {
				console.log("open-node", node);
				await this.data.updateNode(node);
			});


			this.check4Update();

			if (this.appProvider.startUpdater) {
				this.router.navigate([`/updater`]);
			}
		} else {
			(function (w, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({
					'gtm.start': new Date().getTime(),
					event: 'gtm.js'
				});
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s),
					dl = l != 'dataLayer' ? '&l=' + l : '';
				(j as any).async = true;
				(j as any).src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
				f.parentNode.insertBefore(j, f);
			})(window, document, 'script', 'dataLayer', 'GTM-WZQVHB9 ');
		}


		effect(() => {
			const user = this.userProvider.currentUserSignal();
			this.setTitle();
			if (user?.data?.settings) {
				this.silentUpdater = user.data.settings.silentUpdater;
				let storedLsView = localStorage.getItem("_phb_left_view");
				if (user.data.settings.view && !storedLsView) {
					localStorage.setItem("_phb_left_view", user.data.settings.view || 'cards');
				}
			}
		});


		if (PHBGlobals.phbClient !== "www") {

			effect(() => {
				const step: number = this.update.updateStepSignal();
				const curUpdate = this.update.updateAvailable();
				if (step === 0 && (this.silentUpdater || (curUpdate && !curUpdate.forced && curUpdate.downloads.length <= 0))) {
					this.update.pushMessage("Ein Update wird im Hintergrund heruntergeladen.");
					console.log("Ein Update wird im Hintergrund heruntergeladen.");
					this.update.update().then(() => {
						console.log("Silent Update complete");
						//TODO show message?
					});
				}
			}, {
				allowSignalWrites: true
			});

			effect(async () => {
				const finished: Update = this.update.updateFinished();
				if (finished) {
					if (finished.forced || finished.downloads.length > 0) {
						console.log("Update finished! Show Confirm and Reload");
						//TODO
						this.openAlert();
					}
					if (!finished.forced && finished.downloads.length <= 0) {
						console.log("Update finished! Reload index");
						await this.data.service.init();
					}
				}
			});


			effect(() => {
					const error = this.update.updateFailure();
					if (error !== null) {
						const lastStep = this.update.updateStepSignal();
						console.error(error);
						let message = "";
						let skippable = false;
						switch (error.message) {
							case "symlinks":
							case "deleteOldFiles":
								message = translate("updater_error_files_busy");
								skippable = true;
								break;
							default:
								message = translate("updater_error_retry");
								break;
						}
						if (this.silentUpdater) {
							setTimeout(() => {
								console.log("silent retry");
								this.update.updateStepSignal.set(lastStep);
							}, 1500);
						} else {
							this.dialog.open(PhbUpdateFailureComponent, {
								panelClass: 'cdk-overlay__alert',
								backdropClass: 'cdk-overlay__alert-backdrop',
								disableClose: true,
								data: {
									msg: message,
									skipAllowed: skippable
								}
							}).afterClosed().subscribe(async (result) => {
								if (result === 'skip') {
									this.update.pushMessage("Skipping last step...");
									this.update.updateStepSignal.set(lastStep + 1);
								} else if (result == 'retry') {
									this.update.updateStepSignal.set(lastStep);
								}
							});
						}
					}
				},
				{
					allowSignalWrites: true
				});
		}

		this.router.events.subscribe(async (event) => {
			if (event instanceof NavigationEnd) {
				this.setTitle();
				if (event.url.includes("/search") || event.url.includes("/search-result")) {
					closeSplitMenu();
				}
			}
		});

		/**
		 * set current and default language for i18n
		 */
		global_vocab_settings.fallbackLanguageId = "en";

		// diese funktion deaktiviert den ESC key
		// Change this as soon as it is clear how to deal with ESC
		document.body.addEventListener("keyup", (event) => {
			if (event.keyCode === 27) { // ESCAPE key
				if (openAlert != null) {
					event.preventDefault();
					event.stopImmediatePropagation();

					// But, close open alerts - TIDY: use GUI provider
					openAlert.dismiss();
					clearOpenAlert();
				}
			}
		});

		const runUpdate = async () => {
			if (PHBGlobals.phbClient !== "www" && this.checkDataVersion && this.online) {
				if (this.everythingFine && !PHBGlobals.softwareUpdateAvailable) {
					try {
						if (await this.update.hasUpdate()) {
							console.log("has Update");
						}
					} catch (e) {
					}
				}
			}
			setTimeout(runUpdate, 1000 * 60);
		};
		if (isElectron()) {
			runUpdate().then();
		}
	}

	async ngOnInit() {

		this.setTitle();

		this.initCookieConsent();

		/**
		 * überprüfe die Verbindung zur API wenn:
		 * das Gerät mit corodva arbeitet und der Benutzer nicht eingelogt ist
		 * oder mit cordova arbeitet und der benutzer eingeloggt ist und nicht offline arbeiten möchte
		 * oder es sich um electron handelt
		 */
		if (PHBGlobals.phbClient === "www") {
			this.appProvider.onlineSignal.set(true);
			this.online = true;
		}

		// Periodically refresh the access token
		this._interval();

		//www Version check
		if (!isElectron()) {
			if (environment.production) {
				this.versionCheckService.initVersionCheck(environment.versionCheck.url, environment.versionCheck.interval);
			}
		}
	}

	async setTitle() {
		if (!isElectron()) {
			this.titleService.setTitle(translate("Planerhandbuch"));
			return;
		}
		let indexVersion = PHBGlobals.phbVersion;
		if (PHBGlobals.phbVersion === null) {
			indexVersion = await this.data.getIndexVersion();
		}
		const user = this.userProvider.currentUserSignal();


		// Show version number in title
		const stringVersion = packageInfo.version;
		+"/" + indexVersion;
		// Show current user in the title

		const t_user = translate("user"),
			t_anonymous = translate("anonymous");

		const stringUser = isUserUnknown(user) ? "" :
			(user.username ? " - " + t_user + ": " + user.username : " - " + t_anonymous);
		//document.title = translate("Planerhandbuch") + " " + stringVersion + stringUser;
		//console.log("setTitle", user, stringUser, this.router.url);
		this.titleService.setTitle(translate("Planerhandbuch") + " " + stringVersion + stringUser);
	}


	/**
	 * Refreshes the login token each 10 seconds.
	 */
	async _interval() {
		const refreshSeconds = 60;

		if (PHBGlobals.phbClient !== "www" && !PHBGlobals.softwareUpdateSkipped) {
			console.log("checking connection...");
			this.online = await this.appProvider.isOnline(true);
			if (this.appProvider.onlineSignal() !== this.online) {
				this.appProvider.onlineSignal.set(this.online);
			}
		}
		this.online = this.appProvider.onlineSignal();

		try {
			if (this.appProvider.onlineSignal()) {
				if (this.userProvider.token) {
					await this.userProvider.refreshExpiringToken();
				}
			} else {
				console.info("Cannot refresh token: No Wifi-Connection");
			}
		} catch (e) {
			console.error("Refreshing token failed", e);
		}

		setTimeout(async () => {
			await this._interval();
		}, refreshSeconds * 1000);

	}

	openExternalLink(link: string) {
		if (isElectron()) {
			const {shell} = window["require"]("electron");
			shell.openExternal(translate(link));
		} else {
			window.open(translate(link), "_blank");
		}
	}

	private async initCookieConsent() {
		document.cookie = "cookielaw_dummy=dummy-cookie";
		if (false && !isElectron()) {
			const script01 = document.createElement("script");
			script01.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
			script01.setAttribute("data-document-language", "false");
			script01.setAttribute("data-language", PHBGlobals.currentLanguage);
			script01.setAttribute("type", "text/javascript");
			script01.setAttribute("charset", "UTF-8");
			script01.setAttribute("data-domain-script", "80ced074-0106-4bf6-a536-3e46aee8bc9b");

			const script02 = document.createElement("script");
			script02.setAttribute("type", "text/javascript");
			script02.innerHTML = "function OptanonWrapper() { }";
			document.getElementsByTagName("head")[0].appendChild(script01).appendChild(script02);
		} else {
			this.cookieConsentVisible = !localStorage.getItem("cookie-consent");
		}

	}

	setCookieConsent() {
		localStorage.setItem("cookie-consent", "1");
		this.cookieConsentVisible = false;
	}

	async deleteCookie() {
		localStorage.removeItem("cookie-consent");
		this.cookieConsentVisible = true;
	}

	private checkDataVersion: boolean = true;

	async openAlert() {
		this.dialog.open(UpdateDownloadedComponent, {
			panelClass: 'cdk-overlay__alert',
			backdropClass: 'cdk-overlay__alert-backdrop',
			disableClose: true
		}).afterClosed().subscribe(async (result) => {
			if (result === 'restart') {
				callElectron('restart');
			} else {
				this.checkDataVersion = false;
			}
		});
	}

	private checkVersion: boolean = true;

	async check4Update() {
		if (this.checkVersion) {
			if (this.online && !PHBGlobals.softwareUpdateAvailable) {
				if (await this.softwareService.checkVersion()) {
					await this.openAlert();
				}
			}
			setTimeout(() => {
				this.check4Update();
			}, 60 * 15 * 1000);
		}

	}

	showUpdater(): boolean {
		return this.online && isElectron();
	}

	protected readonly translate = translate;
	readonly isElectron = isElectron;
}
