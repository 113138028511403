<div class="language-header-backdrop" [class.language-header-backdrop--clickable]="popupOpen && languages.length > 1" (click)="closePopup($event)"></div>
<div class="language-header__outer">
	@if(!disabled) {
		<div class="language-header" (click)="togglePopOver()">
			{{language.title}}
			@if(languages.length > 1) {
				@if(popupOpen) {
					<span class="phb-icon icon icon--arrow--up"></span>
				} @else {
					<span class="phb-icon icon icon--arrow--down"></span>
				}
			}
		</div>
	}


	<div class="language-header__outer-popover" [class.language-header__outer-popover--visible]="popupOpen && languages.length > 1">
		<div class="language-header__outer-popover-inner">
			@for(_language of languages; track _language.isocode) {
				<div class="pointer" (click)="changeLanguage(_language)">
					@if(PHBGlobals.currentLanguage !== _language.isocode) {
						<div class="bez" [innerHTML]="_language.title"></div>
					}
				</div>
			}
		</div>
	</div>
</div>

