export function arraysEqual(a, b) {
	if (a === b) {
		return true;
	}
	if (a == null || b == null) {
		return false;
	}
	if (a.length != b.length) {
		return false;
	}

	// If you don't care about the order of the elements inside
	// the array, you should sort both arrays here.
	// Please note that calling sort on an array will modify that array.
	// you might want to clone your array first.

	for (var i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) {
			return false;
		}
	}
	return true;
}

export function last<T>(a: T[]): T {
	if (!a || a.length < 1) {
		return null;
	}
	return a[a.length - 1];
}

export function secondLast<T>(a: T[]): T {
	if (!a || a.length < 2) {
		return null;
	}
	return a[a.length - 2];
}

export function remove<T>(a: T[], item: T) {
	const index = a.indexOf(item);
	if (index != -1) {
		a.splice(index, 1);
	}
}

export function fatalError(message: string) {
	console.error("FATAL ERROR: " + message);
	throw new Error(message);
}

export function inArray<T>(a: T[], item: T) {
	return a.indexOf(item) !== -1;
}

export function generateTextColor(color = "#000000") {
	const _r = color.substr(1, 2);
	const _g = color.substr(3, 2);
	const _b = color.substr(5, 2);

	const r = parseInt(_r, 16);
	const g = parseInt(_g, 16);
	const b = parseInt(_b, 16);

	const c = ((r + g + b) / 3);
	if (c < 128) {
		color = "#ffffff";
	} else {
		color = "#000000";
	}
	return color;
}

/**
 * compare if the current Version (v1) is newer or equal to the target version (v2)
 * @param v1
 * @param v2
 */
export function newerOrEqualVersion(v1: string, v2: string) {
	if (v1 === null || v2 === null) {
		return false;
	}
	if (v1.indexOf(".") === -1 || v2.indexOf(".") === -1) {
		return false;
	}
	const splittedV1 = v1.split(".");
	const splittedV2 = v2.split(".");
	if (splittedV1.length != splittedV2.length) {
		return false;
	}

	if (v1 === v2) {
		return true;
	}

	let numbersV1 = [];
	let numbersV2 = [];
	for (let num of splittedV1) {
		numbersV1.push(parseInt(num));
	}
	for (let num of splittedV2) {
		numbersV2.push(parseInt(num));
	}

	if (numbersV1[0] < numbersV2[0]) {
		return false;
	}
	if (numbersV1[1] < numbersV2[1]) {
		return false;
	}
	if (numbersV2.length >= 3) {
		if (numbersV1[2] < numbersV2[2]) {
			return false;
		}
	}

	console.log(numbersV1, numbersV2);

	return true;
}

export function getTranslationOrDefault(caption, lang) {
	if (typeof caption === "object") {
		if (caption[lang]) {
			return caption[lang];
		}
		if (caption["def"]) {
			return caption["def"];
		}
	}

	return caption;
}


export function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}


/**
Converts the given version in the format "major.minor.revision"
to an integer with the format major * 1_000_000 + minor * 1_000 + revision,
e.g. "1.25.3" to 1_025_003. Returns 0 if the version is invalid.
 **/
export function versionToNumeric(version: string) {
	const parts = version.split(".").map(it => parseInt(it));
	if (parts.length != 3) {
		return 0;
	}
	return parts[0] * 1_000_000 + parts[1] * 1_000 + parts[2];
}


//copied from https://dzone.com/articles/how-to-check-password-strength-meter-in-angular-8
export function measurePasswordStrength(pass) {
	let score = 0;
	// award every unique letter until 5 repetitions
	let letters = {};
	for (let i = 0; i < pass.length; i++) {
		letters[pass[i]] = (letters[pass[i]] || 0) + 1;
		score += 2.5 / letters[pass[i]];
	}
	// bonus points for mixing it up
	let variations = {
		digits: /\d/.test(pass),
		lower: /[a-z]/.test(pass),
		upper: /[A-Z]/.test(pass),
		nonWords: /\W/.test(pass)
	};
	let variationCount = 0;
	for (let check in variations) {
		if (!variations[check]) {
			return 0;
		}
		variationCount += (variations[check]) ? 1 : 0;
	}
	score += (variationCount - 1) * 20;
	return Math.trunc(score);
}
