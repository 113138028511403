import { Component, effect, OnInit } from "@angular/core";
import {Directory}                          from "../../../_model";
import {PHBGlobals}                  from "../../../_config/config";
import {DataProvider}                from "../../services/data/data";
import {User}                        from "../../../_model/user";
import {UserProvider}                from "../../services/user/user";
import {DomSanitizer}                from "@angular/platform-browser";
import { Router } from "@angular/router";
import { CommonModule } from "@angular/common";
import { GetIconPipe } from "../../pipes/get-icon.pipe";
import { CaptionPipe } from "../../pipes/caption.pipe";

/**
 * Header which shows the current section and allows to select a different
 * section within a dropdown.
 */
@Component({
	selector: "section-list",
	templateUrl: "section-list.html",
	styleUrls: ["section-list.scss"],
	standalone: true,
	imports: [
		CommonModule,
		GetIconPipe,
		CaptionPipe
	]
})
export class SectionListComponent implements OnInit{

	get sections(): Directory[] {
		return PHBGlobals.index.getSections();
	}

	currentUser: User;
	currentSection: Directory;
	popupOpen: boolean = false;

	get systemLanguage() {
		return PHBGlobals.systemLanguage;
	}

	constructor(public data: DataProvider,
	            public sanitizer: DomSanitizer,
				private router: Router,
	            private userProvider: UserProvider) {

		effect(() => {
			const sectionId = this.data.currentSection();
			console.log(sectionId);
			this.currentSection = this.data.section;
		});
	}

	async ngOnInit() {
		this.currentUser = this.userProvider.currentUserSignal();
	}


	togglePopOver() {
		this.popupOpen = !this.popupOpen;
	}

	async changeSection(section: Directory) {
		this.data.updateSearchData("");
		await this.data.openSection(section.id);
		await this.router.navigate(["/node", section.id]);
		this.popupOpen = false;
	}

	closePopup(e) {
		e.stopPropagation();
		e.preventDefault();
		this.popupOpen = false;
	}
}
