import { NgModule }                                from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ZipDownloadModal }                        from "./pages/zip-download/zip-download";
import { AppLoadedGuard }                          from "./guards/app-loaded.guard";
import { UpdaterGuard }                            from "./pages/updater/updater.guard";


const routes: Routes = [
	{
		path: "",
		redirectTo: "home",
		pathMatch: "full"
	},
	{
		path: "home",
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
	},

	//pages
	{
		path: "node/:id",
		loadChildren: () => import('./pages/node/node.module').then(m => m.NodePageModule),
		canActivate: [AppLoadedGuard]
	},
	{
		path: "node/:id/page/:page",
		loadChildren: () => import('./pages/node/node.module').then(m => m.NodePageModule),
		canActivate: [AppLoadedGuard]
	},
	{
		path: "node/:isoCode/:id",
		loadChildren: () => import('./pages/node/node.module').then(m => m.NodePageModule),
		canActivate: [AppLoadedGuard]
	},

	{
		path: "search",
		loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule),
		canActivate: [AppLoadedGuard]
	},
	{
		path: "search-result/:id",
		loadChildren: () => import('./pages/search-result/search-result.module').then(m => m.SearchResultModule),
		canActivate: [AppLoadedGuard]
	},
	{
		path: "search-result/:isoCode/:id",
		loadChildren: () => import('./pages/search-result/search-result.module').then(m => m.SearchResultModule),
		canActivate: [AppLoadedGuard]
	},

	{
		path: "search;q=:q",
		loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule),
		canActivate: [AppLoadedGuard]
	},
	{
		path: "updater",
		loadChildren: () => import('./pages/updater/updater.module').then(m => m.UpdaterPageModule),
		canActivate: [UpdaterGuard],
		canLoad: [UpdaterGuard]
	},

	{
		path: "profile",
		loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModalModule)
	},
	{
		path: "settings",
		loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModalModule)
	},
	{
		path: "collect",
		component: ZipDownloadModal
	},

	{
		path: "login",
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPopoverModule)
	},

	{
		path: "reset-password",
		loadChildren: () => import('./pages/password/password.module').then(m => m.PasswordModalModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
