<div [ngClass]="'suggestions' + (suggestions.length > 0 ? ' suggestions--has-entries':'')">
	<ul>
		@for(sug of suggestions; track sug) {
			<li (click)="useSuggestion(sug)">{{sug}}</li>
		}


		<li class="all_results" (click)="openSearch()">
			<span class="phb-icon icon--mp-zoom"></span>
		</li>

	</ul>
</div>
