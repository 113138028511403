@if(currentUser) {
	<div class="app-page  justify-content-start">
		<div class="modal-header">
			<input class="modal-prevent-focus" disabled>
			<h1 class="modal-title">{{ 'edit_profile_intro' | translate:systemLanguage }}</h1>
			<div class="modal-close" (click)="closeModal()">
				<span class="icon--cross2"></span>
			</div>
		</div>

		<div class="page-content p-a">
			<div class="content-inner">
				<div class="row">
					<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
						<div class="text-content">

							<div class="form-wrapper">
								<h2>{{ 'edit_profile_intro' | translate:systemLanguage }}</h2>
								<form (ngSubmit)="saveChanges()">

									<div class="input-wrapper">
										<input type="password" name="_password" [(ngModel)]="_password"
											[class.password-weak]="_password && passwordScore<40"
											[class.password-medium]="passwordScore>=40 && passwordScore<70"
											[class.password-strong]="passwordScore>=70"
											autocomplete="new-password"
											[placeholder]="'password' | translate:systemLanguage" (keyup)="checkPW()" (change)="checkPW()">
									</div>
									@if(showInsecureMessage) {
										<div>
											{{ 'User.Password.Strength' | translate:systemLanguage }}
										</div>
									}

									<div class="input-wrapper">
										<input type="text" name="company" [(ngModel)]="currentUser.data.company"
											placeholder="{{'company' | translate:systemLanguage}}*">
									</div>

									<div class="input-wrapper">
										<input type="text" name="addition" [(ngModel)]="currentUser.data.company2"
											[placeholder]="'company2' | translate:systemLanguage">
									</div>

									<div class="input-wrapper">
										<input type="text" name="firstname" #firstname="ngModel" required [(ngModel)]="currentUser.data.firstname"
											placeholder="{{'firstname' | translate:systemLanguage}}*">
									</div>
									@if(firstname.invalid && (firstname.dirty || firstname.touched)) {
										<div>
											@if(firstname.errors.required) {
												<div>
													{{ 'required' | translate:systemLanguage }}
												</div>
											}
										</div>
									}


									<div class="input-wrapper">
										<input type="text" name="lastname" #lastname="ngModel" required [(ngModel)]="currentUser.data.lastname"
											placeholder="{{'lastname' | translate:systemLanguage}}*">
									</div>

									@if(lastname.invalid && (firstname.dirty || lastname.touched)) {
										<div class="warning-outer">
										@if(lastname.errors.required){
											<div class="warning-inner">
												{{ 'required' | translate:systemLanguage }}
											</div>
										}
										</div>
									}




									<div class="input-wrapper">
										<input type="text" name="city" [(ngModel)]="currentUser.data.city"
											placeholder="{{'city' | translate:systemLanguage}}*">
									</div>

									<div class="input-wrapper">
										<country-select [(ngModel)]="currentUser.data.country" name="country" ngDefaultControl></country-select>
									</div>


									<div class="input-wrapper">
										<input type="email" name="email" #email="ngModel" required [(ngModel)]="currentUser.email"
											placeholder="{{'email' | translate:systemLanguage}}*">
									</div>

									@if(email.invalid && (email.dirty || email.touched)) {
										<div class="warning-outer">
											@if(email.errors.required) {
												<div  class="warning-inner">
													{{ 'required' | translate:systemLanguage }}
												</div>
											}
										</div>
									}

									<p class="text-info" [innerHtml]="'profile_terms' | translate:systemLanguage"></p>

									<button color="primary" mat-flat-button type="submit">{{ 'save' | translate:systemLanguage }}</button>

								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}
<ng-template #ldapUser>
	<div [innerHTML]="'profile_ldap' | translate:systemLanguage"></div>
</ng-template>
