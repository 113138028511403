<!-- split-pane -->
<div class="page-split-pane">

	<div class="split-area-wrapper">
		<as-split direction="horizontal" (gutterClick)="toggleSplitMenu($event)" [gutterSize]="35" (dragEnd)="menuSizeChanged($event)">

			<as-split-area [size]="100" [minSize]="50">
				<div class="split-area-wrapper-content">
					<router-outlet></router-outlet>
				</div>
			</as-split-area>

			<as-split-area [size]="0">

				<div class="menu__search-outer d-sm-none">
					@if(online()) {
						<searchbar [inMenu]="true"></searchbar>
					}
				</div>

				<section-list></section-list>
				<menu-list></menu-list>

			</as-split-area>

		</as-split>
	</div>


</div>
<!--/split-pane-->
