import { HttpClient, HttpHeaders }            from "@angular/common/http";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { DataService }                        from "./dataService";
import { WinDataService } from "./winDataService";
import { WwwDataService } from "./wwwDataService";
import { Observable } from "rxjs";
import { PHBGlobals } from "../../../_config/config";
import { Directory, File, Hash, Index, Node, Section, UUID } from "../../../_model";
import { isElectron } from "../../../_utils/electron-utils";
import { TagGroup } from "../../../_model/tags";
import { environment } from "../../../environments/environment";
import { Iso639_1, IsoCode, Language } from "../../../_model/Language";
import { AppstatusProvider } from "../appstatus";
import * as _ from "lodash";

@Injectable({providedIn: "root"})
export class DataProvider implements DataService {
	service: DataService;

	get currentNode(): WritableSignal<Node> {
		return this.service.currentNode;
	}

	currentNodeWithIsoCode: WritableSignal<[Node, IsoCode]> = signal<[Node, IsoCode]>([null, null]);

	get currentSection(): WritableSignal<UUID> {
		return this.service.currentSection;
	}

	get section(): Directory {
		return PHBGlobals.index.getNode(this.currentSection()) as Directory;
	}

	get loadingSections(): { [key: string]: Promise<Section> } {
		return this.service.loadingSections;
	}

	searchData: WritableSignal<{
		query: string,
		tags: string[][],
		language: Iso639_1[],
		section: UUID[],
		year: (number|string)[],
		limit: number,
		start: number
	}> = signal<{
		query: string,
		tags: string[][],
		language: Iso639_1[],
		section: UUID[],
		year: (number|string)[],
		limit: number,
		start: number
	}>(null);

	constructor(public http: HttpClient,
	            private appStatus: AppstatusProvider) {
	}

	async init() {
		switch (true) {
			case PHBGlobals.phbClient === "www":
				this.service = new WwwDataService(this.http);
				break;
			case PHBGlobals.phbClient === "win":
				this.service = new WinDataService(this.http);
				break;
			default:
				throw new Error("Unknown client!");
		}

		console.log("Init " + this.service.getServiceName() + " DataService");
	}

	getServiceName(): string {
		return this.service.getServiceName();
	}

	getDirectory(id: UUID, depth: number, header: any = {}): Promise<Directory> {
		return this.service.getDirectory(id, depth, header);
	}

	/**
	 * Gets the path to the file with the given hash.
	 */
	getFilePath(hash: string): string {
		return this.service.getFilePath(hash);
	}

	/**
	 * Gets the file tree path of the file or directory, e.g. "C:/MPCS/PHB4/Files/Service (D)/Anleitungen/Info.pdf".
	 * This is only implemented for the Electron client!
	 */
	getFileTreePath(node: Node, isoCode: IsoCode): string {
		return this.service.getFileTreePath(node, isoCode);
	}

	/**
	 * Gets the path to the file with the given hash, simulating the given filename.
	 * This is only implemented for the www client!
	 */
	getFileNamedPath(hash: string, filename: string): string {
		return this.service.getFileNamedPath(hash, filename);
	}

	/**
	 * Gets the path to the file with the given hash, simulating the given filename.
	 * This is only implemented for the www client!
	 */
	getVideoNamedPath(hash: string, filename: string): string {
		return this.service.getVideoNamedPath(hash, filename);
	}

	async getFileContent(hash: string): Promise<string> {
		return await this.service.getFileContent(hash);
	}

	getIndex(depth: number = -1): Promise<Index> {
		return this.service.getIndex(depth);
	}

	getSubtree(startLevel: number, nodeID: UUID): Promise<Directory> {
		return this.service.getSubtree(startLevel, nodeID);
	}

	async getLanguages(): Promise<Language[]> {
		try {
			return await this.service.getLanguages();
		} catch (e) {
			if (isElectron()) {
				try {
					if (this.appStatus.onlineSignal()) {
						return await this.getRemoteLanguages();
					}
				} catch (e) {
					return await this.http.get<Language[]>("assets/boilerplate/resources/languages.json").toPromise();
				}
			}
			throw e;
		}
	}

	async getNode(id: UUID): Promise<Node> {
		try {
			let node = await this.service.getNode(id);

			if (!node.icon || node.icon == "ordner.png" || node.icon == "no.png" || node.icon == "nix.png" || node.icon == "nix1.png" || node.icon == "no-icon.png") {
				if (!node.niceIconPath) {
					const folder = "assets/placeholder/";
					if (node.nodeType == "File" || node.nodeType == "WebLink") {
						const file = node as File;
						switch (file.mimeType) {
							case "image/jpeg":
							case "image/png":
							case "image/gif":
								file.niceIconPath = this.getFilePath(file.hash);
								break;

							case "video/mp4":
							case "video/webm":
							case "video/ogg":
							case "video/mpeg":
								file.niceIconPath = folder + "video.svg";
								break;

							case "application/pdf":
								if (file.pages) {
									file.niceIconPath = this.getFilePreview(file.hash);
								} else {
									file.niceIconPath = folder + "pdf.svg";
								}
								break;

							default:
								console.log(file.mimeType);
								file.niceIconPath = folder + "file.svg";
								break;
						}

					} else if (node.nodeType == "Directory") {
						node.niceIconPath = folder + "folder.svg";
					}
				}
			} else {
				node.niceIconPath = this.getIcon(node.icon);
			}

			return node;
		} catch (e) {
			throw e;
		}
	}

	getResource(path: string): string {
		return this.service.getResource(path);
	}

	getIcon(icon: string): string {
		var path = "";
		if (!icon) {
			return "";
		}
		if (icon.startsWith("flags/")) {
			path = icon;
		} else {
			path = "icons/" + icon;
		}
		return this.getResource(path) as string;
	}

	getFilePreview(hash: string, page: number = 0): string {
		return this.service.getFilePreview(hash, page);
	}

	getIndexVersion(): Promise<number> {
		if (PHBGlobals.phbVersion) {
			return new Promise<number>(async (resolve) => {
				resolve(PHBGlobals.phbVersion);
			});
		}
		return this.service.getIndexVersion();
	}

	async getServerIndexVersion(): Promise<number> {
		return await (this.http.get(environment.kotlin + "/version") as Observable<number>).toPromise();
	}

	async getServerSoftwareWinVersion(): Promise<string> {
		return await (this.http.get(environment.kotlin + "/software/win/version",
			{responseType: "text"}) as Observable<string>).toPromise();
	}

	async getFileHashes(visibleSections: UUID[] = []): Promise<Hash[]> {
		console.log("getFileHashes()");
		const headers = {
			"PHB-Client": PHBGlobals.phbClient
		};
		const fileHashes = [];
		if (visibleSections.length > 0) {
			for (let section of visibleSections) {
				fileHashes.push(
					...await this.http.get<Hash[]>(environment.kotlin + "/file-hashes/" + section, {"headers": new HttpHeaders(headers)}).toPromise()
				);
			}
		} else {
			fileHashes.push(
				...await this.http.get<Hash[]>(environment.kotlin + "/file-hashes", {"headers": new HttpHeaders(headers)}).toPromise()
			);
		}
		return fileHashes;
	}

	async getCurrentIndex(includeHidden: boolean = null): Promise<Index> {
		const headers = {
			"PHB-Client": PHBGlobals.phbClient
		};
		if (includeHidden !== null) {
			headers["PHB-Hidden"] = `${includeHidden}`;
		}
		return await this.http.get<Index>(environment.kotlin + "/index?depth=-1", {"headers": new HttpHeaders(headers)}).toPromise();
	}

	async getTranslationsForLanguage(lang: Language): Promise<{ [wordId: string]: string }> {
		return await this.service.getTranslationsForLanguage(lang);
	}


	async getRemoteLanguages(): Promise<Language[]> {
		return await this.http.get<Language[]>(`${environment.symfony}/languages`).toPromise();
	}

	async getRemoteTagGroups(): Promise<TagGroup[]> {
		return await this.http.get<TagGroup[]>(`${environment.symfony}/tag-groups`).toPromise();
	}

	async updateSearchData(searchData: {
		query: string,
		tags: string[][],
		language: Iso639_1[],
		section: UUID[],
		year: (number|string)[],
		limit: number,
		start: number
	} | string) {
		if(searchData !== null) {
			if (typeof searchData === "string") {
				let s = this.searchData();
				if (!s) s = {
					query: "",
					section: [],
					limit: null,
					start: 0,
					year: [],
					tags: [],
					language: []
				}
				s.query = searchData;
				searchData = _.clone(s) as {
					query: string,
					tags: string[][],
					language: Iso639_1[],
					section: UUID[],
					year: (number|string)[],
					limit: number,
					start: number
				};
			}
			this.searchData.set(searchData);
			localStorage.setItem('phb-search-' + PHBGlobals.user.sessionHash, JSON.stringify(searchData));
		}else{
			this.searchData.set(null);
			localStorage.removeItem('phb-search-' + PHBGlobals.user.sessionHash);
		}
	}

	async search(term, sectionId, limit: number = null, autocomplete = false, start = 0) {
		if (PHBGlobals.phbClient === "www" || this.appStatus.onlineSignal()) {
			if (!limit || limit <= 0) {
				limit = isElectron() ? 1000 : 150;
			}
			const url = environment.symfony + "/search"
				+ "?q=" + encodeURIComponent(term)
				+ "&section=" + encodeURIComponent(sectionId)
				+ (autocomplete ? "&autocomplete=1" : "")
				+ "&limit=" + limit
				+ (start > 0 ? "&start=" + start : "");

			let headers = {};
			if (isElectron()) {
				headers["PHB-Index-Version"] = PHBGlobals.index.version + "";
			}

			const res = await this.http.get<any>(url, {"headers": new HttpHeaders(headers)}).toPromise();

			for (let key in res.content.hits.hits) {
				const el = res.content.hits.hits[key];
				const splitted = el._id.split("_");
				el._id = splitted[0];
				el.isoCode = `${splitted[1]}` + (splitted[2] ? "_" + splitted[2] : "");
			}
			return res.content;

		} else {
			throw new Error("You must be online to use the search!");
		}
	}


	async advSearch(query: string,
	                tags: string[][] = [],
	                language: Iso639_1[] = null,
	                sections: UUID[] = [],
	                year: (number|string)[] = [],
	                limit: number = null,
	                start: number = 0) {
		if (PHBGlobals.phbClient === "www" || this.appStatus.onlineSignal()) {
			if (!limit || limit <= 0) {
				limit = 1000;
			}
			if(query.indexOf(':') === query.length-1){
				query+='*'
			}

			const searchData = {
				query: query,
				start: start,
				limit: limit,
				tags: tags,
				section: sections,
				year: year,
				language: language
			}

			await this.updateSearchData(searchData);

			const years:number[] = [];
			for(let year of searchData.year){
				if(year.toString().includes('-')){
					const t = year.toString().split('-').map(parseFloat);
					const dif = t[1] - t[0];
					for(let i=t[0]; i<= t[1]; i++){
						years.push(i)
					}
				}else{
					years.push(year as number);
				}
			}
			searchData.year = years;

			const res: any = await this.http.post(`${environment.symfony}/search`, searchData).toPromise();

			for (let key in res.content.hits.hits) {
				const el = res.content.hits.hits[key];
				const splitted = el._id.split("_");
				el._id = splitted[0];
				el.isoCode = `${splitted[1]}` + (splitted[2] ? "_" + splitted[2] : "");
			}
			return res.content;

		} else {
			throw new Error("You must be online to use the search!");
		}
	}

	private _tags?: TagGroup[] = null;

	async getTags(search: boolean = false): Promise<TagGroup[]> {
		if (!this._tags) {
			this._tags = await this.service.getTags(search);
		}
		return this._tags;
	}

	async getTagGroup(id: number) {
		const tags: TagGroup[] = await this.getTags();
		return tags.find(item => {
			return item.id === id;
		});
	}

	async getNodesByTag(tagId: number | number[]): Promise<Node[]> {
		return this.service.getNodesByTag(tagId);
	}

	async getChangelogForNode(directory: Directory): Promise<Node[]> {
		if (!directory.children) {
			return;
		}

		let data: Node[] = [];
		const nodes: Node[] = await this.getNodesByTag([21, 22]);
		for (let node of nodes) {
			let parentIds = node.parentIds;
			if (parentIds) {
				if (parentIds.indexOf(directory.id) !== -1) {
					data.push(node);
				}
			} else {
				console.error("No parentIds set", node);
			}
		}
		return data;
	}

	async getConfig() {
		return await this.service.getConfig();
	}

	async updateNode(node: Node | UUID, isoCode: IsoCode = null) {
		try {
			if (typeof node === "string") {
				node = await this.service.getNode(node as UUID);
			}
			if (!isoCode || !node.translations.hasOwnProperty(isoCode)) {
				isoCode = node.isoCode;
			}

			if (!node.parentIds) {
				throw new Error("ParentIds not set");
			}
			if (node.parentIds[1] && node.parentIds[1] !== this.currentSection()) {
				this.currentSection.set(node.parentIds[1]);
				localStorage.setItem(`phb-section-${PHBGlobals.user.sessionHash}`, node.parentIds[1]);
			}
			this.service.setCurrentNode(node);
			this.currentNodeWithIsoCode.set([node, isoCode]);
		} catch (e) {
			console.log("caught in update", node);
			throw e;
		}
	}

	/**
	 * Use the service method or this.updateNode()!!!
	 * @param node
	 */
	setCurrentNode(node: Node) {
		throw new Error("Use service");
	}

	async openSection(id: UUID) {
		await this.service.openSection(id);
	}

	async getBreadcrumbOfNode(node: Node): Promise<Directory[]> {
		let breadcrumb: Directory[] = [];
		const ids = [...node.parentIds];
		ids.shift();
		for (const nodeID of ids) {
			let dir = await this.getNode(nodeID);
			if (dir && dir.nodeType === "Directory") {
				breadcrumb.push(dir as Directory);
			}
		}
		return breadcrumb;
	}

	track(node: Node) {
		if (location.href.includes("localhost")) {
			return false;
		}
		this.http.post(`${environment.symfony}/track`, {
			node: node.id,
			"user-language": PHBGlobals.currentLanguage
		}).subscribe((res) => {
			console.log(res);
		});

	}

	async fileExists(hash: Hash): Promise<boolean> {
		return await this.service.fileExists(hash);
	}
}
