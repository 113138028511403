<div class="app-page  justify-content-start">
	<div class="modal-header">
		<input class="modal-prevent-focus" disabled>
		<h1 class="modal-title">{{'DocumentPool.Title' | translate:systemLanguage}} ({{lastFileCount}})</h1>
		<div class="modal-close" (click)="close()">
			<span class="icon--cross2"></span>
		</div>
	</div>

	<div class="page-content p-a">

		<div class="zip-inner content-inner">

			@if (!collectedFiles || collectedFiles.length <= 0) {
				<p>{{'zip_download_nodata' | translate:systemLanguage}}</p>
			}

			@if(collectedFiles && collectedFiles.length>0) {
				<h2>{{'zip_title' | translate:systemLanguage}}</h2>

				<table class="mb-3">
					<tr>
						<!--<th class="td-file-prev">{{'zip_field_preview' | translate:systemLanguage}}</th>-->
						<th class="td-file-bez">{{'zip_field_title' | translate:systemLanguage}}</th>
						<!-- ToDo
                        <th class="td-file-date">{{'zip_field_date' | translate:systemLanguage}}</th>
                        <th class="td-file-desc">{{'zip_field_note' | translate:systemLanguage}}</th>
                        -->
						<th class="td-file-pages">{{'DocumentPool.Pages' | translate:systemLanguage}}</th>
						<th class="td-file-type">{{'zip_field_type' | translate:systemLanguage}}</th>
						<th class="td-size">{{'zip_field_size' | translate:systemLanguage}}</th>
						<th class="td-remove"></th>
					</tr>

					@for(collectedFile of collectedFiles; track collectedFile.fileId) {
						<tr [class.no-border-bottom]="collectedFile.fileId === visiblePageSelection">
							<td class="td-file-bez pointer" (click)="open(collectedFile.fileId)">{{collectedFile.translation.caption}}</td>
							<td class="td-file-pages">
								@if(collectedFile.translation.mimeType === 'application/pdf') {
									@if(collectedFile.pages.length > 0 && collectedFile.pages.length < collectedFile.translation.pages) {
										{{collectedFile.pages.length}}
									}
									@if(collectedFile.pages.length === collectedFile.translation.pages || collectedFile.pages.length <= 0) {
										{{"DocumentPool.All" | translate:systemLanguage}}
									}
								}
								@if (collectedFile.translation.mimeType !== 'application/pdf') {
									-
								}
							</td>
							<td class="td-file-type">{{collectedFile.translation.mimeType.split("/")[1]}}</td>
							<td class="td-size">{{collectedFile.translation.size | filesize}}</td>
							<td class="td-remove">
								@if(collectedFile.translation.mimeType === 'application/pdf' && collectedFile.translation.pages > 1) {
									<span class="phb-icon icon--search m-r" (click)="visiblePageSelection = collectedFile.fileId" [title]="'DocumentPool.SelectPages' | translate:systemLanguage"></span>
								}
								<span class="phb-icon icon--trash" (click)="removeFile(collectedFile)" [title]="'title_remove' | translate:systemLanguage"></span>
							</td>
						</tr>
						@if(collectedFile.translation.mimeType === 'application/pdf' && collectedFile.translation.pages > 1 && visiblePageSelection === collectedFile.fileId) {
							<tr>
								<td colspan="5">
									<div class="file-pages m-t-half">
										@for(page of getPagesAsArray(collectedFile.translation.pages); track page) {
											<div class="file-page">
												<img [src]="api + '/file-preview/'+collectedFile.translation.hash + '/'+page+'.jpg'" [alt]="collectedFile.translation.caption + ' - ' + (page + 1)" class="file-page-image"/>
												<div class="text-center">
													<label class="file-page-number m-t-quarter">
														{{page + 1}}
														<input type="checkbox" [checked]="isPageSelected(collectedFile,page)" (click)="togglePage(collectedFile,page)"/>
													</label>
												</div>
											</div>
										}

									</div>

									<div class="file-button-wrapper m-y">
										<button mat-flat-button color="light" class="m-r" (click)="visiblePageSelection = null">{{'close' |translate:systemLanguage}}</button>
										<button mat-flat-button class="m-r" color="primary" (click)="selectAll(collectedFile)">{{'PDFPageChooser.SelectAll' |translate:systemLanguage}}</button>
										<button mat-flat-button class="m-r" color="primary" (click)="selectNone(collectedFile)">{{'PDFPageChooser.SelectNone' |translate:systemLanguage}}</button>
									</div>

								</td>
							</tr>
						}
					}
				</table>

				<div class="text-content p-a-0">
					<button mat-flat-button color="light" (click)="createZip()" class="m-t-half">{{'zip_button_download' | translate:systemLanguage}}</button>
				</div>


				<div class="text-content">
					<div class="zip-download_email-form card card--light p-a">
						<div>
							<h3 class="m-t-0">{{'DocumentPool.SendNow' | translate:systemLanguage}}</h3>
							<div class="zip-download_email-form__inner">
								<div>
									<p [innerHTML]="'DocumentPool.SendSizeMB' | translate:systemLanguage:{'{MB}':total_filesize | filesize:false}"></p>
									<div class="input-wrapper m-b-0">
										<label>
											{{'DocumentPool.Receiver' | translate:systemLanguage}}<br>
											<input [(ngModel)]="mailRecipient" type="email" name="recipient"
												placeholder="beispiel@melitta.de" class="m-t-quarter" [class.error]="!validateEmail(mailRecipient)"/>
										</label>
									</div>
								</div>

								<div class="input-wrapper p-t-half m-b-0">
									<textarea [(ngModel)]="mailText" name="text"></textarea>
								</div>

							</div>

						</div>
						<button color="primary" mat-flat-button (click)="send()" class="m-t-half">{{'DocumentPool.SendNowSingle' | translate:systemLanguage}}</button>
					</div>
				</div>
			}
		</div>

	</div>

</div>
