import { Component, OnInit }                                           from "@angular/core";
import { setDefaultUserSettings, User, UserSettings }                  from "../../../_model/user";
import { UserProvider }                                                from "../../services/user/user";
import { callElectron, isElectron }                                    from "../../../_utils/electron-utils";
import { translate }                                                   from "../../../_model/vocab.fun";
import { LanguageService }                                             from "../../services/language";
import { Directory }                                                   from "../../../_model";
import { PHBGlobals }                                                  from "../../../_config/config";
import { AppstatusProvider }                                           from "../../services/appstatus";
import { Router }                                                      from "@angular/router";
import { Location }                                                    from "@angular/common";
import { Iso639_1, Language }                                 from "../../../_model/Language";
import { MatSnackBar }                                                 from "@angular/material/snack-bar";
import { MatDialog }                                                   from "@angular/material/dialog";
import {
	ChangedZoomComponent
}                                                                      from "../../dialogs/changed-zoom/changed-zoom.component";

@Component({
	selector: "settings-modal",
	templateUrl: "settings.html",
	styleUrls: ["settings.scss"]
})
export class SettingsModal implements OnInit {

	settings: UserSettings = new UserSettings(null);
	isWindowsClient: boolean = isElectron();
	languages: Language[] = [];

	restart: boolean = false;
	update: boolean = false;
	warning: string = "";

	sections: Directory[] = null;

	view: string;
	language: any;

	currentZoom: number = 1;

	online: boolean = false;
	newsletterRecipient: boolean = false;
	currentUser: User;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(public userProvider: UserProvider,
		private status: AppstatusProvider,
		private snackBar: MatSnackBar,
		private langProvider: LanguageService,
		private router: Router,
		private location: Location,
		private dialog: MatDialog
	) {
	}

	async ngOnInit() {
		const l = await this.langProvider.getLanguages();
		for (let lang in l) {
			this.languages.push(l[lang]);
		}

		this.online = this.status.onlineSignal();

		if (this.online) {
			this.currentUser = await this.userProvider.getUser();
		} else {
			this.currentUser = PHBGlobals.user;
		}

		setDefaultUserSettings(this.currentUser);
		if (this.currentUser.data.settings) {
			this.settings = this.currentUser.data.settings;
		}

		this.newsletterRecipient = this.currentUser ? this.currentUser.data.permissions.newsletter : false;

		if (!this.settings.language) {
			this.settings.language = PHBGlobals.currentLanguage;
		}

		if (this.settings.zoom) {
			this.currentZoom = this.settings.zoom;
		}
	}

	async saveChanges() {

		localStorage.setItem("_phb_left_view", this.settings.view);

		if (PHBGlobals.currentLanguage !== this.settings.language) {
			console.log("change it", this.settings.language);
			//await this.langProvider.set(this.settings.language);
			//TODO sort languages and save to user!
			this.warning = translate("settings_restart_warning");
			this.restart = true;
		}

		await this.userProvider.updateSettings(this.settings);
		if (this.online && this.currentUser && this.currentUser.data.permissions.newsletter !== this.newsletterRecipient) {
			this.currentUser.data.permissions.newsletter = this.newsletterRecipient;
			await this.userProvider.update(this.currentUser, null);
		}

		if (!this.update) {
			if (PHBGlobals.phbClient === "www") {
				if (this.restart) {
					location.reload();
				}
			}

			this.snackBar.open(translate("Toastr.Message.Success.Saved"),"", {
				duration: 3000,
				panelClass: "custom-snackbar--success",
				verticalPosition: "top",
				horizontalPosition: "center"
			});
		}
		//TODO save offline users to online DB!
	}

	showWarning() {
		if (isElectron()) {
			if (PHBGlobals.currentLanguage !== this.settings.language) {
				this.warning = translate("settings_restart_warning");
				this.restart = true;
			} else {
				this.warning = "";
				this.restart = false;
			}
		}
	}

	async close() {
		this.location.back();
	}

	async startUpdate() {
		await this.saveChanges();
		await this._startUpdate();
	}

	async _startUpdate() {
		await this.router.navigate(["/updater"]);
	}

	async changedZoom() {
		if (this.currentZoom !== this.settings.zoom) {
			this.dialog.open(ChangedZoomComponent, {
				panelClass: "cdk-overlay__alert",
				backdropClass: "cdk-overlay__alert-backdrop"
			}).afterClosed().subscribe(async (result) => {
				if(result == 'restart') {
					console.log(1);
					this.settings.zoom = this.currentZoom;
					await this.saveChanges();
					callElectron("restart");
				}
				else {
					console.log(2);
					this.currentZoom = this.settings.zoom;
				}
			});
		}
	}


	canSeeTreeTranslationSettings(): boolean {
		if (isElectron()) {
			return false;
		}
		if (!PHBGlobals.useTranslationFallback) {
			return false;
		}
		return true;
	}
}
