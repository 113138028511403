<div class="section-list-backdrop" [class.section-list-backdrop--clickable]="popupOpen" (click)="closePopup($event)"></div>
@if(currentSection) {
	<div class="section-list">
		<div class="sections w-100 d-flex">
			<div class="section-inner w-100">
				<div [ngClass]="(sections.length <=1 ? 'no-cursor ' : '') + 'section-select'">
					<!-- Header with current section and its icon -->
					<div class="section-select-box" (click)="togglePopOver()" [class.open]="popupOpen">
						@if(currentSection.icon) {
							<div class="icon">
								<img [src]="currentSection | getIcon" [alt]="currentSection | caption:systemLanguage" width="20"/>
							</div>
						}
						<div class="text" [innerHTML]="currentSection | caption:systemLanguage"></div>
						@if(sections.length > 1) {
							@if(popupOpen) {
								<span class="phb-icon icon icon--arrow--up"></span>
							} @else {
								<span class="phb-icon icon icon--arrow--down"></span>
							}
						}
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>


		@if(sections.length > 1) {
			<div class="section-list__outer-popover" [class.section-list__outer-popover--visible]="popupOpen">
				<div class="section-list__outer-popover-inner">
					@for(section of sections; track section.id) {
						@if(section.id !== currentSection.id) {
							<div class="section pointer" (click)="changeSection(section)">
								@if(section.icon) {
									<div class="icon">
										<img [src]="section | getIcon" [alt]="section | caption:systemLanguage" width="20"/>
									</div>
								}
								<div class="bez" [innerHTML]="section | caption:systemLanguage"></div>
							</div>
						}
					}
				</div>
			</div>
		}
	</div>
}
