@if (currentSection) {
	<ul class="menu-list">
		@for (node of currentSection.children; track node.id) {
			<ng-template [ngTemplateOutlet]="nodeEl" [ngTemplateOutletContext]="{ node: node, level:0 }"></ng-template>
		}
	</ul>
}



<ng-template #nodeEl let-node="node" let-_level="level">
	<li menuClose [class]="'level-'+_level +' list-node-'+node.nodeType.toLowerCase()"
		[attr.data-id]="node.id" [ngStyle]="node | color" [title]="node | caption:systemLanguage">
		@if (node.nodeType === 'Directory') {
			<ng-template [ngTemplateOutlet]="directoryEl"
				[ngTemplateOutletContext]="{ directory: node, level:(_level+1) }"></ng-template>
		}
		@if (!canSeeTranslations() && node.isFileType()) {
			<ng-template [ngTemplateOutlet]="fileEl" [ngTemplateOutletContext]="{ file: node }"></ng-template>
		}
	</li>
</ng-template>

<ng-template #directoryEl let-directory="directory" let-_level="level">
	<!-- Directory or Heading -->
	<div [class.heading]="directory.heading" (click)="onItemClicked(directory)"
		[class.is-active]="currentNode && (currentNode.id === directory.id || currentNode.parentIds.includes(directory.id))">
			<span class="item-bez">
				<span class="item-bez-inner" [ngStyle]="getTextColor(directory)">
					@if (directory.treeIcon || directory.icon) {
						<img [src]="directory | getIcon:true:'treeIcon'"
							[alt]="directory | caption:systemLanguage"/>
					}

					<span class="item-bez-inner-text">{{ directory | caption:systemLanguage }}</span>
				</span>
			</span>
		@if (!directory.heading && directory.icon) {
			<div class="item-big-flag">
				@if (directory.treeIcon || directory.icon) {
					<img [src]="directory | getIcon:true:'treeIcon'" [alt]="directory | caption:systemLanguage"/>
				}
			</div>
		}

	</div>

	@if (isOpen(directory)) {
		<ul style="margin:0;padding:0; list-style: none">
			@if (!canSeeTranslations() && !directory.hideChildren) {
				@for(child of directory.children; track child.id) {
					<ng-template [ngTemplateOutlet]="nodeEl"
						[ngTemplateOutletContext]="{ node: child, level:(_level+1) }"></ng-template>
				}
			}

			@if (canSeeTranslations()) {
				@for(child of directory.children | noFiles; track child.id) {
					<ng-template [ngTemplateOutlet]="nodeEl"
						[ngTemplateOutletContext]="{ node: child, level:(_level+1) }"></ng-template>
				}

				@for(lang of languages; track lang.isocode) {
					@for(child of directory.children | onlyFiles; track child.id) {
						@if (child | defaultTranslation:lang) {
							<li menuClose [class]="'level-'+_level +' list-node-'+child.nodeType.toLowerCase()"
								[attr.data-id]="child.id" [ngStyle]="child | color"
								[title]="child | caption:systemLanguage">
								<ng-template [ngTemplateOutlet]="fileTranslations"
									[ngTemplateOutletContext]="{ file: child, language:lang }"></ng-template>
							</li>
						}
					}
				}
			}
		</ul>
	}

</ng-template>

<ng-template #fileTranslations let-file="file" let-_lang="language">
	<div (click)="onTranslatedItemClick(file,_lang)" [class.is-active]="currentNode && currentNode.id === file.id && currentIsoCode === (file | defaultTranslation:_lang).key">
		<span class="item-bez">
			<span class="item-bez-inner" [ngStyle]="getTextColor(file)">
				<img
					[src]="'assets/flags/'+(((file | defaultTranslation:_lang).key) | isoCodeToCountryKey).toUpperCase()+'.png'"
					[alt]="(file | defaultTranslation:_lang).value.caption"/>
				<span class="item-bez-inner-text">{{ (file | defaultTranslation:_lang).value.caption }}</span>
			</span>
		</span>
	</div>
</ng-template>

<ng-template #fileEl let-file="file">
	<!-- File or Weblink -->
	<div (click)="onItemClicked(file)" [class.is-active]="currentNode && currentNode.id === file.id">
		<span class="item-bez">
			<span class="item-bez-inner" [ngStyle]="getTextColor(file)">
				@if (file.treeIcon || file.icon) {
					<img [src]="file | getIcon:true:'treeIcon'" [alt]="file | caption:systemLanguage"/>
				}
				<span class="item-bez-inner-text">{{ file | caption:systemLanguage }}</span>
			</span>
			@if (useFallback && file.isoCode !== '__' && currentLanguage !== file.isoCode.split('_')[0]) {
				<span class="item-fallback-iso" [style.color]="'#000000'">{{ file.isoCode.split('_')[0].toUpperCase() }}</span>
			}
		</span>
	</div>
</ng-template>
