import { Directory, Node } from "./index";


export function isToc(directory: Directory) {
	if (!directory.tags) {
		return false;
	}
	if (directory.tags.indexOf(20) === -1) {
		return false;
	}
	if (directory.tags.indexOf(42) === -1) {
		return false;
	}
	return true;
}


export function isPageOfBook(node: Node) {
	if (!node.specialType) {
		return false;
	}
	if (node.specialType !== "page") {
		return false;
	}
	return true;
}
