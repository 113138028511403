import { Directive, effect, ElementRef, input, InputSignal, Renderer2 } from "@angular/core";
import { Node }                                                                from "../../_model";

@Directive({
	selector: "[nodeBackground]"
})
export class NodeBackgroundDirective {
	domElement: any;

	nodeBackground: InputSignal<Node> = input<Node>();

	constructor(private renderer: Renderer2,
		private elementRef: ElementRef) {

		this.domElement = this.elementRef.nativeElement;

		effect(() => {
			const n = this.nodeBackground();
			this.updateView();
		});
	}

	updateView() {
		const requiredStyles = {};

		if (this.nodeBackground().color) {
			requiredStyles["background"] = "#" + this.nodeBackground().color;
		}

		Object.keys(requiredStyles).forEach(newStyle => {
			this.renderer.setStyle(
				this.domElement, `${newStyle}`, requiredStyles[newStyle]
			);
		});
	}

// Any other logic to be implemented for directive…
}
