@if(everythingFine && !softwareUpdateNeeded) {
	<app-phb></app-phb>
}

@if(softwareUpdateNeeded) {
	<app-software-updater></app-software-updater>
}

@if(dataUpdateNeeded && !softwareUpdateNeeded) {
	@if (!online) {
		<div>
			<div class="pseudo-page mx-3">
				<h1>Etwas ist schief gegangen!</h1>
				<p>Das PHB muss zwingend aktualisiert werden! Bitte stellen Sie sicher, dass eine Verbindung zum Internet besteht!</p>
			</div>
		</div>
	} @else {
		<router-outlet></router-outlet>
	}
}
