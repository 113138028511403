/**
 * Sprachenpaket mit Übersetzungen.
 * Zugriff z.B.: languages["de"]["S2"] = "Pfahltyp"
 */
import { PHBGlobals } from "../_config/config";
import { Iso639_1 }   from "./Language";

export interface Vocab {
	languages: {
		[languageId: string]: {
			[wordId: string]: string
		}
	};
}

/**
 * Global language pack.
 */
export const global_vocab_settings = {
	fallbackLanguageId: "en",
	vocab: {languages: {}} as Vocab
};

/**
 * Übersetze das gegebene Wort in die aktuelle Sprache
 * oder in die Fallback-Sprache.
 */
export function translate(wordId: string): string {
	let langId: Iso639_1 = PHBGlobals.systemLanguage;
	return translateToLang(wordId,langId);
}

export function translateToLang(wordId: string,langId:Iso639_1){
	if (!wordId) {
		return "";
	}
	let s = global_vocab_settings;

	let lang = s.vocab.languages[langId];
	if (lang) {
		let value = lang[wordId];
		if (value) {
			return value;
		} else {
			console.log(`cant find translation for ${wordId} : ${langId}`);
		}
	} else {
		console.log("cant find language " + langId);
	}
	//Nicht gefunden? Versuche nochmal in Fallbacksprache
	lang = s.vocab.languages[s.fallbackLanguageId];
	if (lang) {
		let value = lang[wordId];
		if (value) {
			return value;
		} else {
			console.log("cant find translation for " + wordId + " in fallback language " + s.fallbackLanguageId);
		}
	}
	//Immer noch nicht gefunden? Verwende Word-ID als Fallback
	return wordId;
}
