import { Pipe, PipeTransform } from "@angular/core";

/**
 * Generated class for the FilesizePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
	name: "filesize",
	standalone: true
})
export class FilesizePipe implements PipeTransform {

	transform(size: string | number, extension: boolean = true, ...args) {
		if (!size) {
			return "";
		}

		if (typeof size === "string") {
			size = parseInt(size);
		}

		if (size > 1073664000) {
			return Math.round(size / 1024 / 1024 / 1024 * 100) / 100 + (extension ? " GB" : "");
		}
		if (size > 10485) {
			return Math.round(size / 1024 / 1024 * 100) / 100 + (extension ? " MB" : "");
		} else {
			return Math.round(size / 1024 * 100) / 100 + (extension ? " MB" : "");
		}
	}
}
