import { HttpClient } from "@angular/common/http";
import { DataService } from "./dataService";
import { assignIndex, Directory, Hash, Index, Node, NodeTranslation, Section, UUID } from "../../../_model";
import { Observable } from "rxjs";
import { fatalError } from "../../../_utils/general-utils";
import { PHBGlobals }  from "../../../_config/config";
import { TagGroup} from "../../../_model/tags";
import { environment } from "../../../environments/environment";
import { IsoCode, Language } from "../../../_model/Language";
import { convert_accented_characters } from "../../../_utils/charTable";
import { callAsyncElectron }      from "../../../_utils/electron-utils";
import { signal, WritableSignal } from "@angular/core";

class LocalSettings {
	localBasePath = environment.localBasePath;
	localDbPath = environment.localBasePath + "/Database";

	get LocalClientPath(): string {
		return `${this.localBasePath}/Software/Client`;
	}

	get localFilesPath() {
		return `${this.localBasePath}/Files`;
	};
}

export const ElectronLocalSettings = new LocalSettings();

export const LocalClientPath = `${environment.localBasePath}/Software/Client`;
export const localDbPath = environment.localBasePath + "/Database";
export const localFilesPath = environment.localBasePath + "/Files";


export class WinDataService implements DataService {
	private name: string = "win";
	config: { appDataDir: string, [key: string]: string };

	currentNode: WritableSignal<Node> = signal<Node>(null);
	currentSection: WritableSignal<UUID> = signal<UUID>(null);
	loadingSections: { [key: string]: Promise<Section> } = {};

	constructor(private httpClient: HttpClient) {
	}

	getServiceName(): string {
		return this.name;
	}

	async getConfig() {
		if (!this.config) {
			try {
				this.config = await this.httpClient.get<any>(ElectronLocalSettings.LocalClientPath + "/config").toPromise();
				console.log("Config loaded", this.config);
			} catch (e) {
				console.error(e);
				console.error("No config found!");
			}
		}
		return this.config;
	}

	async init() {
		console.log("Loading index...");
		PHBGlobals.index._tempNodes = {};
		const index = await this.httpClient.get<Index>(ElectronLocalSettings.localDbPath + "/index").toPromise();
		assignIndex(index);

		const sectionId = localStorage.getItem(`phb-section-${PHBGlobals.user.sessionHash}`);
		if (!sectionId || !PHBGlobals.index.getSections().find(it => it.id === sectionId)) {
			await this.openSection(PHBGlobals.index.getSections()[0].id);
		} else {
			await this.openSection(sectionId);
		}

		for (let section of PHBGlobals.index.getSections()) {
			this.loadingSections[section.id] = new Promise<Section>((resolve) => {
				resolve(section);
			})
		}

		console.info("Index loaded", PHBGlobals.index);
		PHBGlobals.indexChangedSignal.set(true);
	}

	async openSection(id: UUID): Promise<void> {
		let section: Directory = null;
		if (PHBGlobals.index.root.children) {
			section = PHBGlobals.index.root.children.find(it => it.id === id) as Directory;
		}

		if (!section) {
			throw new Error("Section not found in index!");
		}

		this.currentSection.set(section.id);
		localStorage.setItem(`phb-section-${PHBGlobals.user.sessionHash}`, section.id);
		return;
	}

	async getIndex(depthIgnored = -1): Promise<Index> {
		return PHBGlobals.index;
	}

	async getSubtree(startLevel: number, nodeID: UUID): Promise<Directory> {
		throw Error("getSubtree not implemented!");
	}

	async getDirectory(id: UUID, depth: number, header: any = {}): Promise<Directory> {
		return PHBGlobals.index.getDirectory(id);
	}

	private _hashToPath(hash: string) {
		return hash.substring(0, 2) + "/" + hash.substring(2, 4) + "/" + hash
	}

	getFilePath(hash: string): string {
		const filePath = ElectronLocalSettings.localDbPath + "/" + this._hashToPath(hash);
		//console.log("Returning file path: " + filePath);
		return filePath;
	}

	getFileTreePath(node: Node, isoCode: IsoCode): string {
		let translation: NodeTranslation = null;
		if (node.translations[isoCode]) {
			translation = node.translations[isoCode];
		} else {
			const [_isoCode, _translation] = node.getTranslation(isoCode);
			isoCode = _isoCode;
			translation = _translation;
		}
		console.log(translation)

		let path = translation.systemFullName;
		if (path == null) {
			return "";
		} //illegal node in parameter
		path = path.replace(/\//g, "-").trim();
		let parentId = node.parentId;
		if (parentId !== "71cf3e6f-ba83-4c86-8b14-73287b732068") {
			const parentDir = PHBGlobals.index.getDirectory(parentId);
			path = this._hashToPath(parentDir.id) + "/" + isoCode + "/" + path;
		}

		console.log(localFilesPath + "/" + path);
		return localFilesPath + "/" + path;
	}

	getFileNamedPath(hash: string, filename: string): string {
		fatalError("getFileNamedPath: not implemented for win client");
		return ""; //not implemented for Electron client
	}

	getVideoNamedPath(hash: string, filename: string): string {
		return environment.kotlin + "/video/" + hash + "/" + convert_accented_characters(filename);
	}

	getFileContent(hash: string): Promise<string> {
		return null;
	}

	async getLanguages(): Promise<Language[]> {
		const languages = await this.httpClient.get<Language[]>(ElectronLocalSettings.localDbPath + "/lang/languages.json").toPromise();
		return languages.filter(it => it.hidden === undefined || it.hidden === false);
	}

	async getNode(id: UUID): Promise<Node> {
		if (!environment.production)
			console.log(`getNode(${id})`);
		return PHBGlobals.index.getNode(id);
	}

	getResource(path: string): string {
		//this.log(`getResource(${path})`);
		for (let key in PHBGlobals.index.resources) {
			if (key === path) {
				let filePath = this.getFilePath(PHBGlobals.index.resources[key]);
				//console.log("found for " + path + ": " + filePath);
				return filePath;
			}
		}
		return null;
	}

	getFilePreview(hash: string, page: number): string {
		if (page < 0) {
			return this.getFilePath(hash);
		}
		return this.getFilePath(hash + ".preview." + page);
	}

	private log(message: string) {
		//console.log("WinDataService: " + message);
	}

	async getIndexVersion(): Promise<number> {
		this.log("getIndexVersion()");
		return PHBGlobals.index.version;
	}

	async getTranslationsForLanguage(lang: Language): Promise<{ [wordId: string]: string }> {
		//const path = this.getFilePath(lang.i18nFile);
		const path = ElectronLocalSettings.localDbPath + "lang/" + lang.isocode + ".json";
		let language;
		try {
			language = await (this.httpClient.get(path) as Observable<Language>).toPromise();
		} catch (e) {
			console.warn("Loading local translations for '" + lang.isocode + "'!");
			language = await (this.httpClient.get("./assets/boilerplate/resources/lang/" + lang.isocode + ".json") as Observable<Language>).toPromise();
		}
		if (!language.translations) {
			throw new Error("No Translations found!");
		}
		return language.translations;
	}


	async getTags(search: boolean = false): Promise<TagGroup[]> {
		const tagGroups:TagGroup[] = await this.httpClient.get<TagGroup[]>(ElectronLocalSettings.localDbPath + "/tag-groups.json").toPromise();
		if(!search) return tagGroups;
		return tagGroups.filter(it => !it.disableInSearch)
	}

	async getNodesByTag(tagId: number | number[]): Promise<Node[]> {
		return this.findTags(PHBGlobals.index.root, tagId);
	}

	private findTags(node, tagId) {
		let nodes: Node[] = [];

		if (typeof tagId === "number") {
			if (this.hasTag(node, tagId) && nodes.findIndex(it => it.id === node.id) === -1) {
				nodes.push(node);
			}
		} else {
			for (let tag of tagId) {
				if (this.hasTag(node, tag) && nodes.findIndex(it => it.id === node.id) === -1) {
					nodes.push(node);
				}
			}
		}

		if (node?.nodeType === "Directory") {
			let dir = node as Directory;
			for (let child of dir.children) {
				nodes.push(...this.findTags(child, tagId));
			}
		}

		return nodes;
	}

	private hasTag(node, tag) {
		if (!node.tags) {
			return false;
		}
		return node.tags.indexOf(tag) !== -1;
	}

	setCurrentNode(node: Node) {
		this.currentNode.set(node);
	}

	async fileExists(hash: Hash): Promise<boolean> {
		const path = this.getFilePath(hash);
		return await callAsyncElectron("fileExists", () => {
		}, path);
	}


}

class Auth {
	username: string;
	accessToken: string;
	refreshToken: string;
}
