import { Component, input, InputSignal } from "@angular/core";
import { PHBGlobals }                           from "../../../_config/config";
import { AppstatusProvider }               from "../../services/appstatus";
import { isElectron }                      from "src/_utils/electron-utils";
import { Iso639_1 }                        from "../../../_model/Language";
import { DataProvider }                    from "../../services/data/data";
import { Router }                          from "@angular/router";
import { closeSplitMenu, reopenSplitMenu } from "../../services/gui";
import packageInfo                         from "../../../../package.json";
import { versionToNumeric }                from "../../../_utils/general-utils";
import { MatDialog}                        from "@angular/material/dialog";

import { ConfigComponent }                 from "../../components/config/config";
import { UserComponent }                   from "../../components/user/user";
import { SearchbarComponent }              from "../../components/searchbar/searchbar";
import { ZipComponent }                    from "../../components/zip/zip";
import { LanguageHeaderComponent }         from "../../components/language-header/language-header.component";
import { UpdaterComponent }                from "../../components/updater/updater";
import { TranslatePipe }                   from "../../pipes/translate";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
	standalone: true,
	imports: [
	    ConfigComponent,
	    UserComponent,
	    SearchbarComponent,
	    ZipComponent,
	    LanguageHeaderComponent,
	    UpdaterComponent,
		TranslatePipe
	]
})
export class HeaderComponent {
	preview: InputSignal<boolean> = input<boolean>();
	everythingFine: InputSignal<boolean> = input<boolean>();

	version = PHBGlobals.phbVersion;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	get canSeeSearch(): boolean {
		if (versionToNumeric(packageInfo.version) < versionToNumeric("4.6.16")) {
			//old Client
			return false;
		} else if (versionToNumeric(packageInfo.version) > versionToNumeric("4.6.16")) {
			return this.appStatus.onlineSignal();
		} else {
			if (!this.appStatus.onlineSignal()) return false;
			if (this.isElectron()) return true;
			if (!PHBGlobals.user.loggedIn) return false;
			if (!PHBGlobals.user.isAdmin()) return false;

			return true;
		}
	}

	constructor(private appStatus: AppstatusProvider,
	            private data: DataProvider,
	            private router: Router,
		private dialog: MatDialog) {
	}

	isElectron() {
		return isElectron();
	}

	async goHome() {
		this.dialog.closeAll(); //TODO: Ist es jemals möglich das ein Modal offen und gleichzeitig das Logo klickbar ist?


		const menuSize = Number(localStorage.getItem('menu-size'));
		reopenSplitMenu(menuSize);

		this.data.updateSearchData(null);
		await this.router.navigate(["/home"]);
		if (window.innerWidth < 768) {
			closeSplitMenu();
		}
	}

}
