import { HttpClient }                                 from "@angular/common/http";
import { Injectable, Signal, signal, WritableSignal } from "@angular/core";
import { File, FileTranslation, UUID }                from "../../_model";
import { Observable } from "rxjs";
import { UserProvider }                from "./user/user";
import { environment }                 from "../../environments/environment";
import { IsoCode }                     from "../../_model/Language";


export interface CollectionEntry {
	fileId: UUID;
	isoCode: IsoCode;
	translation: FileTranslation;
	pages?: number[];
}

@Injectable({providedIn: "root"})
export class ZipProvider {
	private _storage: CollectionEntry[];

	private _fileCountSignal: WritableSignal<number> = signal<number>(0);
	readonly fileCountSignal: Signal<number> = this._fileCountSignal.asReadonly();

	constructor(public http: HttpClient,
		public userProvider: UserProvider) {
	}

	async init() {
		this._storage = JSON.parse(localStorage.getItem('phb-zip-storage'));
		if (this._storage === null) {
			this._storage = [];
		}

		this._fileCountSignal.set(this._storage.length);
	}

	async getAll(): Promise<CollectionEntry[]> {
		return this._storage;
	}

	async add(file: File, isoCode: IsoCode) {
		if (!this.has(file, isoCode)) {
			this._storage.push({fileId: file.id, translation: file.translations[isoCode], isoCode: isoCode});
		}
		this._save();
		this._fileCountSignal.set(this.count());
	}

	count(): number {
		return this._storage.length;
	}

	async delete(id: UUID, isoCode: IsoCode) {
		let pos = this._storage.findIndex(el => el.fileId === id && el.isoCode === isoCode);
		this._storage.splice(pos, 1);
		this._save();
		this._fileCountSignal.set(this.count());
	}

	async truncate() {
		this._storage = [];
		this._save();
		this._fileCountSignal.set(this.count());
	}

	private _save() {
		localStorage.setItem('phb-zip-storage', JSON.stringify(this._storage));
	}

	async create(files: CollectionEntry[]): Promise<string> {
		return await (this.http.post(environment.symfony + "/file-collect/zip", {files: files}) as Observable<string>).toPromise();
	}

	has(file: File, isoCode: IsoCode): boolean {
		return !!this._storage.find(it => it.fileId === file.id && it.isoCode === isoCode);
	}

	async send(target: string, subject: string, body: string, files: CollectionEntry[]) {
		await this.http.post(environment.symfony + "/file-collect/send", {
			target: target,
			subject: subject,
			body: body,
			files: files
		}).toPromise();
	}

}
