import { Directive, effect, input, InputSignal, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Directory, Node }                                                                     from "../../_model";

@Directive({
	selector: "[hasChildren]"
})
export class HasChildrenDirective implements OnInit {

	node: Node;
	filesOnly: boolean = true;

	hasChildren: InputSignal<[Node, boolean]> = input<[Node, boolean]>();

	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {

		effect(() => {
			const d = this.hasChildren();
			[this.node, this.filesOnly] = d;
			this.updateView();
		});
	}

	ngOnInit() {
		this.updateView();
	}

	private updateView() {
		this.viewContainer.clear();
		if (this.node?.nodeType === "Directory") {
			const dir = this.node as Directory;
			if (dir.children && dir.children.length > 0 && (!this.filesOnly || dir.children.filter(child => child.nodeType === "File" || child.nodeType == "WebLink" || child.nodeType === "VirtualFile").length > 0)) {
				this.viewContainer.createEmbeddedView(this.templateRef);
				return;
			}
		}
		this.viewContainer.clear();
	}
}
