import { DataService } from "./dataService";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { assignIndex, assignNode, Directory, Hash, Index, Node, Section, UUID } from "../../../_model";
import { fatalError } from "../../../_utils/general-utils";
import { TagGroup } from "../../../_model/tags";
import { environment } from "../../../environments/environment";
import { convert_accented_characters } from "../../../_utils/charTable";
import { Language } from "../../../_model/Language";
import { PHBGlobals }             from "../../../_config/config";
import { signal, WritableSignal } from "@angular/core";

export class WwwDataService implements DataService {
	private name: string = "www";

	currentNode: WritableSignal<Node> = signal<Node>(null);
	currentSection: WritableSignal<UUID> = signal<UUID>(localStorage.getItem(`phb-section-${PHBGlobals.user.sessionHash}`));

	constructor(private httpClient: HttpClient) {
	}

	getServiceName(): string {
		return this.name;
	}

	loadingSections: { [key: string]: Promise<Section> } = {};

	async init() {
		const sections = await this.httpClient.get<Directory[]>(environment.kotlin + "/sections").toPromise();
		const index = await this.getIndex(0);
		assignIndex(index);
		if (!PHBGlobals.index.i18n) {
			throw new Error("Index isn't 4.4 compatible!");
		}

		const sectionId = localStorage.getItem(`phb-section-${PHBGlobals.user.sessionHash}`);
		if (!sectionId || !sections.find(it => it.id === sectionId)) {
			console.log(PHBGlobals.index);
			await this.openSection(sections[0].id);
		} else {
			await this.openSection(sectionId);
		}

		for (let section of sections) {
			this.loadingSections[section.id] = this.loadSection(section.id);
		}

		console.info("Index loaded", !environment.production ? PHBGlobals.index : "prod");

	}

	async openSection(id: UUID): Promise<void> {
		const section: Directory = await this.loadSection(id);

		this.currentSection.set(section.id);
		localStorage.setItem(`phb-section-${PHBGlobals.user.sessionHash}`, section.id);
		return;
	}

	async loadSection(id: UUID): Promise<Section> {
		PHBGlobals.index._tempNodes = {};
		if (this.loadingSections[id]) {
			return await this.loadingSections[id];
		} else {
			let section: Directory = null;
			if (PHBGlobals.index.root.children) {
				section = PHBGlobals.index.getSections().find(it => it.id === id) as Directory;
			} else {
				PHBGlobals.index.root.children = [];
			}

			if (!section) {
				section = await this.getDirectory(id, -1);
				PHBGlobals.index.root.children.push(section);
				PHBGlobals.index._tempNodes = {};
			}
			return section as Section;
		}

	}

	async getDirectory(id: UUID, depth: number = 2, header = {}): Promise<Directory> {
		const flat = PHBGlobals.index.nodes;
		if (!flat[id]) {
			const dir = await this.httpClient.get<Directory>(environment.kotlin + "/directory/" + id + "?depth=" + depth).toPromise();

			flat[id] = assignNode(dir);
		}
		return flat[id] as Directory;
	}

	getFilePath(hash: string): string {
		return environment.kotlin + "/file/" + hash;
	}

	getFileTreePath(node: Node): string {
		fatalError("getFileTreePath: not implemented for www client");
		return ""; //not implemented for www client
	}

	getFileNamedPath(hash: string, filename: string): string {
		return environment.kotlin + "/file-named/" + hash + "/" + convert_accented_characters(filename);
	}

	getVideoNamedPath(hash: string, filename: string): string {
		return environment.kotlin + "/video/" + hash + "/" + convert_accented_characters(filename);
	}

	async getFileContent(hash: string): Promise<string> {
		try {
			let content = await this.httpClient.get(environment.kotlin + "/file/" + hash, {
				headers: {"Content-Type": "text/plain; Charset=UTF-8"},
				responseType: "text"
			}).toPromise();
			console.log(content);
			content = escape(content);
			return decodeURIComponent(content);
		} catch (e) {
			console.error("Could not load file content.", e);
			return null;
		}
	}

	async getIndex(depth: number): Promise<Index> {
		return await (this.httpClient.get(environment.kotlin + "/index?depth=" + depth) as Observable<Index>).toPromise();
	}

	async getSubtree(startLevel: number, nodeID: UUID): Promise<Directory> {
		try {
			return await (this.httpClient.get(environment.kotlin + "/subtree/" +
				startLevel + "/" + nodeID) as Observable<Directory>).toPromise();
		} catch (e) {
			console.error("Could not load subtree for node " + nodeID + " :", e);
		}
	}

	async getLanguages(): Promise<Language[]> {
		return await (this.httpClient.get<Language[]>(environment.symfony + "/languages")).toPromise();
	}

	async getNode(id: UUID): Promise<Node> {
		const flat = PHBGlobals.index.nodes;
		if (!flat[id]) {
			const nodeJson = await this.httpClient.get<Node>(`${environment.kotlin}/node/${id}`).toPromise();
			const node = assignNode(nodeJson);
			flat[id] = node;
			if (!node.parentIds[1]) {
				console.info("no section in node:", node);
				await this.openSection(id);
				this.setCurrentNode(node);
			}
			if (node.parentIds[1] === this.currentSection()) {
				throw new Error(`Node ${id} not found in section!`);
			} else {
				//open the section;
				await this.openSection(node.parentIds[1]);
				this.setCurrentNode(node);
			}
		}
		return flat[id];
	}

	getResource(path: string): string {
		return environment.kotlin + "/resource/" + path;
	}

	getFilePreview(hash: string, page: number): string {
		if (page < 0) {
			return environment.kotlin + "/file/" + hash;
		}
		return environment.kotlin + "/file-preview/" + hash + "/" + page + ".jpg";
	}

	async getIndexVersion(): Promise<number> {
		try {
			return await (this.httpClient.get(environment.kotlin + "/version") as Observable<number>).toPromise();
		} catch (ex) {
			console.error("Could not get server index version. Check internet connection.");
			return -1;
		}
	}

	async getTranslationsForLanguage(lang: Language): Promise<{ [wordId: string]: string }> {
		let language;
		try {
			language = await (this.httpClient.get(environment.symfony + "/language/" + lang.isocode) as Observable<Language>).toPromise();
		} catch (e) {
			console.warn("Loading local translations for '" + lang.isocode + "'!");
			language = await (this.httpClient.get("./assets/boilerplate/resources/lang/" + lang.isocode + ".json") as Observable<Language>).toPromise();
		}
		if (!language.translations) {
			throw new Error("No Translations found!");
		}
		return language.translations;
	}

	async search(term: string, section: UUID, limit: number = 50, autocomplete: boolean, start: number = 0) {

	}

	async getTags(search: boolean = false): Promise<TagGroup[]> {

		return await (this.httpClient.get(`${environment.symfony}/tag-groups${search ? '?search=1' : ''}`) as Observable<TagGroup[]>).toPromise();

	}

	async getNodesByTag(tagId: number | number[]): Promise<Node[]> {
		let result: Node[] = [];
		if (typeof tagId === "number") {
			result = await (this.httpClient.get(environment.kotlin + "/nodes-by-tag/" + tagId) as Observable<Node[]>).toPromise();
		} else {
			result = await this.httpClient.get<Node[]>(environment.kotlin + "/nodes-by-tags?tags=" + tagId.join(",") + "&op=or", {
				headers: {
					"PHB-Modification-Time": "true"
				}
			}).toPromise();
		}

		const nodes: Node[] = [];
		for (let nodeJson of result) {
			nodes.push(assignNode(nodeJson));
		}
		return nodes;
	}

	async getConfig() {
		throw new Error("No Config needed!");
	}

	setCurrentNode(node: Node) {
		if (!environment.production) {
			try {
				throw new Error("test");
			} catch (e) {
				console.log(node);
				console.info(e);
			}
		}

		this.currentNode.set(node);
	}

	async fileExists(hash: Hash): Promise<boolean> {
		return true; //TODO should we check this in www?
	}
}
