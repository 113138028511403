import {Pipe, PipeTransform} from "@angular/core";
import {Node}                from "../../_model";


@Pipe({
	name: "onlyFiles",
	standalone: true
})
export class OnlyFilesPipe implements PipeTransform {

	constructor() {

	}

	transform(nodes: Node[]): Node[] {
		return nodes.filter(it => it.isFileType());

	}

}
