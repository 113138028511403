import { Component, OnInit } from "@angular/core";
import { User }              from "../../../_model/user";
import { UserProvider }            from "../../services/user/user";
import { Location }                from "@angular/common";
import { measurePasswordStrength } from "../../../_utils/general-utils";
import { translate }         from "../../../_model/vocab.fun";
import { Iso639_1 } from "../../../_model/Language";
import { PHBGlobals }        from "../../../_config/config";
import { MatSnackBar } from "@angular/material/snack-bar";


@Component({
	selector: "profile-modal",
	templateUrl: "profile.html",
	styleUrls: ["profile.scss"]
})
export class ProfileModal implements OnInit {

	currentUser: User;
	_password: string;
	showInsecureMessage: boolean = false;
	passwordScore: number = 0;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}


	constructor(public userProvider: UserProvider,
		private location: Location,
		private snackBar: MatSnackBar) {
	}

	async ngOnInit() {
		this.currentUser = await this.userProvider.getUser();
	}

	async saveChanges() {
		if (!this.showInsecureMessage
			&& this.currentUser.email
			&& this.currentUser.data.company
		) {
			try {
				await this.userProvider.update(this.currentUser, this._password);
				this.snackBar.open(translate("Toastr.Message.Success.Saved"),"", {
					duration: 3000,
					panelClass: "custom-snackbar--success",
					verticalPosition: "top",
					horizontalPosition: "center"
				});
			} catch (e) {
				console.error(e);
			}
		}
	}

	closeModal() {
		this.location.back();
	}

	checkPW() {
		if (!this._password) {
			this.showInsecureMessage = false;
			this.passwordScore = 0;
			return;
		}
		this.passwordScore = measurePasswordStrength(this._password);
		if (this.passwordScore < 70) {
			this.showInsecureMessage = true;
			return;
		}

		this.showInsecureMessage = false;
		return;
	}

}
