import { ClientType, Index }           from "../_model";
import { isElectron }                  from "../_utils/electron-utils";
import { User }                        from "../_model/user";
import { HttpHeaders }                 from "@angular/common/http";
import { Iso639_1, IsoCode, Language }                  from "../_model/Language";
import { EventEmitter, signal, WritableSignal } from "@angular/core";
import { environment }                                  from "../environments/environment";

/**
 * Global configuration.
 */

class Globals {
	index: Index;

	indexChangedSignal: WritableSignal<boolean> = signal<boolean>(false);
	user: User = null;

	get useTranslationFallback(): boolean {
		return (this.user && this.user.loggedIn && this.user.isAdmin()) || !environment.languageFallbackDisabled;
	}

	showMenu: boolean = true;

	toggleMenu() {
		this.showMenu = !this.showMenu;
	}

	userLoggedIn: boolean = false;
	zipAvailable: boolean = false;
	updateAvailable: boolean = false;
	softwareUpdateAvailable: boolean = false;
	softwareUpdateSkipped: boolean = false;

	get browserLanguage() {
		const browserLang = navigator.language || (navigator as any).userLanguage;
		return browserLang.split("-")[0];
	}

	languages: { [key: string]: Language };

	getLanguage(isoCode: IsoCode = null): Language {
		if (isoCode === null) {
			isoCode = this.currentLanguage;
		}
		if (isoCode.includes("_")) {
			isoCode = isoCode.split("_")[0];
		}
		return this.languages[isoCode];
	}

	get plainLanguages(): Language[] {
		const languages = [];
		for (let isoCode in this.languages) {
			languages.push(this.languages[isoCode]);
		}
		return languages;
	}

	get systemLanguage(): Iso639_1 {
		let langId: Iso639_1 = PHBGlobals.currentLanguage;
		if (!PHBGlobals.useTranslationFallback && PHBGlobals.user && PHBGlobals.user.loggedIn) {
			langId = PHBGlobals.user.data.settings.language;
		}
		return langId;
	}

	languageChanged: EventEmitter<Iso639_1> = new EventEmitter<Iso639_1>();
	private _currentLanguage: Iso639_1 = null;
	get currentLanguage(): Iso639_1 {
		if (this.user && this.user.loggedIn) {
			this._currentLanguage = localStorage.getItem(`phb-default-language-${this.user.sessionHash}`) as Iso639_1;
		}

		if (!this._currentLanguage) {
			if (!this.user || !this.user.loggedIn) {
				console.log("using browser-language:", this.browserLanguage);
				this._currentLanguage = this.browserLanguage;
				return this.browserLanguage;
			}
			return this.user.languages[0] || this.browserLanguage;
		}
		if (this.user && this.user.languages.includes(this._currentLanguage)) {
			return this._currentLanguage;
		} else if (this.user) {
			localStorage.removeItem(`phb-default-language-${this.user.sessionHash}`);
			let def = this.user.languages[0];
			if (!def) {
				def = "en";
			}
			this._currentLanguage = def;
			return def;
		}
		return this._currentLanguage;
	}

	set currentLanguage(isoCode: Iso639_1) {
		if (this.user) {
			if (!this.user.languages.includes(isoCode)) {
				throw new Error("Invalid IsoCode!");
			}

			localStorage.setItem(`phb-default-language-${this.user.sessionHash}`, isoCode);
		}
		this._currentLanguage = isoCode;
	}

	get language(): Language {
		return this.plainLanguages.find(it => it.isocode === this.currentLanguage);
	}

	get languageHeader(): HttpHeaders {
		return new HttpHeaders({
			"Phb-Language": this.user.languages.join(",")
		});
	}

	//win or www client for Header var PHB-Client
	get phbClient(): ClientType {
		const _c = this.user ? localStorage.getItem(`phb-client-${this.user.sessionHash}`) as ClientType : null;
		let client: ClientType = null;
		if (_c) {
			client = _c;
		}
		return isElectron() ? "win" : (client === null ? "www" : client);
	}

	set phbClient(c: ClientType) {
		console.info(`Set client type: ${c}`);
		localStorage.setItem(`phb-client-${this.user.sessionHash}`, c);
	}

	get phbVersion(): number {
		const _v = this.user ? localStorage.getItem(`phb-version-${this.user.sessionHash}`) : null;
		return _v ? parseInt(_v) : null;
	}

	/**
	 * if the client is set to preview mode by autologin on planerhandbuch-preview.melitta-group.com this vars are set
	 */
	get isPreviewMode(): boolean {
		return this.user ? JSON.parse(localStorage.getItem(`phb-preview-${this.user.sessionHash}`)) : false;
	}

	get previewModeUserGroups(): any[] {
		return this.user ? JSON.parse(localStorage.getItem(`phb-preview-groups-${this.user.sessionHash}`)) : [];
	}

	dataSuccessfulLoaded: boolean = false;

	constructor() {
		this.languageChanged.subscribe(language => this.currentLanguage = language);
	}

	resetCurrentLanguage(){
		this._currentLanguage = null;
		console.log("Reset language to", this.currentLanguage);
	}
}

export const PHBGlobals = new Globals();
