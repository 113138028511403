import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { callAsyncElectron }      from "../../../../_utils/electron-utils";
import { SoftwareService }                      from "../../../services/software.service";
import { Iso639_1 }                             from "../../../../_model/Language";
import { PHBGlobals }                           from "../../../../_config/config";
import { AppstatusProvider }                    from "../../../services/appstatus";

import { MatButtonModule }                      from "@angular/material/button";
import { TranslatePipe }                        from "../../../pipes/translate";
import { FilesizePipe }                         from "../../../pipes/filesize";

@Component({
	selector: "app-software-updater",
	templateUrl: "./software-updater.component.html",
	styleUrls: ["./software-updater.component.scss"],
	standalone: true,
	imports: [
	    MatButtonModule,
		TranslatePipe,
		FilesizePipe
	]
})
export class SoftwareUpdaterComponent implements OnInit {

	progress: number = 0;
	size: number;
	lastSkippedDate: Date = null;

	get remainingDays(): number {
		if (!this.lastSkippedDate) {
			return 7;
		}
		let difference = (new Date()).getTime() - this.lastSkippedDate.getTime();
		return 7 - Math.round(difference / (1000 * 3600 * 24));
	}

	downloading: boolean = false;
	skipped: boolean = false;

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}

	constructor(private softwareService: SoftwareService,
		private status: AppstatusProvider,
		private _cdr: ChangeDetectorRef) {
	}

	async ngOnInit() {
		this.size = await this.softwareService.getDownloadSize();
		const remaining = Number(localStorage.getItem("phb-skipped-installer"));
		if (remaining) {
			const date = new Date();
			date.setTime(remaining);
			this.lastSkippedDate = date;
		}
	}

	async skip() {
		this.skipped = true;

		if(!(localStorage.getItem('phb-skipped-installer'))) {
			this.lastSkippedDate = new Date();
			localStorage.setItem('phb-skipped-installer', this.lastSkippedDate.getTime() + '');
		}
		PHBGlobals.softwareUpdateAvailable = false;
		PHBGlobals.softwareUpdateSkipped = true;
		this.status.onlineSignal.set(false);
	}

	async download() {
		try {
			this.downloading = true;
			await callAsyncElectron("downloadInstaller", (result) => {
				this.progress = Math.round(result);
				try {
					this._cdr.detectChanges();
				} catch (e) {
				}
			}, this.softwareService.getInstaller(), "Planerhandbuch-Installer.exe", this.size, await this.softwareService.getCheckSum());
			this.downloading = false;
			localStorage.removeItem('phb-skipped-installer');
			return;
		} catch (e) {
			console.error(e);
			console.log("restart needed!");
			//callElectron("restart");
		}
	}

}
