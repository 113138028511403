import { Component } from "@angular/core";
import { MatDialogRef }      from "@angular/material/dialog";
import { Iso639_1 } from "../../../_model/Language";
import { PHBGlobals } from "../../../_config/config";
import { MatButtonModule } from "@angular/material/button";

@Component({
	selector: "app-phb-update",
	templateUrl: "./phb-update.component.html",
	styleUrls: ["./phb-update.component.scss"],
	standalone: true,
	imports: [
		MatButtonModule
	]
})
export class PhbUpdateComponent {

	get systemLanguage(): Iso639_1 {
		return PHBGlobals.systemLanguage;
	}


	constructor(protected dialogRef: MatDialogRef<PhbUpdateComponent>) {
	}

	acceptAndClose() {
		this.dialogRef.close('restart');
	}

	close() {
		this.dialogRef.close();
	}

}
