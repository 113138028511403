<div class="app-page">

	<!-- Header -->
	<div class="page-header">
		<app-header [preview]="preview" [everythingFine]="everythingFine"></app-header>
	</div>
	<!--/Header -->


	<app-main></app-main>


	<!--footer-->
	<div class="page-footer">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-md-6 text-left">
					@if(everythingFine) {
						<ul>
							<li>
								<a (click)="openExternalLink( 'footer_legal_link')">{{"footer_legal" | translate:systemLanguage}}</a>
							</li>
							<li>
								<a (click)="openExternalLink('footer_privacy_link')">{{"footer_privacy" | translate:systemLanguage}}</a>
							</li>
							<li>
								<a (click)="openExternalLink('footer_imprint_link')">{{"footer_imprint" | translate:systemLanguage}}</a>
							</li>
							<li>
								<a (click)="openExternalLink('footer_terms_link')">{{"footer_terms" | translate:systemLanguage}}</a>
							</li>
						</ul>
					}
				</div>

				@if(everythingFine && showUpdater()) {
					<div class="col-12 col-md-2 d-flex justify-content-end">
						<a style="color:white" [routerLink]="['/updater']">{{'updater_check' | translate:systemLanguage}}</a>
					</div>
				}


				<div [class]="'col-12 justify-content-end d-flex col-md-' + (showUpdater() ? 4 : 6)">
					© Copyright {{year}} | Melitta Professional Coffee Solutions GmbH & Co. KG
				</div>
			</div>
		</div>


	</div>
	<!--/Footer-->

	@if(cookieConsentVisible && !isElectron()) {
		<div class="cookie-banner">
			<div class="cookie-banner__title">
				{{'cookie_text' | translate:systemLanguage}}
			</div>
			<button mat-flat-button (click)="setCookieConsent()">{{'cookie_button_accept_text' | translate:systemLanguage}}</button>
			<button mat-flat-button (click)="openExternalLink(translate('cookie_button_info_link'))">{{'cookie_button_info_text' | translate:systemLanguage}}</button>
		</div>
	}

</div>
