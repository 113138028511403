import { Injectable }            from "@angular/core";
import { HttpClient }            from "@angular/common/http";
import { DataProvider }          from "./data/data";
import { global_vocab_settings } from "../../_model/vocab";
import { Language }     from "../../_model/Language";
import { PHBGlobals }            from "../../_config/config";


@Injectable({providedIn: "root"})
export class LanguageService {

	constructor(public http: HttpClient, private dataProvider: DataProvider) {
	}

	async init(forced: boolean = false) {
		//load languages
		try {
			await this.getLanguages(forced);

			//translations loaded
			this.loadTranslations();
		}catch (e) {
			throw e;
		}
	}

	async getLanguages(forced: boolean = false): Promise<{ [key: string]: Language }> {
		if (!PHBGlobals.languages || forced) {
			const langs = await this.dataProvider.getLanguages();
			const _langs = {};
			for (let lang of langs) {
				if(!lang.countries){
					lang.countries = ["__"];
				}
				_langs[lang.isocode] = lang;
			}
			PHBGlobals.languages = _langs;

			console.log("---- Languages loaded ----",PHBGlobals.languages);
		}
		return PHBGlobals.languages;
	}

	loadTranslations() {
		for(let isoCode in PHBGlobals.languages){
			global_vocab_settings.vocab.languages[isoCode] = PHBGlobals.languages[isoCode].translations;
		}
	}
}
