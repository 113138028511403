import { Pipe, PipeTransform } from "@angular/core";
import { IsoCode }             from "../../_model/Language";


@Pipe({
	name: "isoCodeToCountryKey",
	standalone: true
})
export class IsoCodeToCountryKeyPipe implements PipeTransform {

	transform(isoCode: IsoCode): string {
		if (!isoCode.includes("_")) {
			return "__";
		}
		return isoCode.split("_")[1];

	}

}
