import {Pipe, PipeTransform} from "@angular/core";
import {Node}                from "../../_model";

@Pipe({
	name: "color",
	standalone: true
})
export class ColorPipe implements PipeTransform {

	transform(node: Node, args?: any): { background: string } | string {
		if (node.color) {
			//return this.sanitizer.bypassSecurityTrustStyle("background: #" + node.color + ";");
			return {background: "#" + node.color};
		} else {
			return "";
		}
	}

}
